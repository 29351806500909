import { createSlice } from "@reduxjs/toolkit";

const initialState = { socketConn: null };

export const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    socketConnection: (socket, action) => {
      socket.socketConn = action.payload;
    },
  },
});

export const { socketConnection } = socketSlice.actions;

export const socketlist = (store) => store?.socket.socketConn;
export default socketSlice.reducer;
