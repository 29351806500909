import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Modal,
  PageHeader,
  Row,
  message,
} from "antd";
import FloatInput from "components/shared-components/MaterialFormUI/FloatInput";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptionRequestDetailsRequest,
  getSubscriptionRequestGetByIdState,
  getSubscriptionRequestUpdateState,
  updateSubscriptionRequestRequest,
} from "redux/reducers/subscription-request";
import PrivacyPolicy from "utils/subscriber-request-constance/privacyPolicyForm";
import TermsAndCondition from "utils/subscriber-request-constance/termsAndConditionForm";
import home from "../../../../src/assets/login.jpeg";
import { initialValuesSchema } from "./formValidation";
import subscriptionRequest from "./subscription-request.json";
let getSubscriberId;

const AddSubscriptionRequestProcessing = (props) => {
  const theme = useSelector((state) => state.theme.currentTheme);
  const [messageApi, contextHolder] = message.useMessage();

  const [showTerms, setShowTerms] = useState();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [getPrivacyPolicyValues, setGetPrivacyPolicyValues] = useState();
  const [getTermsAndConditionValues, setGetTermsAndConditionValues] =
    useState();
  const [valueofRowData, setValueOfRowData] = useState({});
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(true);

  const resetFormRef = useRef();
  const dispatch = useDispatch();

  const LAYOUT = {
    labelCol: { span: 10 },
    wrapperCol: { span: 20 },
  };

  // subscriber get by id service
  useEffect(() => {
    //get url search parmas
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    getSubscriberId = queryParams.get("acbS");
    localStorage.setItem("tn_ah", token);
    dispatch(getSubscriptionRequestDetailsRequest({ id: getSubscriberId }));
  }, [dispatch]);

  const subscriptionGetByIdData = useSelector(
    getSubscriptionRequestGetByIdState
  );
  useEffect(() => {
    if (!subscriptionGetByIdData?.data?.data) return setValueOfRowData({});
    setValueOfRowData(subscriptionGetByIdData?.data?.data);
    setShowSubscriptionForm(
      subscriptionGetByIdData?.data?.data?.status === "Accepted" ||
        subscriptionGetByIdData?.data?.data?.status === "On-Board"
        ? false
        : true
    );
  }, [subscriptionGetByIdData]);

  // formik initial values
  const INITIAL_FORM_STATE = {
    companyName: valueofRowData?.companyName ? valueofRowData?.companyName : "",
    gstNo: valueofRowData?.gstNo ? valueofRowData?.gstNo : "",
    companyTeamSize: valueofRowData?.companyTeamSize
      ? valueofRowData?.companyTeamSize
      : "",
    address: valueofRowData?.address ? valueofRowData?.address : "",
    termsAndConditions: "",
    privacyPolicy: "",
  };

  // form submit button click
  const handleFormSubmit = (value) => {
    let _id = getSubscriberId;
    let finalData = {
      ...value,
      _id,
      status: "Accepted",
      termsAndConditions: getTermsAndConditionValues,
      privacyPolicy: getPrivacyPolicyValues,
    };
    if (getTermsAndConditionValues && getPrivacyPolicyValues) {
      dispatch(updateSubscriptionRequestRequest(finalData));
    }
  };

  // Message and error response based on the response data
  const responseData = useSelector(getSubscriptionRequestUpdateState);
  useEffect(() => {
    if (!responseData) return null;
    if (responseData.error) {
      messageApi.open({
        type: "error",
        content: responseData?.error || "Unable to update",
      });
      setShowSubscriptionForm(true);
    } else if (responseData?.data && !responseData.loading) {
      setShowSubscriptionForm(false);
      //reset from when success
      resetFormRef.current.resetForm();
    }
  }, [messageApi, responseData]);

  const no = () => {
    setShowTerms(false);
    setShowPrivacy(false);
  };

  // accept decline values from term and condition form
  const submitFormTermsAndCondition = (value) => {
    setGetTermsAndConditionValues(value);
    setShowTerms(false);
  };

  // accept decline values from privacy policy form
  const submitFormPrivacyPolicy = (value) => {
    setGetPrivacyPolicyValues(value);
    setShowPrivacy(false);
  };

  return (
    <>
      <div className={`h-100 ${theme === "light" ? "bg-white" : ""}`}>
        <Row justify="center" className="align-items-stretch h-50">
          <Col xs={0} sm={0} md={0} lg={12}>
            <div className="d-flex flex-column justify-content-between h-100 px-4">
              <div className="text-center">
                <div className="mt-4">
                  <img
                    src={home}
                    alt="home"
                    style={{
                      width: "600px",
                      height: "360px",
                      marginTop: "100px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12}>
            <div className="container d-flex flex-column justify-content-center h-100 mt-5">
              {showSubscriptionForm ? (
                <Row justify="center">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card
                      style={{
                        borderRadius: "16px",
                        boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                      }}
                    >
                      {/* Page Header */}
                      <PageHeader
                        className="pageHeadingTitle"
                        title={`${subscriptionRequest?.HEADINGTITLE}`}
                      ></PageHeader>

                      <Formik
                        initialValues={INITIAL_FORM_STATE}
                        validationSchema={initialValuesSchema}
                        innerRef={resetFormRef}
                        enableReinitialize
                        onSubmit={(values) => {
                          handleFormSubmit(values);
                        }}
                      >
                        {({
                          values,
                          submitForm,
                          errors,
                          touched,
                          isValid,
                          dirty,
                        }) => {
                          return (
                            <Form {...LAYOUT} layout="vertical">
                              <FloatInput
                                label={subscriptionRequest.FORM_COMPANYNAME}
                                errors={errors.companyName}
                                touched={touched.companyName}
                                values={values.companyName}
                                name="companyName"
                                placeholder={
                                  subscriptionRequest.FORM_COMPANYNAME
                                }
                                required={true}
                                disabled={true}
                              />
                              <FloatInput
                                label={subscriptionRequest.FORM_COMPANY_GST_NO}
                                errors={errors.gstNo}
                                touched={touched.gstNo}
                                values={values.gstNo}
                                name="gstNo"
                                placeholder={
                                  subscriptionRequest.FORM_COMPANY_GST_NO
                                }
                              />
                              <FloatInput
                                label={subscriptionRequest.FORM_NO_OF_USERS}
                                errors={errors.companyTeamSize}
                                touched={touched.companyTeamSize}
                                values={values.companyTeamSize}
                                name="companyTeamSize"
                                placeholder={
                                  subscriptionRequest.FORM_NO_OF_USERS
                                }
                                required={true}
                              />
                              <FloatInput
                                label={subscriptionRequest.FORM_COMPANY_ADDRESS}
                                errors={errors.address}
                                touched={touched.address}
                                values={values.address}
                                name="address"
                                placeholder={
                                  subscriptionRequest.FORM_ADDRESS_PLACEHOLDER
                                }
                                required={true}
                                type=""
                              />
                              <div className="ant-row">
                                <Checkbox
                                  checked={getTermsAndConditionValues}
                                  disabled
                                ></Checkbox>
                                View and accept &nbsp; &nbsp;
                                <a onClick={(e) => setShowTerms(true)}>
                                  {subscriptionRequest.TERMS_AND_CONDITIONS}
                                </a>
                              </div>
                              <div className="ant-row">
                                <Checkbox
                                  checked={getPrivacyPolicyValues}
                                  disabled
                                ></Checkbox>
                                View and accept &nbsp; &nbsp;
                                <a onClick={(e) => setShowPrivacy(true)}>
                                  {subscriptionRequest.PRIVACY_POLICY}
                                </a>
                              </div>{" "}
                              {/* form footer button */}
                              <div className="mt-3">
                                <Button
                                  block
                                  htmlType="submit"
                                  type="primary"
                                  onClick={() => {
                                    submitForm();
                                  }}
                                  disabled={
                                    !(
                                      isValid &&
                                      dirty &&
                                      getTermsAndConditionValues &&
                                      getPrivacyPolicyValues
                                    )
                                  }
                                >
                                  {subscriptionRequest.SUBMITREQUEST_BUTTON}
                                </Button>
                                &nbsp; &nbsp;
                                <div style={{ textAlign: "end" }}>
                                  {subscriptionRequest.CONTACT_US} &nbsp;
                                  <a>
                                    {subscriptionRequest.CONTACT_US_EMAIL}
                                    &nbsp;
                                  </a>
                                  {subscriptionRequest.OTHER_MESSAGE} &nbsp;
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Alert
                  style={{ padding: "20px" }}
                  message={`You are  subscribed. ${subscriptionRequest.SUCCESS_MESSAGE}`}
                  type="success"
                />
              )}
            </div>
          </Col>
        </Row>
      </div>

      {contextHolder}

      {/* modal for terms and conditions */}
      <Modal
        title={subscriptionRequest.TERMS_AND_CONDITIONS}
        visible={showTerms}
        onOk={no}
        onCancel={no}
        footer={null}
        width={"550px"}
        className="modal-tc-and-pp"
      >
        <TermsAndCondition
          showFooterButton={true}
          submitFormTermsAndCondition={submitFormTermsAndCondition}
        />
      </Modal>

      {/* modal for privacy policy */}
      <Modal
        title={subscriptionRequest.PRIVACY_POLICY}
        visible={showPrivacy}
        onOk={no}
        onCancel={no}
        footer={null}
        width={"550px"}
        className="modal-tc-and-pp"
      >
        <PrivacyPolicy
          showFooterButton={true}
          submitFormPrivacyPolicy={submitFormPrivacyPolicy}
        />
      </Modal>
    </>
  );
};

export default AddSubscriptionRequestProcessing;
