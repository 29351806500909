import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionRequestList: { loading: false, data: null, error: null },
  createSubscriptionRequest: { loading: false, data: null, error: null },
  getSubscriptionRequestById: { loading: false, data: null, error: null },
  updateSubscriptionRequest: { loading: false, data: null, error: null },
  termsConditionDataRequestList: { loading: false, data: null, error: null },
  pullFromDynamoDb: { loading: false, data: null, error: null },
  updateSubscriberRequest: { loading: false, data: null, error: null },
  subscriptionRequestQAList: { loading: false, data: null, error: null },
  subscriptionRequestQACreate: { loading: false, data: null, error: null },
  subscriptionRequestQAUpdate: { loading: false, data: null, error: null },
};

export const SubscriptionRequestSlice = createSlice({
  name: "subscriptionRequest",
  initialState,
  reducers: {
    fetchSubscriptionRequestListRequest: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestList.loading = true;
      subscriptionRequest.subscriptionRequestList.error = null;
    },
    fetchSubscriptionRequestListSuccess: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestList.data = action.payload;
      subscriptionRequest.subscriptionRequestList.loading = false;
      subscriptionRequest.subscriptionRequestList.error = null;
    },
    fetchSubscriptionRequestListFailure: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestList.data = null;
      subscriptionRequest.subscriptionRequestList.loading = false;
      subscriptionRequest.subscriptionRequestList.error = action.payload;
    },

    // create subscriptionRequest
    createSubscriptionRequestRequest: (subscriptionRequest, action) => {
      subscriptionRequest.createSubscriptionRequest.loading = true;
      subscriptionRequest.createSubscriptionRequest.error = null;
    },
    createSubscriptionRequestSuccess: (subscriptionRequest, action) => {
      subscriptionRequest.createSubscriptionRequest.data = action.payload;
      subscriptionRequest.createSubscriptionRequest.loading = false;
      subscriptionRequest.createSubscriptionRequest.error = null;
    },
    createSubscriptionRequestFailure: (subscriptionRequest, action) => {
      subscriptionRequest.createSubscriptionRequest.data = null;
      subscriptionRequest.createSubscriptionRequest.loading = false;
      subscriptionRequest.createSubscriptionRequest.error = action.payload;
    },

    // get by id subscriptionRequest details
    getSubscriptionRequestDetailsRequest: (subscriptionRequest, action) => {
      subscriptionRequest.getSubscriptionRequestById.loading = true;
      subscriptionRequest.getSubscriptionRequestById.error = null;
    },
    getSubscriptionRequestDetailsSuccess: (subscriptionRequest, action) => {
      subscriptionRequest.getSubscriptionRequestById.loading = false;
      subscriptionRequest.getSubscriptionRequestById.error = null;
      subscriptionRequest.getSubscriptionRequestById.data = action.payload;
    },
    getSubscriptionRequestDetailsFailure: (subscriptionRequest, action) => {
      subscriptionRequest.getSubscriptionRequestById.data = null;
      subscriptionRequest.getSubscriptionRequestById.loading = false;
      subscriptionRequest.getSubscriptionRequestById.error = action.payload;
    },

    // update subscriptionRequest
    updateSubscriptionRequestRequest: (subscriptionRequest, action) => {
      subscriptionRequest.updateSubscriptionRequest.loading = true;
      subscriptionRequest.updateSubscriptionRequest.error = null;
    },
    updateSubscriptionRequestSuccess: (subscriptionRequest, action) => {
      subscriptionRequest.updateSubscriptionRequest.data = action.payload;
      subscriptionRequest.updateSubscriptionRequest.loading = false;
      subscriptionRequest.updateSubscriptionRequest.error = null;
    },
    updateSubscriptionRequestFailure: (subscriptionRequest, action) => {
      subscriptionRequest.updateSubscriptionRequest.data = null;
      subscriptionRequest.updateSubscriptionRequest.loading = false;
      subscriptionRequest.updateSubscriptionRequest.error = action.payload;
    },

    // terms and condition and privacy policy
    fetchTermsConditionRequestListRequest: (subscriptionRequest, action) => {
      subscriptionRequest.termsConditionDataRequestList.loading = true;
      subscriptionRequest.termsConditionDataRequestList.error = null;
    },
    fetchTermsConditionRequestListSuccess: (subscriptionRequest, action) => {
      subscriptionRequest.termsConditionDataRequestList.data = action.payload;
      subscriptionRequest.termsConditionDataRequestList.loading = false;
      subscriptionRequest.termsConditionDataRequestList.error = null;
    },
    fetchTermsConditionRequestListFailure: (subscriptionRequest, action) => {
      subscriptionRequest.termsConditionDataRequestList.data = null;
      subscriptionRequest.termsConditionDataRequestList.loading = false;
      subscriptionRequest.termsConditionDataRequestList.error = action.payload;
    },

    fetchPullFromDynamoDBRequest: (subscriptionRequest, action) => {
      subscriptionRequest.pullFromDynamoDb.loading = true;
      subscriptionRequest.pullFromDynamoDb.error = null;
    },
    fetchPullFromDynamoDBSuccess: (subscriptionRequest, action) => {
      subscriptionRequest.pullFromDynamoDb.data = action.payload;
      subscriptionRequest.pullFromDynamoDb.loading = false;
      subscriptionRequest.pullFromDynamoDb.error = null;
    },
    fetchPullFromDynamoDBFailure: (subscriptionRequest, action) => {
      subscriptionRequest.pullFromDynamoDb.data = null;
      subscriptionRequest.pullFromDynamoDb.loading = false;
      subscriptionRequest.pullFromDynamoDb.error = action.payload;
    },

    // update subscriber request
    updateSubscriberRequest: (subscriptionRequest, action) => {
      subscriptionRequest.updateSubscriberRequest.loading = true;
      subscriptionRequest.updateSubscriberRequest.error = null;
    },
    updateSubscriberRequestSuccess: (subscriptionRequest, action) => {
      subscriptionRequest.updateSubscriberRequest.data = action.payload;
      subscriptionRequest.updateSubscriberRequest.loading = false;
      subscriptionRequest.updateSubscriberRequest.error = null;
      subscriptionRequest.subscriptionRequestList = updateSubscriberSuccess(
        action.payload,
        {
          ...subscriptionRequest.subscriptionRequestList,
        }
      );
    },
    updateSubscriberRequestFailure: (subscriptionRequest, action) => {
      subscriptionRequest.updateSubscriberRequest.data = null;
      subscriptionRequest.updateSubscriberRequest.loading = false;
      subscriptionRequest.updateSubscriberRequest.error = action.payload;
    },

    // Subscription Request QA List
    fetchSubscriptionRequestQAListRequest: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestQAList.loading = true;
      subscriptionRequest.subscriptionRequestQAList.error = null;
    },
    fetchSubscriptionRequestQAListSuccess: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestQAList.data = action.payload;
      subscriptionRequest.subscriptionRequestQAList.loading = false;
      subscriptionRequest.subscriptionRequestQAList.error = null;
    },
    fetchSubscriptionRequestQAListFailure: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestQAList.data = null;
      subscriptionRequest.subscriptionRequestQAList.loading = false;
      subscriptionRequest.subscriptionRequestQAList.error = action.payload;
    },

    // create answer for subscription Request QA
    createSubscriptionRequestQARequest: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestQACreate.loading = true;
      subscriptionRequest.subscriptionRequestQACreate.error = null;
    },
    createSubscriptionRequestQASuccess: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestQACreate.data = action.payload;
      subscriptionRequest.subscriptionRequestQACreate.loading = false;
      subscriptionRequest.subscriptionRequestQACreate.error = null;
    },
    createSubscriptionRequestQAFailure: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestQACreate.data = null;
      subscriptionRequest.subscriptionRequestQACreate.loading = false;
      subscriptionRequest.subscriptionRequestQACreate.error = action.payload;
    },

    // update answer for subscription Request QA
    updateSubscriptionRequestQARequest: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestQAUpdate.loading = true;
      subscriptionRequest.subscriptionRequestQAUpdate.error = null;
    },
    updateSubscriptionRequestQASuccess: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestQAUpdate.data = action.payload;
      subscriptionRequest.subscriptionRequestQAUpdate.loading = false;
      subscriptionRequest.subscriptionRequestQAUpdate.error = null;
    },
    updateSubscriptionRequestQAFailure: (subscriptionRequest, action) => {
      subscriptionRequest.subscriptionRequestQAUpdate.data = null;
      subscriptionRequest.subscriptionRequestQAUpdate.loading = false;
      subscriptionRequest.subscriptionRequestQAUpdate.error = action.payload;
    },

    //Reset subscriptionRequest state
    resetSubscriptionRequest: (subscriptionRequest, action) => {
      subscriptionRequest.createSubscriptionRequest.data = null;
      subscriptionRequest.createSubscriptionRequest.loading = false;
      subscriptionRequest.createSubscriptionRequest.error = null;

      subscriptionRequest.updateSubscriptionRequest.data = null;
      subscriptionRequest.updateSubscriptionRequest.loading = false;
      subscriptionRequest.updateSubscriptionRequest.error = null;

      subscriptionRequest.pullFromDynamoDb.data = null;
      subscriptionRequest.pullFromDynamoDb.loading = false;
      subscriptionRequest.pullFromDynamoDb.error = null;

      subscriptionRequest.updateSubscriberRequest.data = null;
      subscriptionRequest.updateSubscriberRequest.loading = false;
      subscriptionRequest.updateSubscriberRequest.error = null;

      subscriptionRequest.subscriptionRequestQACreate.data = null;
      subscriptionRequest.subscriptionRequestQACreate.loading = false;
      subscriptionRequest.subscriptionRequestQACreate.error = null;

      subscriptionRequest.subscriptionRequestQAUpdate.data = null;
      subscriptionRequest.subscriptionRequestQAUpdate.loading = false;
      subscriptionRequest.subscriptionRequestQAUpdate.error = null;
    },
  },
});

function updateSubscriberSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });
  return state;
}

export const {
  fetchSubscriptionRequestListRequest,
  fetchSubscriptionRequestListSuccess,
  fetchSubscriptionRequestListFailure,

  createSubscriptionRequestRequest,
  createSubscriptionRequestSuccess,
  createSubscriptionRequestFailure,

  getSubscriptionRequestDetailsRequest,
  getSubscriptionRequestDetailsSuccess,
  getSubscriptionRequestDetailsFailure,

  updateSubscriptionRequestRequest,
  updateSubscriptionRequestSuccess,
  updateSubscriptionRequestFailure,

  fetchTermsConditionRequestListRequest,
  fetchTermsConditionRequestListSuccess,
  fetchTermsConditionRequestListFailure,

  fetchPullFromDynamoDBRequest,
  fetchPullFromDynamoDBSuccess,
  fetchPullFromDynamoDBFailure,

  updateSubscriberRequest,
  updateSubscriberRequestSuccess,
  updateSubscriberRequestFailure,

  fetchSubscriptionRequestQAListRequest,
  fetchSubscriptionRequestQAListSuccess,
  fetchSubscriptionRequestQAListFailure,

  createSubscriptionRequestQARequest,
  createSubscriptionRequestQASuccess,
  createSubscriptionRequestQAFailure,

  updateSubscriptionRequestQARequest,
  updateSubscriptionRequestQASuccess,
  updateSubscriptionRequestQAFailure,

  resetSubscriptionRequest,
} = SubscriptionRequestSlice.actions;

export const getSubscriptionRequestListState = (store) =>
  store?.subscriptionRequest?.subscriptionRequestList;
export const getSubscriptionRequestCreateState = (store) =>
  store?.subscriptionRequest?.createSubscriptionRequest;
export const getSubscriptionRequestGetByIdState = (store) =>
  store?.subscriptionRequest?.getSubscriptionRequestById;
export const getSubscriptionRequestUpdateState = (store) =>
  store?.subscriptionRequest?.updateSubscriptionRequest;
export const getTermsConditionRequestListState = (store) =>
  store?.subscriptionRequest?.termsConditionDataRequestList;
export const getPullFromDynamoDBState = (store) =>
  store?.subscriptionRequest?.pullFromDynamoDb;
export const getSubscriberRequestUpdateState = (store) =>
  store?.subscriptionRequest?.updateSubscriberRequest;
export const getSubscriptionRequestQAListState = (store) =>
  store?.subscriptionRequest?.subscriptionRequestQAList;
export const getSubscriptionRequestQACreateState = (store) =>
  store?.subscriptionRequest?.subscriptionRequestQACreate;
export const getSubscriptionRequestQAUpdateState = (store) =>
  store?.subscriptionRequest?.subscriptionRequestQAUpdate;
export default SubscriptionRequestSlice.reducer;
