import { put, takeLatest } from "redux-saga/effects";

import {
    requestFinalInvoiceList,
    requestCreateFinalInvoice,
    requestUpdateFinalInvoice,
    requestDeleteFinalInvoice,
    requestFinalInvoiceDataById,
} from "services/finalInvoice";

import {
    fetchFinalInvoiceListRequest,
    fetchFinalInvoiceListSuccess,
    fetchFinalInvoiceListFailure,
    createFinalInvoiceRequest,
    createFinalInvoiceSuccess,
    createFinalInvoiceFailure,
    updateFinalInvoiceRequest,
    updateFinalInvoiceSuccess,
    updateFinalInvoiceFailure,
    deleteFinalInvoiceRequest,
    deleteFinalInvoiceSuccess,
    deleteFinalInvoiceFailure,
    getFinalInvoiceByIdRequest,
    getFinalInvoiceByIdSuccess,
    getFinalInvoiceByIdFailure,
} from "redux/reducers/finalInvoice";

export function* watchFinalInvoiceSaga() {
    yield takeLatest(fetchFinalInvoiceListRequest, fetchFinalInvoiceList);
    yield takeLatest(createFinalInvoiceRequest, createFinalInvoice);
    yield takeLatest(updateFinalInvoiceRequest, updateFinalInvoice);
    yield takeLatest(deleteFinalInvoiceRequest, deleteFinalInvoice);
    yield takeLatest(getFinalInvoiceByIdRequest, getFinalInvoiceById);
}

function* fetchFinalInvoiceList({ payload }) {
    try {
        const response = yield requestFinalInvoiceList(payload);
        yield put(fetchFinalInvoiceListSuccess(response));
    } catch (error) {
        yield put(
            fetchFinalInvoiceListFailure("Failed to get list of final invoices")
        );
    }
}

function* createFinalInvoice({ payload }) {
    try {
        // service add
        const response = yield requestCreateFinalInvoice(payload);
        yield put(createFinalInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create final invoice";
        yield put(createFinalInvoiceFailure(message));
    }
}

function* updateFinalInvoice({ payload }) {
    try {
        const response = yield requestUpdateFinalInvoice(payload);
        yield put(updateFinalInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update final invoice";
        yield put(updateFinalInvoiceFailure(message));
    }
}

function* deleteFinalInvoice({ payload }) {
    try {
        const response = yield requestDeleteFinalInvoice(payload);
        yield put(deleteFinalInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete final invoice";
        yield put(deleteFinalInvoiceFailure(message));
    }
}

function* getFinalInvoiceById({ payload }) {
    try {
        const response = yield requestFinalInvoiceDataById(payload);
        yield put(getFinalInvoiceByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get final invoice details";
        yield put(getFinalInvoiceByIdFailure(message));
    }
}