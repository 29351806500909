import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    generateEInvoiceList: { loading: false, data: null, error: null },
    createGenerateEInvoice: { loading: false, data: null, error: null },
    updateGenerateEInvoice: { loading: false, data: null, error: null },
    deleteGenerateEInvoice: { loading: false, data: null, error: null },
    getGenerateEInvoiceById: { loading: false, data: null, error: null },
};

export const generateEInvoiceSlice = createSlice({
    name: "generateEInvoice",
    initialState,
    reducers: {
        fetchGenerateEInvoiceListRequest: (generateEInvoice, action) => {
            generateEInvoice.generateEInvoiceList.loading = true;
            generateEInvoice.generateEInvoiceList.error = null;
        },
        fetchGenerateEInvoiceListSuccess: (generateEInvoice, action) => {
            generateEInvoice.generateEInvoiceList.data = action.payload;
            generateEInvoice.generateEInvoiceList.loading = false;
            generateEInvoice.generateEInvoiceList.error = null;
        },
        fetchGenerateEInvoiceListFailure: (generateEInvoice, action) => {
            generateEInvoice.generateEInvoiceList.data = null;
            generateEInvoice.generateEInvoiceList.loading = false;
            generateEInvoice.generateEInvoiceList.error = action.payload;
        },

        //create GenerateEInvoice
        createGenerateEInvoiceRequest: (generateEInvoice, action) => {
            generateEInvoice.createGenerateEInvoice.loading = true;
            generateEInvoice.createGenerateEInvoice.error = null;
        },
        createGenerateEInvoiceSuccess: (generateEInvoice, action) => {
            generateEInvoice.createGenerateEInvoice.data = action.payload;
            generateEInvoice.createGenerateEInvoice.loading = false;
            generateEInvoice.createGenerateEInvoice.error = null;
        },
        createGenerateEInvoiceFailure: (generateEInvoice, action) => {
            generateEInvoice.createGenerateEInvoice.data = null;
            generateEInvoice.createGenerateEInvoice.loading = false;
            generateEInvoice.createGenerateEInvoice.error = action.payload;
        },

        //update generateEInvoice details
        updateGenerateEInvoiceRequest: (generateEInvoice, action) => {
            generateEInvoice.updateGenerateEInvoice.loading = true;
            generateEInvoice.updateGenerateEInvoice.error = null;
        },
        updateGenerateEInvoiceSuccess: (generateEInvoice, action) => {
            generateEInvoice.updateGenerateEInvoice.data = action.payload;
            generateEInvoice.updateGenerateEInvoice.loading = false;
            generateEInvoice.updateGenerateEInvoice.error = null;
            generateEInvoice.generateEInvoiceList =
                updateNewGenerateEInvoiceSuccess(
                    action.payload,
                    generateEInvoice.generateEInvoiceList
                );
        },
        updateGenerateEInvoiceFailure: (generateEInvoice, action) => {
            generateEInvoice.updateGenerateEInvoice.data = null;
            generateEInvoice.updateGenerateEInvoice.loading = false;
            generateEInvoice.updateGenerateEInvoice.error = action.payload;
        },

        // delete generateEInvoice
        deleteGenerateEInvoiceRequest: (generateEInvoice, action) => {
            generateEInvoice.deleteGenerateEInvoice.loading = true;
            generateEInvoice.deleteGenerateEInvoice.error = null;
        },
        deleteGenerateEInvoiceSuccess: (generateEInvoice, action) => {
            generateEInvoice.deleteGenerateEInvoice.data = action.payload;
            generateEInvoice.deleteGenerateEInvoice.loading = false;
            generateEInvoice.deleteGenerateEInvoice.error = null;
            generateEInvoice.generateEInvoiceList =
                deleteNewGenerateEInvoiceSuccess(action.payload, {
                    ...generateEInvoice.generateEInvoiceList,
                });
        },
        deleteGenerateEInvoiceFailure: (generateEInvoice, action) => {
            generateEInvoice.deleteGenerateEInvoice.data = null;
            generateEInvoice.deleteGenerateEInvoice.loading = false;
            generateEInvoice.deleteGenerateEInvoice.error = action.payload;
        },

        // getById generateEInvoice details
        getGenerateEInvoiceByIdRequest: (generateEInvoice, action) => {
            generateEInvoice.getGenerateEInvoiceById.loading = true;
            generateEInvoice.getGenerateEInvoiceById.error = null;
        },
        getGenerateEInvoiceByIdSuccess: (generateEInvoice, action) => {
            generateEInvoice.getGenerateEInvoiceById.data = action.payload;
            generateEInvoice.getGenerateEInvoiceById.loading = false;
            generateEInvoice.getGenerateEInvoiceById.error = null;
        },
        getGenerateEInvoiceByIdFailure: (generateEInvoice, action) => {
            generateEInvoice.getGenerateEInvoiceById.data = null;
            generateEInvoice.getGenerateEInvoiceById.loading = false;
            generateEInvoice.getGenerateEInvoiceById.error = action.payload;
        },

        // reset generateEInvoice details
        resetGenerateEInvoice: (generateEInvoice, action) => {
            generateEInvoice.createGenerateEInvoice.data = null;
            generateEInvoice.createGenerateEInvoice.loading = false;
            generateEInvoice.createGenerateEInvoice.error = null;

            generateEInvoice.updateGenerateEInvoice.data = null;
            generateEInvoice.updateGenerateEInvoice.loading = false;
            generateEInvoice.updateGenerateEInvoice.error = null;

            generateEInvoice.deleteGenerateEInvoice.data = null;
            generateEInvoice.deleteGenerateEInvoice.loading = false;
            generateEInvoice.deleteGenerateEInvoice.error = null;

            generateEInvoice.getGenerateEInvoiceById.data = null;
            generateEInvoice.getGenerateEInvoiceById.loading = false;
            generateEInvoice.getGenerateEInvoiceById.error = null;
        },

        resetGenerateEInvoiceList: (generateEInvoice, action) => {
            generateEInvoice.generateEInvoiceList.data = null;
            generateEInvoice.generateEInvoiceList.loading = false;
            generateEInvoice.generateEInvoiceList.error = null;
        },
    },
});

function updateNewGenerateEInvoiceSuccess(dataToUpdate, state) {
    if (state.data?.data.length) {
        const updatedData = state.data.data.map((value) => {
            if (value._id !== dataToUpdate._id) return value;
            return { ...value, ...dataToUpdate };
        });
        return { ...state, data: { ...state.data, data: updatedData } };
    } else {
        return state;
    }
}

function deleteNewGenerateEInvoiceSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchGenerateEInvoiceListRequest,
    fetchGenerateEInvoiceListSuccess,
    fetchGenerateEInvoiceListFailure,

    createGenerateEInvoiceRequest,
    createGenerateEInvoiceSuccess,
    createGenerateEInvoiceFailure,

    updateGenerateEInvoiceRequest,
    updateGenerateEInvoiceSuccess,
    updateGenerateEInvoiceFailure,

    deleteGenerateEInvoiceRequest,
    deleteGenerateEInvoiceSuccess,
    deleteGenerateEInvoiceFailure,

    getGenerateEInvoiceByIdRequest,
    getGenerateEInvoiceByIdSuccess,
    getGenerateEInvoiceByIdFailure,

    resetGenerateEInvoice,
    resetGenerateEInvoiceList,
} = generateEInvoiceSlice.actions;

export const getGenerateEInvoiceListState = (store) =>
    store?.generateEInvoice?.generateEInvoiceList;
export const getGenerateEInvoiceCreateState = (store) =>
    store?.generateEInvoice?.createGenerateEInvoice;
export const getGenerateEInvoiceUpdateState = (store) =>
    store?.generateEInvoice?.updateGenerateEInvoice;
export const getGenerateEInvoiceDeleteState = (store) =>
    store?.generateEInvoice?.deleteGenerateEInvoice;
export const getGenerateEInvoiceGetByIdState = (store) =>
    store?.generateEInvoice?.getGenerateEInvoiceById;
export default generateEInvoiceSlice.reducer;
