import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    creditNoteList: { loading: false, data: null, error: null },
    createCreditNote: { loading: false, data: null, error: null },
    updateCreditNote: { loading: false, data: null, error: null },
    deleteCreditNote: { loading: false, data: null, error: null },
    getCreditNoteById: { loading: false, data: null, error: null },
    approvedCreditNote: { loading: false, data: null, error: null },
}

export const billsSlice = createSlice({
    name: "creditNote",
    initialState,
    reducers: {
        fetchcreditNoteListRequest: (creditNote, action) => {
            creditNote.creditNoteList.loading = true;
            creditNote.creditNoteList.error = null;
        },
        fetchcreditNoteListSuccess: (creditNote, action) => {
            creditNote.creditNoteList.data = action.payload;
            creditNote.creditNoteList.loading = false;
            creditNote.creditNoteList.error = null;
        },
        fetchcreditNoteListFailure: (creditNote, action) => {
            creditNote.creditNoteList.data = null;
            creditNote.creditNoteList.loading = false;
            creditNote.creditNoteList.error = action.payload;
        },

        //create creditNote
        createcreditNoteRequest: (creditNote, action) => {
            creditNote.createCreditNote.loading = true;
            creditNote.createCreditNote.error = null;
        },
        createcreditNoteSuccess: (creditNote, action) => {
            creditNote.createCreditNote.data = action.payload;
            creditNote.createCreditNote.loading = false;
            creditNote.createCreditNote.error = null;
        },
        createcreditNoteFailure: (creditNote, action) => {
            creditNote.createCreditNote.data = null;
            creditNote.createCreditNote.loading = false;
            creditNote.createCreditNote.error = action.payload;
        },

        //update creditNote details
        updatecreditNoteRequest: (creditNote, action) => {
            creditNote.updateCreditNote.loading = true;
            creditNote.updateCreditNote.error = null;
        },
        updatecreditNoteSuccess: (creditNote, action) => {
            creditNote.updateCreditNote.data = action.payload;
            creditNote.updateCreditNote.loading = false;
            creditNote.updateCreditNote.error = null;
        },
        updatecreditNoteFailure: (creditNote, action) => {
            creditNote.updateCreditNote.data = null;
            creditNote.updateCreditNote.loading = false;
            creditNote.updateCreditNote.error = action.payload;
        },

        approvedcreditNoteRequest: (creditNote, action) => {
            creditNote.approvedCreditNote.loading = true;
            creditNote.approvedCreditNote.error = null;
        },
        approvedcreditNoteSuccess: (creditNote, action) => {
            creditNote.approvedCreditNote.data = action.payload;
            creditNote.approvedCreditNote.loading = false;
            creditNote.approvedCreditNote.error = null;
        },
        approvedcreditNoteFailure: (creditNote, action) => {
            creditNote.approvedCreditNote.data = null;
            creditNote.approvedCreditNote.loading = false;
            creditNote.approvedCreditNote.error = action.payload;
        },

        // delete creditNote
        deletecreditNoteRequest: (creditNote, action) => {
            creditNote.deleteCreditNote.loading = true;
            creditNote.deleteCreditNote.error = null;
        },
        deletecreditNoteSuccess: (creditNote, action) => {
            creditNote.deleteCreditNote.data = action.payload;
            creditNote.deleteCreditNote.loading = false;
            creditNote.deleteCreditNote.error = null;
            creditNote.creditNoteList = deleteNewcreditNoteSuccess(action.payload, {
                ...creditNote.creditNoteList,
            })
        },
        deletecreditNoteFailure: (creditNote, action) => {
            creditNote.deleteCreditNote.data = null;
            creditNote.deleteCreditNote.loading = false;
            creditNote.deleteCreditNote.error = action.payload;
        },

        // getById creditNote details
        getcreditNoteByIdRequest: (creditNote, action) => {
            creditNote.getCreditNoteById.loading = true;
            creditNote.getCreditNoteById.error = null;
        },
        getcreditNoteByIdSuccess: (creditNote, action) => {
            creditNote.getCreditNoteById.data = action.payload;
            creditNote.getCreditNoteById.loading = false;
            creditNote.getCreditNoteById.error = null;
        },
        getcreditNoteByIdFailure: (creditNote, action) => {
            creditNote.getCreditNoteById.data = null;
            creditNote.getCreditNoteById.loading = false;
            creditNote.getCreditNoteById.error = action.payload;
        },

        // reset creditNote details
        resetcreditNote: (creditNote, action) => {
            creditNote.createCreditNote.data = null;
            creditNote.createCreditNote.loading = false;
            creditNote.createCreditNote.error = null;

            creditNote.updateCreditNote.data = null;
            creditNote.updateCreditNote.loading = false;
            creditNote.updateCreditNote.error = null;

            creditNote.deleteCreditNote.data = null;
            creditNote.deleteCreditNote.loading = false;
            creditNote.deleteCreditNote.error = null;

            creditNote.approvedCreditNote.data = null;
            creditNote.approvedCreditNote.loading = false;
            creditNote.approvedCreditNote.error = null;
        }
    }
})

function deleteNewcreditNoteSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchcreditNoteListRequest,
    fetchcreditNoteListSuccess,
    fetchcreditNoteListFailure,

    createcreditNoteRequest,
    createcreditNoteSuccess,
    createcreditNoteFailure,

    updatecreditNoteRequest,
    updatecreditNoteSuccess,
    updatecreditNoteFailure,

    approvedcreditNoteRequest,
    approvedcreditNoteSuccess,
    approvedcreditNoteFailure,

    deletecreditNoteRequest,
    deletecreditNoteSuccess,
    deletecreditNoteFailure,

    getcreditNoteByIdRequest,
    getcreditNoteByIdSuccess,
    getcreditNoteByIdFailure,

    resetcreditNote,
} = billsSlice.actions;

export const getcreditNoteListState = (store) =>
    store?.creditNote?.creditNoteList;
export const getcreditNoteCreateState = (store) =>
    store?.creditNote?.createCreditNote;
export const getcreditNoteUpdateState = (store) =>
    store?.creditNote?.updateCreditNote;
export const getcreditNoteApprovedState = (store) =>
    store?.creditNote?.approvedCreditNote;
export const getcreditNoteDeleteState = (store) =>
    store?.creditNote?.deleteCreditNote;
export const getcreditNoteGetByIdState = (store) =>
    store?.creditNote?.getCreditNoteById;
export default billsSlice.reducer;