import { put, takeLatest } from "redux-saga/effects";
import {
    requestGenerateBillsInvoice
} from "services/billsInvoice";

import {
    generateBillsInvoiceFailure,
    generateBillsInvoiceRequest,
    generateBillsInvoiceSuccess
} from "redux/reducers/billsInvoice";

export function* watchBillsInvoiceSaga() {
    yield takeLatest(generateBillsInvoiceRequest, generateBillsInvoice);

}

function* generateBillsInvoice({ payload }) {
    try {
        // service add
        const response = yield requestGenerateBillsInvoice(payload);
        yield put(generateBillsInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to generate bills invoice";
        yield put(generateBillsInvoiceFailure(message));
    }
}

