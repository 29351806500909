import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resourceViewList: { loading: false, data: null, error: null },
  updateresourceView: { loading: false, data: null, error: null },
};

export const resourceViewSlice = createSlice({
  name: "resourceView",
  initialState,
  reducers: {
    fetchresourceViewListRequest: (preventiveMaintenance, action) => {
      preventiveMaintenance.resourceViewList.loading = true;
      preventiveMaintenance.resourceViewList.error = null;
    },
    fetchresourceViewListSuccess: (preventiveMaintenance, action) => {
      preventiveMaintenance.resourceViewList.data = action.payload;
      preventiveMaintenance.resourceViewList.loading = false;
      preventiveMaintenance.resourceViewList.error = null;
    },
    fetchresourceViewListFailure: (preventiveMaintenance, action) => {
      preventiveMaintenance.resourceViewList.data = null;
      preventiveMaintenance.resourceViewList.loading = false;
      preventiveMaintenance.resourceViewList.error = action.payload;
    },

    //update
    updateresourceViewRequest: (preventiveMaintenance, action) => {
      preventiveMaintenance.updateresourceView.loading = true;
      preventiveMaintenance.updateresourceView.error = null;
    },
    updateresourceViewSuccess: (preventiveMaintenance, action) => {
      preventiveMaintenance.updateresourceView.data = action.payload;
      preventiveMaintenance.updateresourceView.loading = false;
      preventiveMaintenance.updateresourceView.error = null;
      preventiveMaintenance.preventiveMaintenanceList =
        updateNewPreventiveMaintenanceSuccess(
          action.payload,
          preventiveMaintenance.resourceViewList
        );
    },
    updateresourceViewFailure: (preventiveMaintenance, action) => {
      preventiveMaintenance.updateresourceView.data = null;
      preventiveMaintenance.updateresourceView.loading = false;
      preventiveMaintenance.updateresourceView.error = action.payload;
    },
  },
});

function updateNewPreventiveMaintenanceSuccess(dataToUpdate, state) {
  if (state.data?.data.length) {
    const updatedData = state.data.data.map((value) => {
      if (value._id !== dataToUpdate._id) return value;
      return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
  } else {
    return state;
  }
}

export const {
  fetchresourceViewListRequest,
  fetchresourceViewListSuccess,
  fetchresourceViewListFailure,

  updateresourceViewRequest,
  updateresourceViewSuccess,
  updateresourceViewFailure,
} = resourceViewSlice.actions;

export const getresourceViewListState = (store) =>
  store?.resourceView?.resourceViewList;

export const getresourceViewUpdateState = (store) =>
  store?.resourceView?.updateresourceView;

export default resourceViewSlice.reducer;
