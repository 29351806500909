import { Grid, Layout } from "antd";
import Footer from "components/layout-components/Footer";
import HeaderNav from "components/layout-components/HeaderNav";
import MobileNav from "components/layout-components/MobileNav";
import PageHeader from "components/layout-components/PageHeader";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import AppViews from "views/app-views";
import navigationConfig from "configs/NavigationConfig";
import {
  DIR_LTR,
  DIR_RTL,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import {
  getSubscriberDetailsHeaderImagesRequest,
  getSubscriberGetHeaderImagesState,
} from "redux/reducers/manageSubscriber";
import utils from "utils";
import { checkPermission } from "utils/permissions";
import { getSideMenuState } from "redux/reducers/getSideMenu";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const [headerImgResponseData, setHeaderImgResponseData] = useState([]);
  const [sidemenu, setSideMenu] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getSubscriberDetailsHeaderImagesRequest({
        id: localStorage.getItem("subscriberId"),
      })
    );
  }, []);
  const sideMenuData = useSelector(getSideMenuState);
  const headerImagesResponseData = useSelector(
    getSubscriberGetHeaderImagesState
  );
  useEffect(() => {
    if (!sideMenuData?.data?.length) return setSideMenu([]);
    const data = sideMenuData?.data?.filter(
      (e) => e?.code !== "SITE_CONFIGURATION"
    );
    setSideMenu(data);
  }, [sideMenuData]);
  
  useEffect(() => {
    if (
      !headerImagesResponseData?.data?.data ||
      !headerImagesResponseData?.data
    )
      return setHeaderImgResponseData([]);
    setHeaderImgResponseData(headerImagesResponseData?.data?.data);
  }, [headerImagesResponseData]);

  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  return (
    <Layout>
      <HeaderNav
        isMobile={isMobile}
        headerImagesResponse={headerImgResponseData}
      />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {/* to show vertical nav  in dekstop*/}
        {isNavSide &&
        checkPermission("SITE_CONFIGURATION", "SHOW_NAVIGATION") &&
        !isMobile ? (
          <SideNav routeInfo={currentRouteInfo} sideMenuData={sidemenu} />
        ) : null}
        {/* vertical nav end */}
        <Layout
          className="app-layout"
          style={
            checkPermission("SITE_CONFIGURATION", "SHOW_NAVIGATION")
              ? getLayoutDirectionGutter()
              : {}
          }
        >
          <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {/* to show vertical nav in mobile */}
      {isMobile && checkPermission("SITE_CONFIGURATION", "SHOW_NAVIGATION") && (
        <MobileNav sideMenuData={sidemenu} />
      )}
      {/* vertical nav mobile end */}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
