import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sensorAnalyticsList: { loading: false, data: null, error: null },
};

export const SensorAnalytics = createSlice({
  name: "sensorAnalytics",
  initialState,
  reducers: {
    fetchSensorAnalyticsListRequest: (state, action) => {
      state.sensorAnalyticsList.loading = true;
      state.sensorAnalyticsList.error = null;
    },
    fetchSensorAnalyticsListSuccess: (state, action) => {
      state.sensorAnalyticsList.data = action.payload;
      state.sensorAnalyticsList.loading = false;
      state.sensorAnalyticsList.error = null;
    },
    fetchSensorAnalyticsListFailure: (state, action) => {
      state.sensorAnalyticsList.data = null;
      state.sensorAnalyticsList.loading = false;
      state.sensorAnalyticsList.error = action.payload;
    },
  },
});

export const {
  fetchSensorAnalyticsListRequest,
  fetchSensorAnalyticsListSuccess,
  fetchSensorAnalyticsListFailure,
} = SensorAnalytics.actions;

export const getSensorAnalyticsListState = (store) =>
  store.sensorAnalytics?.sensorAnalyticsList;

export default SensorAnalytics.reducer;