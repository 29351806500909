import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestAgreementList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/agreement`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/agreement?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateAgreement(data) {
  return fetch.post(`${BASE_URL}/api/app/agreement`, data);
}

export function requestUpdateAgreement(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/agreement/updateAgreement/${data._id}`, body);  // wrapper service
}

export function requestAgreementDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/agreement/getById/${data.id}`);
}

export function requestCheckMachineAvailability(data) {
  return fetch.post(
    `${BASE_URL}/api/app/agreement/checkMachineAvailability`,
    data
  );
}

export function requestAgreementKPIData(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/agreement/agreementKpi`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/agreement/agreementKpi?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestAgreementPdf(data) {
  return fetch.post(`${BASE_URL}/api/app/agreement/generatePdf/${data.id}`);
}

export function requestCreateAgreementClient(data) {
  const body = { ...data};
  delete body["_id"];
  return fetch.post(`${BASE_URL}/api/app/agreement/generate_client_id/${data.id}`,body);
}

