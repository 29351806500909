import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestClientSpocList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/client-spoc`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/client-spoc?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateClientSpoc(data) {
  return fetch.post(`${BASE_URL}/api/app/client-spoc`, data);
}

export function requestUpdateClientSpoc(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/client-spoc/${data._id}`, body);
}

export function requestClientSpocDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/client-spoc/getById/${data.id}`);
}

export function requestDeleteClientSpoc(data) {
  return fetch.delete(`${BASE_URL}/api/app/client-spoc/${data}`);
}

export function requestClientSpocDropdownList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/client-spoc/getSpocList`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/client-spoc/getSpocList/?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}
