import * as Yup from "yup";
import subscriptionRequest from "./subscription-request.json";

const gstNumberRegExp =
  "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$";
const allowFloatingNumber = /^[+-]?\d+(\.\d+)?$/;

// formik validation
export const initialValuesSchema = Yup.object().shape({
  companyName: Yup.string().required(
    `${subscriptionRequest?.FORM_COMPANYNAME} is required`
  ),
  gstNo: Yup.string().matches(gstNumberRegExp, "GST Number is not valid"),
  companyTeamSize: Yup.string()
    .matches(allowFloatingNumber, {
      message: "Please enter valid number",
    })
    .required(`${subscriptionRequest?.FORM_NO_OF_USERS} is required`),
  address: Yup.string().required(
    `${subscriptionRequest?.FORM_COMPANY_ADDRESS} is required`
  ),
  termsAndConditions: Yup.bool(),
  acceptPrivacyPolicy: Yup.bool(),
});
