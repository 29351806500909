import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

export function requestInvoiceItemMasterScreenList(params) {
  if (params === undefined) {
    return fetch.get(`${API_BASE_URL}/api/app/invoice-item-master`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${API_BASE_URL}/api/app/invoice-item-master?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateInvoiceItemMasterScreen(data) {
  return fetch.post(`${API_BASE_URL}/api/app/invoice-item-master`, data);
}

export function requestUpdateInvoiceItemMasterScreen(data) {
  return fetch.put(`${API_BASE_URL}/api/app/invoice-item-master/${data._id}`, { ...data });
}

export function requestDeleteInvoiceItemMasterScreen(data) {
  return fetch.delete(`${API_BASE_URL}/api/app/invoice-item-master/${data}`);
}

export function requestInvoiceItemMasterScreenDataById(data) {
  return fetch.get(`${API_BASE_URL}/api/app/invoice-item-master/getById/${data.id}`);
}

