import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoiceItemMasterList: { loading: false, data: null, error: null },
};

export const invoiceItemMasterSlice = createSlice({
  name: "invoiceItemMaster",
  initialState,
  reducers: {
    fetchInvoiceItemMasterListRequest: (invoiceItemMaster, action) => {
      invoiceItemMaster.invoiceItemMasterList.loading = true;
      invoiceItemMaster.invoiceItemMasterList.error = null;
    },
    fetchInvoiceItemMasterListSuccess: (invoiceItemMaster, action) => {
      invoiceItemMaster.invoiceItemMasterList.data = action.payload;
      invoiceItemMaster.invoiceItemMasterList.loading = false;
      invoiceItemMaster.invoiceItemMasterList.error = null;
    },
    fetchInvoiceItemMasterListFailure: (invoiceItemMaster, action) => {
      invoiceItemMaster.invoiceItemMasterList.data = null;
      invoiceItemMaster.invoiceItemMasterList.loading = false;
      invoiceItemMaster.invoiceItemMasterList.error = action.payload;
    },
  },
});

export const {
  fetchInvoiceItemMasterListRequest,
  fetchInvoiceItemMasterListSuccess,
  fetchInvoiceItemMasterListFailure,
} = invoiceItemMasterSlice.actions;

export const getInvoiceItemMasterListState = (store) =>
  store?.invoiceItemMaster?.invoiceItemMasterList;

export default invoiceItemMasterSlice.reducer;
