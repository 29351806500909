import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestGenerateInvoiceList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/invoice`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/invoice?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateGenerateInvoice(data) {
  return fetch.post(`${BASE_URL}/api/app/invoice/createInvoiceWithItems`, data);
}

export function requestUpdateGenerateInvoice(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/invoice/update-invoice`, body);
}

export function requestDeleteGenerateInvoice(data) {
  return fetch.delete(`${BASE_URL}/api/app/invoice/${data}`);
}

export function requestGenerateInvoiceDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/invoice/getById/${data.id}`);
}

export function requestGenerateInvoicePDF(data) {
  return fetch.post(`${BASE_URL}/api/app/invoice/generatePdf/${data.id}`);
}
