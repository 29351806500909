import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientSpocList: { loading: false, data: null, error: null },
  createClientSpoc: { loading: false, data: null, error: null },
  updateClientSpoc: { loading: false, data: null, error: null },
  clientSpocGetById: { loading: false, data: null, error: null },
  deleteClientSpoc: { loading: false, data: null, error: null },
  clientSpocDropdownList: { loading: false, data: null, error: null },
};

export const clientSpocSlice = createSlice({
  name: "clientSpoc",
  initialState,
  reducers: {
    fetchClientSpocListRequest: (clientSpoc, action) => {
      clientSpoc.clientSpocList.loading = true;
      clientSpoc.clientSpocList.error = null;
    },
    fetchClientSpocListSuccess: (clientSpoc, action) => {
      clientSpoc.clientSpocList.data = action.payload;
      clientSpoc.clientSpocList.loading = false;
      clientSpoc.clientSpocList.error = null;
    },
    fetchClientSpocListFailure: (clientSpoc, action) => {
      clientSpoc.clientSpocList.data = null;
      clientSpoc.clientSpocList.loading = false;
      clientSpoc.clientSpocList.error = action.payload;
    },
    createClientSpocRequest: (clientSpoc, action) => {
      clientSpoc.createClientSpoc.loading = true;
      clientSpoc.createClientSpoc.error = null;
    },
    createClientSpocSuccess: (clientSpoc, action) => {
      clientSpoc.createClientSpoc.data = action.payload;
      clientSpoc.createClientSpoc.loading = false;
      clientSpoc.createClientSpoc.error = null;
      clientSpoc.clientSpocList = addNewClientSpocSuccess(action.payload, {
        ...clientSpoc.clientSpocList,
      });
    },
    createClientSpocFailure: (clientSpoc, action) => {
      clientSpoc.createClientSpoc.data = null;
      clientSpoc.createClientSpoc.loading = false;
      clientSpoc.createClientSpoc.error = action.payload;
    },

    // update clientSpoc
    updateClientSpocRequest: (clientSpoc, action) => {
      clientSpoc.updateClientSpoc.loading = true;
      clientSpoc.updateClientSpoc.error = null;
    },
    updateClientSpocSuccess: (clientSpoc, action) => {
      clientSpoc.updateClientSpoc.data = action.payload;
      clientSpoc.updateClientSpoc.loading = false;
      clientSpoc.updateClientSpoc.error = null;
      clientSpoc.clientSpocList = updateClientSpocListSuccess(action.payload, {
        ...clientSpoc.clientSpocList,
      });
    },
    updateClientSpocFailure: (clientSpoc, action) => {
      clientSpoc.updateClientSpoc.data = null;
      clientSpoc.updateClientSpoc.loading = false;
      clientSpoc.updateClientSpoc.error = action.payload;
    },

    // clientSpoc get by id
    clientSpocGetByIdRequest: (clientSpoc, action) => {
      clientSpoc.clientSpocGetById.loading = true;
      clientSpoc.clientSpocGetById.error = null;
    },
    clientSpocGetByIdSuccess: (clientSpoc, action) => {
      clientSpoc.clientSpocGetById.data = action.payload;
      clientSpoc.clientSpocGetById.loading = false;
      clientSpoc.clientSpocGetById.error = null;
    },
    clientSpocGetByIdFailure: (clientSpoc, action) => {
      clientSpoc.clientSpocGetById.data = null;
      clientSpoc.clientSpocGetById.loading = false;
      clientSpoc.clientSpocGetById.error = action.payload;
    },

    // delete client spoc
    deleteClientSpocRequest: (clientSpoc, action) => {
      clientSpoc.deleteClientSpoc.loading = true;
      clientSpoc.deleteClientSpoc.error = null;
    },
    deleteClientSpocSuccess: (clientSpoc, action) => {
      clientSpoc.deleteClientSpoc.loading = false;
      clientSpoc.deleteClientSpoc.error = null;
      clientSpoc.deleteClientSpoc.data = action.payload;
      clientSpoc.clientSpocList = deleteInstance(action.payload, {
        ...clientSpoc.clientSpocList,
      });
    },
    deleteClientSpocFailure: (clientSpoc, action) => {
      clientSpoc.deleteClientSpoc.data = null;
      clientSpoc.deleteClientSpoc.loading = false;
      clientSpoc.deleteClientSpoc.error = action.payload;
    },

    fetchClientSpocDropdownListRequest: (clientSpoc, action) => {
      clientSpoc.clientSpocDropdownList.loading = true;
      clientSpoc.clientSpocDropdownList.error = null;
    },
    fetchClientSpocDropdownListSuccess: (clientSpoc, action) => {
      clientSpoc.clientSpocDropdownList.data = action.payload;
      clientSpoc.clientSpocDropdownList.loading = false;
      clientSpoc.clientSpocDropdownList.error = null;
    },
    fetchClientSpocDropdownListFailure: (clientSpoc, action) => {
      clientSpoc.clientSpocDropdownList.data = null;
      clientSpoc.clientSpocDropdownList.loading = false;
      clientSpoc.clientSpocDropdownList.error = action.payload;
    },

    // reset create, update
    resetClientSpocData: (clientSpoc, action) => {
      clientSpoc.createClientSpoc.data = null;
      clientSpoc.createClientSpoc.loading = false;
      clientSpoc.createClientSpoc.error = null;

      clientSpoc.updateClientSpoc.data = null;
      clientSpoc.updateClientSpoc.loading = false;
      clientSpoc.updateClientSpoc.error = null;

      clientSpoc.deleteClientSpoc.data = null;
      clientSpoc.deleteClientSpoc.loading = false;
      clientSpoc.deleteClientSpoc.error = null;
    },
  },
});

function addNewClientSpocSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

function updateClientSpocListSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });
  return state;
}

// delete instance function
function deleteInstance(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchClientSpocListRequest,
  fetchClientSpocListSuccess,
  fetchClientSpocListFailure,

  createClientSpocRequest,
  createClientSpocSuccess,
  createClientSpocFailure,

  updateClientSpocRequest,
  updateClientSpocSuccess,
  updateClientSpocFailure,

  clientSpocGetByIdRequest,
  clientSpocGetByIdSuccess,
  clientSpocGetByIdFailure,

  deleteClientSpocRequest,
  deleteClientSpocSuccess,
  deleteClientSpocFailure,

  fetchClientSpocDropdownListRequest,
  fetchClientSpocDropdownListSuccess,
  fetchClientSpocDropdownListFailure,

  resetClientSpocData,
} = clientSpocSlice.actions;

export const getClientSpocListState = (store) =>
  store.clientSpoc?.clientSpocList;
export const getClientSpocCreateState = (store) =>
  store.clientSpoc?.createClientSpoc;
export const getClientSpocUpdateState = (store) =>
  store.clientSpoc?.updateClientSpoc;
export const getByIdStateClientSpoc = (store) =>
  store.clientSpoc?.clientSpocGetById;
export const getClientSpocDeleteState = (store) =>
  store?.clientSpoc?.deleteClientSpoc;
export const getClientSpocDropdownListState = (store) =>
  store.clientSpoc?.clientSpocDropdownList;
export default clientSpocSlice.reducer;
