import { put, takeLatest } from "redux-saga/effects";
import {
  createFireExtinguisherFailure,
  createFireExtinguisherRequest,
  createFireExtinguisherSuccess,
  deleteFireExtinguisherFailure,
  deleteFireExtinguisherRequest,
  deleteFireExtinguisherSuccess,
  fetchFireExtinguisherListFailure,
  fetchFireExtinguisherListRequest,
  fetchFireExtinguisherListSuccess,
  getFireExtinguisherDetailsFailure,
  getFireExtinguisherDetailsRequest,
  getFireExtinguisherDetailsSuccess,
  updateFireExtinguisherFailure,
  updateFireExtinguisherRequest,
  updateFireExtinguisherSuccess,
} from "redux/reducers/fe/fireExtinguisher";
import {
  requestCreateFireExtinguisher,
  requestDeleteFireExtinguisher,
  requestFireExtinguisherDataById,
  requestFireExtinguisherList,
  requestUpdateFireExtinguisher,
} from "services/fe/fireExtinguisher";

export function* watchFireExtinguisherSaga() {
  yield takeLatest(fetchFireExtinguisherListRequest, fetchFireExtinguisherList);
  yield takeLatest(createFireExtinguisherRequest, createFireExtinguisher);
  yield takeLatest(updateFireExtinguisherRequest, updateFireExtinguisher);
  yield takeLatest(deleteFireExtinguisherRequest, deleteFireExtinguisher);
  yield takeLatest(
    getFireExtinguisherDetailsRequest,
    getFireExtinguisherGetByIdData
  );
}
function* fetchFireExtinguisherList({ payload }) {
  try {
    const response = yield requestFireExtinguisherList(payload);
    yield put(fetchFireExtinguisherListSuccess(response));
  } catch (error) {
    yield put(
      fetchFireExtinguisherListFailure("Failed to get list of FireExtinguisher")
    );
  }
}

function* createFireExtinguisher({ payload }) {
  try {
    //service add
    const response = yield requestCreateFireExtinguisher(payload);
    yield put(createFireExtinguisherSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to create FireExtinguisher";
    yield put(createFireExtinguisherFailure(message));
  }
}

function* updateFireExtinguisher({ payload }) {
  try {
    //service update
    const response = yield requestUpdateFireExtinguisher(payload);
    yield put(updateFireExtinguisherSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to update FireExtinguisher";
    yield put(updateFireExtinguisherFailure(message));
  }
}

function* deleteFireExtinguisher({ payload }) {
  try {
    const response = yield requestDeleteFireExtinguisher(payload);
    yield put(deleteFireExtinguisherSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to Delete FireExtinguisher";
    yield put(deleteFireExtinguisherFailure(message));
  }
}

function* getFireExtinguisherGetByIdData({ payload }) {
  try {
    const response = yield requestFireExtinguisherDataById(payload);
    yield put(getFireExtinguisherDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get FireExtinguisher details";
    yield put(getFireExtinguisherDetailsFailure(message));
  }
}
