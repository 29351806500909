import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    manualPaymentList: { loading: false, data: null, error: null },
    createManualPayment: { loading: false, data: null, error: null },
    updateManualPayment: { loading: false, data: null, error: null },
    deleteManualPayment: { loading: false, data: null, error: null },
    getManualPaymentById: { loading: false, data: null, error: null },
    updatePaymentStage: { loading: false, data: null, error: null },
};

export const manualPaymentSlice = createSlice({
    name: "manualPayment",
    initialState,
    reducers: {
        fetchManualPaymentListRequest: (manualPayment, action) => {
            manualPayment.manualPaymentList.loading = true;
            manualPayment.manualPaymentList.error = null;
        },
        fetchManualPaymentListSuccess: (manualPayment, action) => {
            manualPayment.manualPaymentList.data = action.payload;
            manualPayment.manualPaymentList.loading = false;
            manualPayment.manualPaymentList.error = null;
        },
        fetchManualPaymentListFailure: (manualPayment, action) => {
            manualPayment.manualPaymentList.data = null;
            manualPayment.manualPaymentList.loading = false;
            manualPayment.manualPaymentList.error = action.payload;
        },

        //create ManualPayment
        createManualPaymentRequest: (manualPayment, action) => {
            manualPayment.createManualPayment.loading = true;
            manualPayment.createManualPayment.error = null;
        },
        createManualPaymentSuccess: (manualPayment, action) => {
            manualPayment.createManualPayment.data = action.payload;
            manualPayment.createManualPayment.loading = false;
            manualPayment.createManualPayment.error = null;
            manualPayment.manualPaymentList =
                addNewManualPaymentSuccess(action.payload, {
                    ...manualPayment.manualPaymentList,
                });
        },
        createManualPaymentFailure: (manualPayment, action) => {
            manualPayment.createManualPayment.data = null;
            manualPayment.createManualPayment.loading = false;
            manualPayment.createManualPayment.error = action.payload;
        },

        //update manualPayment details
        updateManualPaymentRequest: (manualPayment, action) => {
            manualPayment.updateManualPayment.loading = true;
            manualPayment.updateManualPayment.error = null;
        },
        updateManualPaymentSuccess: (manualPayment, action) => {
            manualPayment.updateManualPayment.data = action.payload;
            manualPayment.updateManualPayment.loading = false;
            manualPayment.updateManualPayment.error = null;
            manualPayment.manualPaymentList =
                updateNewManualPaymentSuccess(
                    action.payload,
                    manualPayment.manualPaymentList
                );
        },
        updateManualPaymentFailure: (manualPayment, action) => {
            manualPayment.updateManualPayment.data = null;
            manualPayment.updateManualPayment.loading = false;
            manualPayment.updateManualPayment.error = action.payload;
        },

        // delete manualPayment
        deleteManualPaymentRequest: (manualPayment, action) => {
            manualPayment.deleteManualPayment.loading = true;
            manualPayment.deleteManualPayment.error = null;
        },
        deleteManualPaymentSuccess: (manualPayment, action) => {
            manualPayment.deleteManualPayment.data = action.payload;
            manualPayment.deleteManualPayment.loading = false;
            manualPayment.deleteManualPayment.error = null;
            manualPayment.manualPaymentList =
                deleteNewManualPaymentSuccess(action.payload, {
                    ...manualPayment.manualPaymentList,
                });
        },
        deleteManualPaymentFailure: (manualPayment, action) => {
            manualPayment.deleteManualPayment.data = null;
            manualPayment.deleteManualPayment.loading = false;
            manualPayment.deleteManualPayment.error = action.payload;
        },

        // getById manualPayment details
        getManualPaymentByIdRequest: (manualPayment, action) => {
            manualPayment.getManualPaymentById.loading = true;
            manualPayment.getManualPaymentById.error = null;
        },
        getManualPaymentByIdSuccess: (manualPayment, action) => {
            manualPayment.getManualPaymentById.data = action.payload;
            manualPayment.getManualPaymentById.loading = false;
            manualPayment.getManualPaymentById.error = null;
        },
        getManualPaymentByIdFailure: (manualPayment, action) => {
            manualPayment.getManualPaymentById.data = null;
            manualPayment.getManualPaymentById.loading = false;
            manualPayment.getManualPaymentById.error = action.payload;
        },

        // reset manualPayment details
        resetManualPayment: (manualPayment, action) => {
            manualPayment.createManualPayment.data = null;
            manualPayment.createManualPayment.loading = false;
            manualPayment.createManualPayment.error = null;

            manualPayment.updateManualPayment.data = null;
            manualPayment.updateManualPayment.loading = false;
            manualPayment.updateManualPayment.error = null;

            manualPayment.deleteManualPayment.data = null;
            manualPayment.deleteManualPayment.loading = false;
            manualPayment.deleteManualPayment.error = null;

            manualPayment.getManualPaymentById.data = null;
            manualPayment.getManualPaymentById.loading = false;
            manualPayment.getManualPaymentById.error = null;

            manualPayment.updatePaymentStage.data = null;
            manualPayment.updatePaymentStage.loading = false;
            manualPayment.updatePaymentStage.error = null;
        },

        resetManualPaymentList: (manualPayment, action) => {
            manualPayment.manualPaymentList.data = null;
            manualPayment.manualPaymentList.loading = false;
            manualPayment.manualPaymentList.error = null;
        },

        //update payment stage details
        updatePaymentStageRequest: (manualPayment, action) => {
            manualPayment.updatePaymentStage.loading = true;
            manualPayment.updatePaymentStage.error = null;
        },
        updatePaymentStageSuccess: (manualPayment, action) => {
            manualPayment.updatePaymentStage.data = action.payload;
            manualPayment.updatePaymentStage.loading = false;
            manualPayment.updatePaymentStage.error = null;
            manualPayment.manualPaymentList =
                updateNewPaymentStageSuccess(
                    action.payload,
                    manualPayment.manualPaymentList
                );
        },
        updatePaymentStageFailure: (manualPayment, action) => {
            manualPayment.updatePaymentStage.data = null;
            manualPayment.updatePaymentStage.loading = false;
            manualPayment.updatePaymentStage.error = action.payload;
        },
    },
});

function addNewManualPaymentSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewManualPaymentSuccess(dataToUpdate, state) {
    if (state.data?.data.length) {
        const updatedData = state.data.data.map((value) => {
            if (value._id !== dataToUpdate._id) return value;
            return { ...value, ...dataToUpdate };
        });
        return { ...state, data: { ...state.data, data: updatedData } };
    } else {
        return state;
    }
}

function deleteNewManualPaymentSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

function updateNewPaymentStageSuccess(dataToUpdate, state) {
    if (state.data?.data.length) {
        const updatedData = state.data.data.map((value) => {
            if (value._id !== dataToUpdate._id) return value;
            return { ...value, ...dataToUpdate };
        });
        return { ...state, data: { ...state.data, data: updatedData } };
    } else {
        return state;
    }
}


export const {
    fetchManualPaymentListRequest,
    fetchManualPaymentListSuccess,
    fetchManualPaymentListFailure,

    createManualPaymentRequest,
    createManualPaymentSuccess,
    createManualPaymentFailure,

    updateManualPaymentRequest,
    updateManualPaymentSuccess,
    updateManualPaymentFailure,

    deleteManualPaymentRequest,
    deleteManualPaymentSuccess,
    deleteManualPaymentFailure,

    getManualPaymentByIdRequest,
    getManualPaymentByIdSuccess,
    getManualPaymentByIdFailure,

    resetManualPayment,
    resetManualPaymentList,

    updatePaymentStageRequest,
    updatePaymentStageSuccess,
    updatePaymentStageFailure,
} = manualPaymentSlice.actions;

export const getManualPaymentListState = (store) =>
    store?.manualPayment?.manualPaymentList;
export const getManualPaymentCreateState = (store) =>
    store?.manualPayment?.createManualPayment;
export const getManualPaymentUpdateState = (store) =>
    store?.manualPayment?.updateManualPayment;
export const getManualPaymentDeleteState = (store) =>
    store?.manualPayment?.deleteManualPayment;
export const getManualPaymentGetByIdState = (store) =>
    store?.manualPayment?.getManualPaymentById;
export const getPaymentStageUpdateState = (store) =>
    store?.manualPayment?.updatePaymentStage;
export default manualPaymentSlice.reducer;
