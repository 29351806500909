import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestUpdateDocument(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/document/${data._id}`, body);
}

export function requestDeleteDocument(data) {
  return fetch.delete(`${BASE_URL}/api/app/document/${data}`);
}