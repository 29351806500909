import { put, takeLatest } from "redux-saga/effects";
import {
    fetchOrganizationScreenListFailure,
    fetchOrganizationScreenListRequest,
    fetchOrganizationScreenListSuccess,
    createOrganizationScreenRequest,
    createOrganizationScreenSuccess,
    createOrganizationScreenFailure,
    updateOrganizationScreenRequest,
    updateOrganizationScreenSuccess,
    updateOrganizationScreenFailure,
    deleteOrganizationScreenRequest,
    deleteOrganizationScreenSuccess,
    deleteOrganizationScreenFailure,
    organizationScreenGetByIdRequest,
    organizationScreenGetByIdSuccess,
    organizationScreenGetByIdFailure
} from "redux/reducers/organizationScreen";
import {
    requestOrganizationScreenList,
    requestCreateOrganizationScreen,
    requestUpdateOrganizationScreen,
    requestDeleteOrganizationScreen,
    requestOrganizationScreenDataById
} from "services/organizationScreen";

export function* watchOrganizationScreenSaga() {
    yield takeLatest(fetchOrganizationScreenListRequest, fetchOrganizationScreenList);
    yield takeLatest(createOrganizationScreenRequest, createOrganizationScreen);
    yield takeLatest(updateOrganizationScreenRequest, updateOrganizationScreen);
    yield takeLatest(deleteOrganizationScreenRequest, deleteOrganizationScreen);
    yield takeLatest(organizationScreenGetByIdRequest, getOrganizationScreenGetById);
}

function* fetchOrganizationScreenList({ payload }) {
    try {
        const response = yield requestOrganizationScreenList(payload);
        yield put(fetchOrganizationScreenListSuccess(response));
    } catch (error) {
        yield put(
            fetchOrganizationScreenListFailure("Failed to get list of organization")
        )
    }
}

function* createOrganizationScreen({ payload }) {
    try {
        const response = yield requestCreateOrganizationScreen(payload);
        yield put(createOrganizationScreenSuccess(response.data))
    } catch (error) {
        yield put(createOrganizationScreenFailure("Failed to create organization"))
    }
}

function* updateOrganizationScreen({ payload }) {
    try {
        const response = yield requestUpdateOrganizationScreen(payload);
        yield put(updateOrganizationScreenSuccess(response.data))
    } catch (error) {
        yield put(updateOrganizationScreenFailure("Failed to update organization"))
    }
}

function* deleteOrganizationScreen({ payload }) {
    try {
        const response = yield requestDeleteOrganizationScreen(payload);
        yield put(deleteOrganizationScreenSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete organization";
        yield put(deleteOrganizationScreenFailure(message));
    }
}

function* getOrganizationScreenGetById({ payload }) {
    try {
        const response = yield requestOrganizationScreenDataById(payload);
        yield put(organizationScreenGetByIdSuccess(response))
    } catch (error) {
        yield put(organizationScreenGetByIdFailure("Failed to get organization details"))
    }
}