import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  supplierList: { loading: false, data: null, error: null },
  createSupplier: { loading: false, data: null, error: null },
  updateSupplier: { loading: false, data: null, error: null },
  deleteSupplier: { loading: false, data: null, error: null },
  getSupplierById: { loading: false, data: null, error: null },
  supplierDropdownList: { loading: false, data: null, error: null },
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    fetchSupplierListRequest: (supplier, action) => {
      supplier.supplierList.loading = true;
      supplier.supplierList.error = null;
    },
    fetchSupplierListSuccess: (supplier, action) => {
      supplier.supplierList.data = action.payload;
      supplier.supplierList.loading = false;
      supplier.supplierList.error = null;
    },
    fetchSupplierListFailure: (supplier, action) => {
      supplier.supplierList.data = null;
      supplier.supplierList.loading = false;
      supplier.supplierList.error = action.payload;
    },

    //create Supplier
    createSupplierRequest: (supplier, action) => {
      supplier.createSupplier.loading = true;
      supplier.createSupplier.error = null;
    },
    createSupplierSuccess: (supplier, action) => {
      supplier.createSupplier.data = action.payload;
      supplier.createSupplier.loading = false;
      supplier.createSupplier.error = null;
      supplier.supplierList = addNewSupplierSuccess(action.payload, {
        ...supplier.supplierList,
      });
    },
    createSupplierFailure: (supplier, action) => {
      supplier.createSupplier.data = null;
      supplier.createSupplier.loading = false;
      supplier.createSupplier.error = action.payload;
    },

    //update supplier details
    updateSupplierRequest: (supplier, action) => {
      supplier.updateSupplier.loading = true;
      supplier.updateSupplier.error = null;
    },
    updateSupplierSuccess: (supplier, action) => {
      supplier.updateSupplier.data = action.payload;
      supplier.updateSupplier.loading = false;
      supplier.updateSupplier.error = null;
      supplier.supplierList = updateNewSupplierSuccess(
        action.payload,
        supplier.supplierList
      );
    },
    updateSupplierFailure: (supplier, action) => {
      supplier.updateSupplier.data = null;
      supplier.updateSupplier.loading = false;
      supplier.updateSupplier.error = action.payload;
    },

    // delete supplier
    deleteSupplierRequest: (supplier, action) => {
      supplier.deleteSupplier.loading = true;
      supplier.deleteSupplier.error = null;
    },
    deleteSupplierSuccess: (supplier, action) => {
      supplier.deleteSupplier.data = action.payload;
      supplier.deleteSupplier.loading = false;
      supplier.deleteSupplier.error = null;
      supplier.supplierList = deleteNewSupplierSuccess(action.payload, {
        ...supplier.supplierList,
      });
    },
    deleteSupplierFailure: (supplier, action) => {
      supplier.deleteSupplier.data = null;
      supplier.deleteSupplier.loading = false;
      supplier.deleteSupplier.error = action.payload;
    },

    // getById supplier details
    getSupplierByIdRequest: (supplier, action) => {
      supplier.getSupplierById.loading = true;
      supplier.getSupplierById.error = null;
    },
    getSupplierByIdSuccess: (supplier, action) => {
      supplier.getSupplierById.data = action.payload;
      supplier.getSupplierById.loading = false;
      supplier.getSupplierById.error = null;
    },
    getSupplierByIdFailure: (supplier, action) => {
      supplier.getSupplierById.data = null;
      supplier.getSupplierById.loading = false;
      supplier.getSupplierById.error = action.payload;
    },

    // get supplier list for binding dropdown
    fetchSupplierDropdownListRequest: (supplier, action) => {
      supplier.supplierDropdownList.loading = true;
      supplier.supplierDropdownList.error = null;
    },
    fetchSupplierDropdownListSuccess: (supplier, action) => {
      supplier.supplierDropdownList.data = action.payload;
      supplier.supplierDropdownList.loading = false;
      supplier.supplierDropdownList.error = null;
    },
    fetchSupplierDropdownListFailure: (supplier, action) => {
      supplier.supplierDropdownList.data = null;
      supplier.supplierDropdownList.loading = false;
      supplier.supplierDropdownList.error = action.payload;
    },

    // reset supplier details
    resetSupplier: (supplier, action) => {
      supplier.createSupplier.data = null;
      supplier.createSupplier.loading = false;
      supplier.createSupplier.error = null;

      supplier.updateSupplier.data = null;
      supplier.updateSupplier.loading = false;
      supplier.updateSupplier.error = null;

      supplier.deleteSupplier.data = null;
      supplier.deleteSupplier.loading = false;
      supplier.deleteSupplier.error = null;

      supplier.getSupplierById.data = null;
      supplier.getSupplierById.loading = false;
      supplier.getSupplierById.error = null;
    },

    resetSupplierList: (supplier, action) => {
      supplier.supplierList.data = null;
      supplier.supplierList.loading = false;
      supplier.supplierList.error = null;
    },
  },
});

function addNewSupplierSuccess(dataToAdd, state) {
  state.data.data = [dataToAdd, ...state.data.data];
  state.data.total++;
  return state;
}

function updateNewSupplierSuccess(dataToUpdate, state) {
  if (state.data?.data.length) {
    const updatedData = state.data.data.map((value) => {
      if (value._id !== dataToUpdate._id) return value;
      return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
  } else {
    return state;
  }
}

function deleteNewSupplierSuccess(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
  return state;
}

export const {
  fetchSupplierListRequest,
  fetchSupplierListSuccess,
  fetchSupplierListFailure,

  createSupplierRequest,
  createSupplierSuccess,
  createSupplierFailure,

  updateSupplierRequest,
  updateSupplierSuccess,
  updateSupplierFailure,

  deleteSupplierRequest,
  deleteSupplierSuccess,
  deleteSupplierFailure,

  getSupplierByIdRequest,
  getSupplierByIdSuccess,
  getSupplierByIdFailure,

  fetchSupplierDropdownListRequest,
  fetchSupplierDropdownListSuccess,
  fetchSupplierDropdownListFailure,

  resetSupplier,
  resetSupplierList,
} = supplierSlice.actions;

export const getSupplierListState = (store) => store?.supplier?.supplierList;
export const getSupplierCreateState = (store) =>
  store?.supplier?.createSupplier;
export const getSupplierUpdateState = (store) =>
  store?.supplier?.updateSupplier;
export const getSupplierDeleteState = (store) =>
  store?.supplier?.deleteSupplier;
export const getSupplierGetByIdState = (store) =>
  store?.supplier?.getSupplierById;
export const getSupplierDropdownListState = (store) =>
  store?.supplier?.supplierDropdownList;
export default supplierSlice.reducer;
