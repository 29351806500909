import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissionList: {
    loading: false,
    data: localStorage.getDecryptedItem("p_m__"),
    error: null,
  },
};

export const Permissions = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    fetchPermissionListRequest: (state, action) => {
      state.permissionList.loading = true;
      state.permissionList.error = null;
    },
    fetchPermissionListSuccess: (state, action) => {
      localStorage.setEncryptedItem("p_m__", action.payload?.data);
      state.permissionList.data = action.payload;
      state.permissionList.loading = false;
      state.permissionList.error = null;
    },
    fetchPermissionListFailure: (state, action) => {
      state.permissionList.data = null;
      state.permissionList.loading = false;
      state.permissionList.error = action.payload;
    },
  },
});

export const {
  fetchPermissionListRequest,
  fetchPermissionListSuccess,
  fetchPermissionListFailure,
} = Permissions.actions;

export const getPermissionListState = (store) =>
  store.permissions?.permissionList;

export default Permissions.reducer;
