import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

export function requestManualPaymentList(params) {
    if (params === undefined) {
        return fetch.get(`${API_BASE_URL}/api/app/payment`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${API_BASE_URL}/api/app/payment?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    }
}

export function requestCreateManualPayment(data) {
    return fetch.post(`${API_BASE_URL}/api/app/payment`, data);
}

export function requestUpdateManualPayment(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${API_BASE_URL}/api/app/payment/${data._id}`, body);
}

export function requestDeleteManualPayment(data) {
    return fetch.delete(`${API_BASE_URL}/api/app/payment/${data}`);
}

export function requestManualPaymentDataById(data) {
    return fetch.get(`${API_BASE_URL}/api/app/payment/getById/${data.id}`);
}

export function requestUpdatePaymentStage(data) {
    return fetch.patch(`${API_BASE_URL}/api/app/payment/approve`, data);
}
