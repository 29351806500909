import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestSmartProposalList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/proposal`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/proposal?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateSmartProposal(data) {
  return fetch.post(`${BASE_URL}/api/app/proposal`, data);
}

export function requestUpdateSmartProposal(value) {
  const data = { ...value };
  delete data["_id"];
  return fetch.put(`${BASE_URL}/api/app/proposal/${value._id}`, data);
}

export function requestSmartProposalDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/proposal/getById/${data.id}`);
}

export function requestProposalActivity(params) {
  return fetch.get(`${BASE_URL}/api/app/proposal-activity`, {
    params: { ...params },
  });
}

export function requestForArchive(value) {
  return fetch.patch(`${BASE_URL}/api/app/proposal/toggleArchive`, {
    ...value,
  });
}

export function requestForBookMark(value) {
  return fetch.patch(`${BASE_URL}/api/app/proposal/toggleBookmark`, {
    ...value,
  });
}

export function requestForAddNotes(value) {
  return fetch.patch(`${BASE_URL}/api/app/proposal/addNotes`, {
    ...value,
  });
}

export function requestCreateSendDraftProposal(data) {
  return fetch.post(`${BASE_URL}/api/app/proposal/draftProposal`, {
    ...data,
  });
}

export function requestGeneratePublicLink(data) {
  return fetch.patch(`${BASE_URL}/api/app/proposal/generatePublicLink`, {
    ...data,
  });
}

export function requestPublicProposalDetails(data) {
  return fetch.patch(`${BASE_URL}/api/public/business-proposal/viewProposal`, {
    ...data,
  });
}
export function requestAcceptProposal(data) {
  return fetch.patch(
    `${BASE_URL}/api/public/business-proposal/acceptProposal`,
    {
      ...data,
    }
  );
}
export function requestUpdateViewTime(data) {
  return fetch.patch(
    `${BASE_URL}/api/public/business-proposal/updateViewTime`,
    {
      ...data,
    }
  );
}

export function requestProposalKPIData(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/proposal/getKpi`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/proposal/getKpi?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestProposalFileUpload(data) {
  return fetch.post(`${BASE_URL}/api/app/proposal/uploadFile/${data.id}`, {
    ...data,
  });
}

export function requestProposalPDF(data) {
  return fetch.post(`${BASE_URL}/api/app/proposal/generatePdf/${data.id}`);
}
