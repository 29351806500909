import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestcreditNoteList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/credit-note`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/credit-note?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}
// params: { limit: Number.MAX_SAFE_INTEGER },

export function requestCreatecreditNote(data) {
  return fetch.post(`${BASE_URL}/api/app/credit-note`, data);
}

export function requestUpdatecreditNote(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/credit-note/update/credit-note`, body);
}

export function requestApprovedcreditNote(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/credit-note/update/list`, body);
}

export function requestDeletecreditNote(data) {
  return fetch.delete(`${BASE_URL}/api/app/credit-note/delete-credit-note/${data}`);
}

export function requestcreditNoteDataById(data) {
  console.log(data)
  return fetch.get(`${BASE_URL}/api/app/credit-note/get-credit-note-items-invoice-items/${data.id}`);
}
