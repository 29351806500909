import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    checkListViewList: { loading: false, data: null, error: null },
};

export const checkListViewSlice = createSlice({
    name: "checkListView",
    initialState,
    reducers: {
        fetchCheckListViewListRequest: (checkListView, action) => {
            checkListView.checkListViewList.loading = true;
            checkListView.checkListViewList.error = null;
        },
        fetchCheckListViewListSuccess: (checkListView, action) => {
            checkListView.checkListViewList.data = action.payload;
            checkListView.checkListViewList.loading = false;
            checkListView.checkListViewList.error = null;
        },
        fetchCheckListViewListFailure: (checkListView, action) => {
            checkListView.checkListViewList.data = null;
            checkListView.checkListViewList.loading = false;
            checkListView.checkListViewList.error = action.payload;
        },
    },
});

export const {
    fetchCheckListViewListRequest,
    fetchCheckListViewListSuccess,
    fetchCheckListViewListFailure,
} = checkListViewSlice.actions;

export const getCheckListViewListState = (store) =>
    store?.checkListView?.checkListViewList;

export default checkListViewSlice.reducer;
