import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification } from 'antd';
import { Formik } from 'formik';
import { useDispatch } from "react-redux";
import { logout } from "redux/reducers/login";
import { requestChangePassword } from 'services/change-password';
import * as yup from 'yup';

const ChangePasswordForm = ({ closeModal }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const initialValues = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    const handleFormSubmit = async (values) => {
        try {
            let finalData = {
                oldPassword: values?.oldPassword,
                newPassword: values?.newPassword,
                _id: window.localStorage.getItem('user')
            };
            const response = await requestChangePassword(finalData);
            let message = response?.data || response?.data?.data || 'Please check your email';
            notification.success({
                message: message,
            });
            form.resetFields();
            closeModal();
            dispatch(logout());
        } catch (e) {
            let message = e?.response?.data?.message || e?.response?.data?.data;
            notification.error({
                message: message,
            });
        }
    };

    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&@*])[a-zA-Z0-9!@#$%^&@*]{8,20}$/;
    const initialValuesSchema = yup.object().shape({
        oldPassword: yup.string().required('Enter current password'),
        newPassword: yup
            .string()
            .matches(passwordRegex, {
                message: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character',
                excludeEmptyString: false
            })
            .required(),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('newPassword')], 'Passwords must match')
            .required('Confirm password is a required field')
    });

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={initialValuesSchema}
                onSubmit={(values, { resetForm }) => {
                    handleFormSubmit(values);
                }}
            >
                {({ values, setFieldValue, submitForm, errors }) => {
                    return (
                        <div className="content-center">
                            <div>
                                <div>
                                    <h2 className='text-center'>Change Password</h2>
                                    <p className='text-center'>Enter your new password to change your password</p>
                                </div>
                                <Form layout='vertical' form={form}>
                                    <Form.Item
                                        label="Current Password"
                                        name="oldPassword"
                                        value={values.oldPassword}
                                        onChange={(e) =>
                                            setFieldValue("oldPassword", e.target.value)
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your current password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password prefix={<LockOutlined className="text-primary" />} />
                                    </Form.Item>
                                    <Form.Item
                                        label="New Password"
                                        name="newPassword"
                                        value={values.newPassword}
                                        onChange={(e) =>
                                            setFieldValue("newPassword", e.target.value)
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your new password!',
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (!value || passwordRegex.test(value)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error('Password must contain at least one lowercase letter, uppercase letter, number, and special character')
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password prefix={<LockOutlined className="text-primary" />} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        dependencies={['newPassword']}
                                        hasFeedback
                                        value={values.confirmPassword}
                                        onChange={(e) =>
                                            setFieldValue("confirmPassword", e.target.value)
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('newPassword') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error('The two passwords do not match!')
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password prefix={<LockOutlined className="text-primary" />} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="large"
                                            style={{ width: '100%' }}
                                            block
                                            onClick={() => {
                                                submitForm();
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </>
    );
};

export default ChangePasswordForm;
