import { all } from "redux-saga/effects";
import { watchAgreementSaga } from "./agreement";
import { watchLeadSaga } from "./lead";
import { watchProposalSaga } from "./proposal";

export default function* rootSagaSalesPipeline(getState) {
  yield all([watchLeadSaga()]);
  yield all([watchProposalSaga()]);
  yield all([watchAgreementSaga()]);
}
