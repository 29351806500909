import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestInvoiceMasterList(params) {
  return fetch.get(`${BASE_URL}/api/app/invoice-item-master/getInvoiceItemMasterList`, {
    params: { limit: Number.MAX_SAFE_INTEGER, ...params },
  });
}
