import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestOrganizationScreenList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/organization`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/organization?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    }
}
export function requestCreateOrganizationScreen(data) {
    return fetch.post(`${BASE_URL}/api/app/organization`, data);
}

export function requestUpdateOrganizationScreen(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/organization/${data._id}`, body);
}

export function requestOrganizationScreenDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/organization/getById/${data.id}`);
}

export function requestDeleteOrganizationScreen(data) {
    return fetch.delete(`${API_BASE_URL}/api/app/organization/${data}`);
}
