import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestedSparePartList: { loading: false, data: null, error: null },
  requestedSparePartStatusUpdate: { loading: false, data: null, error: null },
  requestedSparePartDelete: { loading: false, data: null, error: null },
  getRequestedSparePartById: { loading: false, data: null, error: null },
};

export const RequestSpareSlice = createSlice({
  name: "requestedSparePart",
  initialState,
  reducers: {
    requestedSparePartListRequest: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartList.loading = true;
      requestedSparePart.requestedSparePartList.error = null;
    },
    requestedSparePartListSuccess: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartList.data = action.payload;
      requestedSparePart.requestedSparePartList.loading = false;
      requestedSparePart.requestedSparePartList.error = null;
    },
    requestedSparePartListFaliure: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartList.data = null;
      requestedSparePart.requestedSparePartList.loading = false;
      requestedSparePart.requestedSparePartList.error = action.payload;
    },

    //delete
    requestedSparePartDeleteRequest: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartDelete.loading = true;
      requestedSparePart.requestedSparePartDelete.error = null;
    },
    requestedSparePartDeleteSuccess: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartDelete.data = action.payload;
      requestedSparePart.requestedSparePartDelete.loading = false;
      requestedSparePart.requestedSparePartDelete.error = null;
      requestedSparePart.requestedSparePartList = deleteRequest(action.payload, {
        ...requestedSparePart.requestedSparePartList,
      });
    },
    requestedSparePartDeleteFaliure: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartDelete.data = null;
      requestedSparePart.requestedSparePartDelete.loading = false;
      requestedSparePart.requestedSparePartDelete.error = action.payload;
    },

    //status update
    requestedSparePartStatusUpdateRequest: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartStatusUpdate.loading = true;
      requestedSparePart.requestedSparePartStatusUpdate.error = null;
    },
    requestedSparePartStatusUpdateSuccess: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartStatusUpdate.data = action.payload;
      requestedSparePart.requestedSparePartStatusUpdate.loading = false;
      requestedSparePart.requestedSparePartStatusUpdate.error = null;
      requestedSparePart.requestedSparePartList = updateStatus(
        action.payload,
        requestedSparePart.requestedSparePartList
      );
    },
    requestedSparePartStatusUpdateFaliure: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartStatusUpdate.data = null;
      requestedSparePart.requestedSparePartStatusUpdate.loading = false;
      requestedSparePart.requestedSparePartStatusUpdate.error = action.payload;
    },

    // getById requestedSparePart details
    getRequestedSparePartByIdRequest: (requestedSparePart, action) => {
      requestedSparePart.getRequestedSparePartById.loading = true;
      requestedSparePart.getRequestedSparePartById.error = null;
    },
    getRequestedSparePartByIdSuccess: (requestedSparePart, action) => {
      requestedSparePart.getRequestedSparePartById.data = action.payload;
      requestedSparePart.getRequestedSparePartById.loading = false;
      requestedSparePart.getRequestedSparePartById.error = null;
    },
    getRequestedSparePartByIdFailure: (requestedSparePart, action) => {
      requestedSparePart.getRequestedSparePartById.data = null;
      requestedSparePart.getRequestedSparePartById.loading = false;
      requestedSparePart.getRequestedSparePartById.error = action.payload;
    },

    resetRequestSaprePart: (requestedSparePart, action) => {
      requestedSparePart.requestedSparePartDelete.data = null;
      requestedSparePart.requestedSparePartDelete.loading = false;
      requestedSparePart.requestedSparePartDelete.error = null;

      requestedSparePart.requestedSparePartStatusUpdate.data = null;
      requestedSparePart.requestedSparePartStatusUpdate.loading = false;
      requestedSparePart.requestedSparePartStatusUpdate.error = null;

      requestedSparePart.getRequestedSparePartById.data = null;
      requestedSparePart.getRequestedSparePartById.loading = false;
      requestedSparePart.getRequestedSparePartById.error = null;
    },
  },
});

function deleteRequest(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

function updateStatus(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

export const {
  requestedSparePartListRequest,
  requestedSparePartListSuccess,
  requestedSparePartListFaliure,

  requestedSparePartDeleteRequest,
  requestedSparePartDeleteSuccess,
  requestedSparePartDeleteFaliure,

  requestedSparePartStatusUpdateRequest,
  requestedSparePartStatusUpdateSuccess,
  requestedSparePartStatusUpdateFaliure,

  getRequestedSparePartByIdRequest,
  getRequestedSparePartByIdSuccess,
  getRequestedSparePartByIdFailure,

  resetRequestSaprePart,
} = RequestSpareSlice.actions;

export const getRequestedSparePartListState = (store) =>
  store.requestedSparePart?.requestedSparePartList;

export const getRequestedSparePartDeleteState = (store) =>
  store.requestedSparePart?.requestedSparePartDelete;

export const getRequestedSparePartStatusUpdateState = (store) =>
  store.requestedSparePart?.requestedSparePartStatusUpdate;

export const getRequestedSparePartGetByIdState = (store) =>
  store?.requestedSparePart?.getRequestedSparePartById;

export default RequestSpareSlice.reducer;
