import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    finalInvoiceList: { loading: false, data: null, error: null },
    createFinalInvoice: { loading: false, data: null, error: null },
    updateFinalInvoice: { loading: false, data: null, error: null },
    deleteFinalInvoice: { loading: false, data: null, error: null },
    getFinalInvoiceById: { loading: false, data: null, error: null },
};

export const finalInvoiceSlice = createSlice({
    name: "finalInvoice",
    initialState,
    reducers: {
        fetchFinalInvoiceListRequest: (finalInvoice, action) => {
            finalInvoice.finalInvoiceList.loading = true;
            finalInvoice.finalInvoiceList.error = null;
        },
        fetchFinalInvoiceListSuccess: (finalInvoice, action) => {
            finalInvoice.finalInvoiceList.data = action.payload;
            finalInvoice.finalInvoiceList.loading = false;
            finalInvoice.finalInvoiceList.error = null;
        },
        fetchFinalInvoiceListFailure: (finalInvoice, action) => {
            finalInvoice.finalInvoiceList.data = null;
            finalInvoice.finalInvoiceList.loading = false;
            finalInvoice.finalInvoiceList.error = action.payload;
        },

        //create FinalInvoice
        createFinalInvoiceRequest: (finalInvoice, action) => {
            finalInvoice.createFinalInvoice.loading = true;
            finalInvoice.createFinalInvoice.error = null;
        },
        createFinalInvoiceSuccess: (finalInvoice, action) => {
            finalInvoice.createFinalInvoice.data = action.payload;
            finalInvoice.createFinalInvoice.loading = false;
            finalInvoice.createFinalInvoice.error = null;
            finalInvoice.finalInvoiceList =
                addNewFinalInvoiceSuccess(action.payload, {
                    ...finalInvoice.finalInvoiceList,
                });
        },
        createFinalInvoiceFailure: (finalInvoice, action) => {
            finalInvoice.createFinalInvoice.data = null;
            finalInvoice.createFinalInvoice.loading = false;
            finalInvoice.createFinalInvoice.error = action.payload;
        },

        //update finalInvoice details
        updateFinalInvoiceRequest: (finalInvoice, action) => {
            finalInvoice.updateFinalInvoice.loading = true;
            finalInvoice.updateFinalInvoice.error = null;
        },
        updateFinalInvoiceSuccess: (finalInvoice, action) => {
            finalInvoice.updateFinalInvoice.data = action.payload;
            finalInvoice.updateFinalInvoice.loading = false;
            finalInvoice.updateFinalInvoice.error = null;
            finalInvoice.finalInvoiceList =
                updateNewFinalInvoiceSuccess(
                    action.payload,
                    finalInvoice.finalInvoiceList
                );
        },
        updateFinalInvoiceFailure: (finalInvoice, action) => {
            finalInvoice.updateFinalInvoice.data = null;
            finalInvoice.updateFinalInvoice.loading = false;
            finalInvoice.updateFinalInvoice.error = action.payload;
        },

        // delete finalInvoice
        deleteFinalInvoiceRequest: (finalInvoice, action) => {
            finalInvoice.deleteFinalInvoice.loading = true;
            finalInvoice.deleteFinalInvoice.error = null;
        },
        deleteFinalInvoiceSuccess: (finalInvoice, action) => {
            finalInvoice.deleteFinalInvoice.data = action.payload;
            finalInvoice.deleteFinalInvoice.loading = false;
            finalInvoice.deleteFinalInvoice.error = null;
            finalInvoice.finalInvoiceList =
                deleteNewFinalInvoiceSuccess(action.payload, {
                    ...finalInvoice.finalInvoiceList,
                });
        },
        deleteFinalInvoiceFailure: (finalInvoice, action) => {
            finalInvoice.deleteFinalInvoice.data = null;
            finalInvoice.deleteFinalInvoice.loading = false;
            finalInvoice.deleteFinalInvoice.error = action.payload;
        },

        // getById finalInvoice details
        getFinalInvoiceByIdRequest: (finalInvoice, action) => {
            finalInvoice.getFinalInvoiceById.loading = true;
            finalInvoice.getFinalInvoiceById.error = null;
        },
        getFinalInvoiceByIdSuccess: (finalInvoice, action) => {
            finalInvoice.getFinalInvoiceById.data = action.payload;
            finalInvoice.getFinalInvoiceById.loading = false;
            finalInvoice.getFinalInvoiceById.error = null;
        },
        getFinalInvoiceByIdFailure: (finalInvoice, action) => {
            finalInvoice.getFinalInvoiceById.data = null;
            finalInvoice.getFinalInvoiceById.loading = false;
            finalInvoice.getFinalInvoiceById.error = action.payload;
        },

        // reset finalInvoice details
        resetFinalInvoice: (finalInvoice, action) => {
            finalInvoice.createFinalInvoice.data = null;
            finalInvoice.createFinalInvoice.loading = false;
            finalInvoice.createFinalInvoice.error = null;

            finalInvoice.updateFinalInvoice.data = null;
            finalInvoice.updateFinalInvoice.loading = false;
            finalInvoice.updateFinalInvoice.error = null;

            finalInvoice.deleteFinalInvoice.data = null;
            finalInvoice.deleteFinalInvoice.loading = false;
            finalInvoice.deleteFinalInvoice.error = null;

            finalInvoice.getFinalInvoiceById.data = null;
            finalInvoice.getFinalInvoiceById.loading = false;
            finalInvoice.getFinalInvoiceById.error = null;
        },

        resetFinalInvoiceList: (finalInvoice, action) => {
            finalInvoice.finalInvoiceList.data = null;
            finalInvoice.finalInvoiceList.loading = false;
            finalInvoice.finalInvoiceList.error = null;
        },
    },
});

function addNewFinalInvoiceSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewFinalInvoiceSuccess(dataToUpdate, state) {
    if (state.data?.data.length) {
        const updatedData = state.data.data.map((value) => {
            if (value._id !== dataToUpdate._id) return value;
            return { ...value, ...dataToUpdate };
        });
        return { ...state, data: { ...state.data, data: updatedData } };
    } else {
        return state;
    }
}

function deleteNewFinalInvoiceSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchFinalInvoiceListRequest,
    fetchFinalInvoiceListSuccess,
    fetchFinalInvoiceListFailure,

    createFinalInvoiceRequest,
    createFinalInvoiceSuccess,
    createFinalInvoiceFailure,

    updateFinalInvoiceRequest,
    updateFinalInvoiceSuccess,
    updateFinalInvoiceFailure,

    deleteFinalInvoiceRequest,
    deleteFinalInvoiceSuccess,
    deleteFinalInvoiceFailure,

    getFinalInvoiceByIdRequest,
    getFinalInvoiceByIdSuccess,
    getFinalInvoiceByIdFailure,

    resetFinalInvoice,
    resetFinalInvoiceList,
} = finalInvoiceSlice.actions;

export const getFinalInvoiceListState = (store) =>
    store?.finalInvoice?.finalInvoiceList;
export const getFinalInvoiceCreateState = (store) =>
    store?.finalInvoice?.createFinalInvoice;
export const getFinalInvoiceUpdateState = (store) =>
    store?.finalInvoice?.updateFinalInvoice;
export const getFinalInvoiceDeleteState = (store) =>
    store?.finalInvoice?.deleteFinalInvoice;
export const getFinalInvoiceGetByIdState = (store) =>
    store?.finalInvoice?.getFinalInvoiceById;
export default finalInvoiceSlice.reducer;
