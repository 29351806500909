import { put, takeLatest } from "redux-saga/effects";

import {
    requestCheckListViewList,
} from "services/checkListView";

import {
    fetchCheckListViewListRequest,
    fetchCheckListViewListSuccess,
    fetchCheckListViewListFailure,
} from "redux/reducers/checkListView";

export function* watchCheckListViewSaga() {
    yield takeLatest(fetchCheckListViewListRequest, fetchCheckListViewList);
}

function* fetchCheckListViewList({ payload }) {
    try {
        const response = yield requestCheckListViewList(payload);
        yield put(fetchCheckListViewListSuccess(response));
    } catch (error) {
        yield put(
            fetchCheckListViewListFailure("Failed to get list of checkListView")
        );
    }
}
