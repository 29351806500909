import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestWorkLogList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/svc-ticket-workLog`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/svc-ticket-workLog?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER }
        });
    }
}

export function requestCreateWorkLog(data) {
    return fetch.post(`${BASE_URL}/api/app/svc-ticket-workLog`, data)
}

export function requestUpdateWorkLog(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/svc-ticket-workLog/${data._id}`, body);
}

export function requestDeleteWorkLog(data) {
    return fetch.delete(`${BASE_URL}/api/app/svc-ticket-workLog/${data}`);
}

export function requestWorkLogDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/worklog/svc-ticket-workLog/${data._id}`);
}