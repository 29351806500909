export const Dashboard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className="anticon"
  >
    <path
      id="Icon_material-dashboard"
      data-name="Icon material-dashboard"
      d="M4.5,13.389h7.111V4.5H4.5Zm0,7.111h7.111V15.167H4.5Zm8.889,0H20.5V11.611H13.389Zm0-16V9.833H20.5V4.5Z"
      transform="translate(-4.5 -4.5)"
      fill="#6e778d"
    />
  </svg>
);

export const Users = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="21.182"
    viewBox="0 0 16 10.182"
    className="anticon"
  >
    <path
      id="Icon_material-group"
      data-name="Icon material-group"
      d="M12.409,11.864a2.182,2.182,0,1,0-2.182-2.182A2.173,2.173,0,0,0,12.409,11.864Zm-5.818,0A2.182,2.182,0,1,0,4.409,9.682,2.173,2.173,0,0,0,6.591,11.864Zm0,1.455c-1.695,0-5.091.851-5.091,2.545v1.818H11.682V15.864C11.682,14.169,8.285,13.318,6.591,13.318Zm5.818,0c-.211,0-.451.015-.705.036a3.069,3.069,0,0,1,1.433,2.509v1.818H17.5V15.864C17.5,14.169,14.1,13.318,12.409,13.318Z"
      transform="translate(-1.5 -7.5)"
      fill="#6e778d"
    />
  </svg>
);

export const ClientData = () => (
  <svg
    id="client-data"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17.604"
    viewBox="0 0 16 17.604"
    className="anticon"
  >
    <path
      id="Path_21"
      data-name="Path 21"
      d="M35.8,74.857c0-1.725.406-2.334,2.07-2.862a7.053,7.053,0,0,1,4.953.081,9.486,9.486,0,0,1,1.908,1.38v1.421C41.747,74.857,38.764,74.857,35.8,74.857Z"
      transform="translate(-28.731 -57.273)"
      fill="#6e778d"
    />
    <path
      id="Path_22"
      data-name="Path 22"
      d="M12.969,0V3.938H.1V0Z"
      transform="translate(-0.08)"
      fill="#6e778d"
    />
    <path
      id="Path_23"
      data-name="Path 23"
      d="M13.069,28v1.664c-2.781-.3-3.776.325-4.912,3.106H.2V28Z"
      transform="translate(-0.159 -22.411)"
      fill="#6e778d"
    />
    <path
      id="Path_24"
      data-name="Path 24"
      d="M0,63.555V59.5H2.781c1.38,0,2.761.061,4.141-.02.934-.041,1.685.081,1.908,1.259a4.7,4.7,0,0,0-3.045,2.619c-.041.1-.264.183-.406.2C3.613,63.575,1.827,63.555,0,63.555Z"
      transform="translate(0 -47.615)"
      fill="#6e778d"
    />
    <path
      id="Path_25"
      data-name="Path 25"
      d="M49.8,47.739a2.02,2.02,0,1,1,2.03-1.969A2.027,2.027,0,0,1,49.8,47.739Z"
      transform="translate(-38.25 -34.97)"
      fill="#6e778d"
    />
  </svg>
);
export const Document = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="19.333"
    viewBox="0 0 16 21.333"
    className="anticon"
  >
    <path
      id="Icon_awesome-file-alt"
      data-name="Icon awesome-file-alt"
      d="M9.333,5.667V0H1A1,1,0,0,0,0,1V20.333a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V6.667H10.333A1,1,0,0,1,9.333,5.667ZM12,15.5a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5v-.333a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5Zm0-2.667a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5V12.5a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5Zm0-3v.333a.5.5,0,0,1-.5.5h-7a.5.5,0,0,1-.5-.5V9.833a.5.5,0,0,1,.5-.5h7A.5.5,0,0,1,12,9.833Zm4-4.754v.254H10.667V0h.254a1,1,0,0,1,.708.292l4.079,4.083A1,1,0,0,1,16,5.079Z"
      fill="#6e778d"
    />
  </svg>
);
export const SalesPipeline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.267"
    height="12.2"
    viewBox="0 0 16.267 12.2"
    className="anticon"
  >
    <path
      id="Icon_awesome-chart-line"
      data-name="Icon awesome-chart-line"
      d="M15.758,14.667H2.033V5.008A.508.508,0,0,0,1.525,4.5H.508A.508.508,0,0,0,0,5.008V15.683A1.017,1.017,0,0,0,1.017,16.7H15.758a.508.508,0,0,0,.508-.508V15.175A.508.508,0,0,0,15.758,14.667Zm-1.017-9.15H10.991a.762.762,0,0,0-.539,1.3l1.029,1.029L9.15,10.179,6.819,7.848a1.016,1.016,0,0,0-1.438,0L3.2,10.03a.508.508,0,0,0,0,.719l.719.719a.508.508,0,0,0,.719,0L6.1,10l2.331,2.331a1.016,1.016,0,0,0,1.438,0l3.05-3.05,1.029,1.029a.762.762,0,0,0,1.3-.539V6.025A.508.508,0,0,0,14.742,5.517Z"
      transform="translate(0 -4.5)"
      fill="#6e778d"
    />
  </svg>
);
export const SignedAgreements = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="15.222"
    viewBox="0 0 16 14.222"
    className="anticon"
  >
    <path
      id="Icon_awesome-file-signature"
      data-name="Icon awesome-file-signature"
      d="M6.06,11.782a.308.308,0,0,0-.282-.181.3.3,0,0,0-.278.172l-.213.426a.444.444,0,0,1-.819-.058L4,10.739l-.295.886a1.2,1.2,0,0,1-1.139.82H2.222a.444.444,0,0,1,0-.889h.344a.311.311,0,0,0,.3-.213l.505-1.518a.667.667,0,0,1,1.265,0l.386,1.157a1.247,1.247,0,0,1,1.833.393.315.315,0,0,0,.26.174V9.268l3.556-3.53V4.444H6.889a.669.669,0,0,1-.667-.667V0H.667A.665.665,0,0,0,0,.667V13.555a.665.665,0,0,0,.667.667H10a.665.665,0,0,0,.667-.667V12.444l-3.556,0A1.193,1.193,0,0,1,6.06,11.782Zm4.606-8.4a.665.665,0,0,0-.194-.469L7.753.194A.666.666,0,0,0,7.281,0H7.111V3.556h3.556ZM8,9.638v1.918H9.916l4.491-4.522L12.522,5.148,8,9.638Zm7.793-4.99-.885-.885a.707.707,0,0,0-1,0l-.757.757,1.886,1.886.757-.757A.707.707,0,0,0,15.793,4.648Z"
      fill="#6f778b"
    />
  </svg>
);

export const ClientStatement = () => (
  <svg
    id="Group_2896"
    className="anticon"
    data-name="Group 2896"
    xmlns="http://www.w3.org/2000/svg"
    width="13.181"
    height="16.519"
    viewBox="0 0 13.181 16.519"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1294"
          data-name="Rectangle 1294"
          width="15.181"
          height="16.519"
          fill="#6e778d"
        />
      </clipPath>
    </defs>
    <g id="Group_2897" data-name="Group 2897" clip-path="url(#clip-path)">
      <path
        id="Path_1173"
        data-name="Path 1173"
        d="M4.707,7.406A3.933,3.933,0,0,1,2.631,4.8a3.807,3.807,0,0,1,.192-2.41,3.893,3.893,0,0,1,7.353.513A3.935,3.935,0,0,1,8.1,7.419c.2.068.383.125.563.194.288.11.413.328.326.548a.451.451,0,0,1-.643.255A5.159,5.159,0,0,0,5.9,8.084,5.4,5.4,0,0,0,3.173,9.121,5.365,5.365,0,0,0,.957,12.657a11.421,11.421,0,0,0-.086,1.756.5.5,0,0,0,.555.493c.319.008.638,0,.958,0q1.894,0,3.787,0c.379,0,.6.316.442.62a.474.474,0,0,1-.476.238c-.987,0-1.973,0-2.96,0-.566,0-1.132,0-1.7,0A1.405,1.405,0,0,1,0,14.292,7.569,7.569,0,0,1,.4,11.4,6.516,6.516,0,0,1,2.825,8.3,7.226,7.226,0,0,1,4.576,7.46c.033-.012.065-.027.131-.054m1.7-.478A3.035,3.035,0,1,0,3.375,3.87,3.047,3.047,0,0,0,6.406,6.928"
        transform="translate(0 0)"
        fill="#6e778d"
      />
      <path
        id="Path_1174"
        data-name="Path 1174"
        d="M163.589,204.231c0-.377-.151-.518-.536-.519h-.681a.386.386,0,0,1-.021-.071c0-.21,0-.421,0-.631,0-.377-.174-.549-.557-.546-.275,0-.55.009-.825.009-1.065,0-2.131,0-3.2,0-.333,0-.513.152-.513.426q0,2.753,0,5.507c0,.375.151.524.53.528.23,0,.46,0,.715,0,0,.259,0,.49,0,.72,0,.362.154.512.52.512q2.024,0,4.049,0c.362,0,.518-.154.518-.517q0-2.71,0-5.42m-5.088.058q0,1.751,0,3.5v.27h-.369v-4.72h3.337v.36c-.075,0-.152.01-.229.01h-2.154c-.459,0-.584.125-.584.577m4.215,5.008h-3.344v-4.719h3.344Z"
        transform="translate(-150.409 -193.649)"
        fill="#6e778d"
      />
      <path
        id="Path_1175"
        data-name="Path 1175"
        d="M205.872,255.739h3.344V251.02h-3.344Zm.765-3.942q.912-.013,1.824-.011a.427.427,0,1,1,.013.853c-.311.01-.622,0-.934,0v.009c-.3,0-.593,0-.89,0a.42.42,0,0,1-.465-.431.413.413,0,0,1,.452-.422m1.773,3.165c-.3,0-.593,0-.89,0v.009c-.289,0-.579,0-.868,0a.425.425,0,1,1,.005-.846q.879-.011,1.757-.013a.431.431,0,0,1,.491.43c0,.258-.188.419-.5.422m.025-1.164q-.9.011-1.8.016a.411.411,0,0,1-.43-.3.389.389,0,0,1,.165-.475.6.6,0,0,1,.285-.077c.3-.01.608,0,.912,0,0,0,0-.006,0-.009.289,0,.579,0,.868,0a.427.427,0,1,1,0,.848"
        transform="translate(-196.909 -240.092)"
        fill="#6e778d"
      />
    </g>
  </svg>
);
export const ManageBusiness = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="anticon"
    width="17"
    height="14.667"
    viewBox="0 0 12 10.667"
  >
    <path
      id="Icon_material-store"
      data-name="Icon material-store"
      d="M15.833,6H5.167V7.333H15.833Zm.667,6.667V11.333L15.833,8H5.167L4.5,11.333v1.333h.667v4h6.667v-4H14.5v4h1.333v-4Zm-6,2.667h-4V12.667h4Z"
      transform="translate(-4.5 -6)"
      fill="#6e778d"
    />
  </svg>
);
export const GenerationOfInvoice = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="anticon"
    width="14"
    height="17"
    viewBox="0 0 12 15"
  >
    <path
      id="Icon_material-note-add"
      data-name="Icon material-note-add"
      d="M13.5,3h-6A1.5,1.5,0,0,0,6.008,4.5L6,16.5A1.5,1.5,0,0,0,7.492,18H16.5A1.5,1.5,0,0,0,18,16.5v-9ZM15,13.5H12.75v2.25h-1.5V13.5H9V12h2.25V9.75h1.5V12H15ZM12.75,8.25V4.125L16.875,8.25Z"
      transform="translate(-6 -3)"
      fill="#6e778d"
    />
  </svg>
);
export const InvoiceList = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="anticon"
    width="15.461"
    height="17.428"
    viewBox="0 0 12.461 14.428"
  >
    <g
      id="Group_2561"
      data-name="Group 2561"
      transform="translate(-24 -458.559)"
    >
      <path
        id="Icon_material-content-copy"
        data-name="Icon material-content-copy"
        d="M12.182,1.5H4.312A1.316,1.316,0,0,0,3,2.812v9.182H4.312V2.812h7.87Zm1.968,2.623H6.935A1.316,1.316,0,0,0,5.623,5.435v9.182a1.316,1.316,0,0,0,1.312,1.312h7.214a1.316,1.316,0,0,0,1.312-1.312V5.435A1.316,1.316,0,0,0,14.149,4.123Zm0,10.493H6.935V5.435h7.214Z"
        transform="translate(21 457.059)"
        fill="#6e778d"
      />
      <path
        id="Icon_material-attach-money"
        data-name="Icon material-attach-money"
        d="M11.248,7.049c-.732-.19-.968-.387-.968-.694,0-.352.326-.6.871-.6.574,0,.787.274.807.678h.713a1.286,1.286,0,0,0-1.036-1.229V4.5h-.968v.7A1.259,1.259,0,0,0,9.538,6.362c0,.745.616,1.116,1.516,1.333.807.194.968.478.968.778,0,.223-.158.578-.871.578-.665,0-.926-.3-.962-.678H9.48a1.352,1.352,0,0,0,1.187,1.236v.7h.968V9.614c.629-.119,1.129-.484,1.129-1.145C12.765,7.552,11.981,7.239,11.248,7.049Z"
        transform="translate(20.75 459.584)"
        fill="#6e778d"
      />
    </g>
  </svg>
);
