import { put, takeLatest } from "redux-saga/effects";
import {
  archiveSmartProposalFailure,
  archiveSmartProposalRequest,
  archiveSmartProposalSuccess,
  bookmarkSmartProposalFailure,
  bookmarkSmartProposalRequest,
  bookmarkSmartProposalSuccess,
  createProposalFailure,
  createProposalRequest,
  createProposalSuccess,
  fetchProposalActivityListFailure,
  fetchProposalActivityListRequest,
  fetchProposalActivityListSuccess,
  fetchProposalListFailure,
  fetchProposalListRequest,
  fetchProposalListSuccess,
  getProposalDetailsFailure,
  getProposalDetailsRequest,
  getProposalDetailsSuccess,
  updateProposalFailure,
  updateProposalRequest,
  updateProposalSuccess,
  sendProposalRequest,
  sendProposalSuccess,
  sendProposalFailure,
  getProposalKPIRequest,
  getProposalKPISuccess,
  getProposalKPIFailure,
  unArchiveSmartProposalRequest,
  unBookmarkSmartProposalRequest,
  unArchiveSmartProposalSuccess,
  unArchiveSmartProposalFailure,
  unBookmarkSmartProposalSuccess,
  unBookmarkSmartProposalFailure,
} from "redux/reducers/sales-pipeline/proposal";
import {
  requestCreateSmartProposal,
  requestForArchive,
  requestForBookMark,
  requestProposalActivity,
  requestSmartProposalDataById,
  requestSmartProposalList,
  requestUpdateSmartProposal,
  requestCreateSendDraftProposal,
  requestProposalKPIData,
} from "services/sales-pipeline/proposal";

export function* watchProposalSaga() {
  yield takeLatest(fetchProposalListRequest, fetchSmartProposal);
  yield takeLatest(createProposalRequest, createSmartProposal);
  yield takeLatest(updateProposalRequest, updateSmartProposal);
  yield takeLatest(getProposalDetailsRequest, getProposalGetByIdData);
  yield takeLatest(archiveSmartProposalRequest, archiveSmartProposal);
  yield takeLatest(bookmarkSmartProposalRequest, bookmarkSmartProposal);
  yield takeLatest(fetchProposalActivityListRequest, fetchProposalActivity);
  yield takeLatest(sendProposalRequest, sendProposal);
  yield takeLatest(getProposalKPIRequest, proposalKpi);
  yield takeLatest(unArchiveSmartProposalRequest, unArchiveSmartProposal);
  yield takeLatest(unBookmarkSmartProposalRequest, unBookmarkSmartProposal);
}

function* fetchSmartProposal({ payload }) {
  try {
    const response = yield requestSmartProposalList(payload);
    yield put(fetchProposalListSuccess(response));
  } catch (error) {
    yield put(fetchProposalListFailure("Failed to get list of smart proposal"));
  }
}

function* createSmartProposal({ payload }) {
  try {
    const request = yield requestCreateSmartProposal(payload);
    yield put(createProposalSuccess(request.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create proposal";
    yield put(createProposalFailure(message));
  }
}

function* updateSmartProposal({ payload }) {
  try {
    const response = yield requestUpdateSmartProposal(payload);
    yield put(updateProposalSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update proposal";
    yield put(updateProposalFailure(message));
  }
}

function* getProposalGetByIdData({ payload }) {
  try {
    const response = yield requestSmartProposalDataById(payload);
    yield put(getProposalDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to get proposal details";
    yield put(getProposalDetailsFailure(message));
  }
}

function* archiveSmartProposal({ payload }) {
  try {
    const response = yield requestForArchive(payload);
    yield put(archiveSmartProposalSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      error?.response?.data ||
      "Failed to archive list of proposals";
    yield put(archiveSmartProposalFailure(message));
  }
}

function* bookmarkSmartProposal({ payload }) {
  try {
    const response = yield requestForBookMark(payload);
    yield put(bookmarkSmartProposalSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      error?.response?.data ||
      "Failed to bookmark of proposal";
    yield put(bookmarkSmartProposalFailure(message));
  }
}

function* fetchProposalActivity({ payload }) {
  try {
    const response = yield requestProposalActivity(payload);
    yield put(fetchProposalActivityListSuccess(response.data));
  } catch (error) {
    yield put(
      fetchProposalActivityListFailure(
        "Failed to get proposal activity of proposal"
      )
    );
  }
}

function* sendProposal({ payload }) {
  try {
    const response = yield requestCreateSendDraftProposal(payload);
    yield put(sendProposalSuccess(response.data));
  } catch (error) {
    yield put(sendProposalFailure("Failed to send proposal "));
  }
}

function* proposalKpi({ payload }) {
  try {
    const response = yield requestProposalKPIData(payload);
    yield put(getProposalKPISuccess(response));
  } catch (error) {
    yield put(getProposalKPIFailure("Failed to get proposal KPI data"));
  }
}

function* unArchiveSmartProposal({ payload }) {
  try {
    const response = yield requestForArchive(payload);
    yield put(unArchiveSmartProposalSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      error?.response?.data ||
      "Failed to bookmark of proposal";
    yield put(unArchiveSmartProposalFailure(message));
  }
}

function* unBookmarkSmartProposal({ payload }) {
  try {
    const response = yield requestForBookMark(payload);
    yield put(unBookmarkSmartProposalSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      error?.response?.data ||
      "Failed to bookmark of proposal";
    yield put(unBookmarkSmartProposalFailure(message));
  }
}
