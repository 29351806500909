import { put, takeLatest } from "redux-saga/effects";
import {
    requestBillsList,
    requestCreateBills,
    requestUpdateBills,
    requestDeleteBills,
    requestBillsDataById,
    requestCreateBillsInvoice,
} from "services/bills";

import {
    fetchBillsListRequest,
    fetchBillsListSuccess,
    fetchBillsListFailure,
    createBillsRequest,
    createBillsSuccess,
    createBillsFailure,
    updateBillsRequest,
    updateBillsSuccess,
    updateBillsFailure,
    deleteBillsRequest,
    deleteBillsSuccess,
    deleteBillsFailure,
    getBillsByIdRequest,
    getBillsByIdSuccess,
    getBillsByIdFailure,
    createBillsInvoiceRequest,
    createBillsInvoiceSuccess,
    createBillsInvoiceFailure,
} from "redux/reducers/bills";

export function* watchBillsSaga() {
    yield takeLatest(fetchBillsListRequest, fetchBillsList);
    yield takeLatest(createBillsRequest, createBills);
    yield takeLatest(updateBillsRequest, updateBills);
    yield takeLatest(deleteBillsRequest, deleteBills);
    yield takeLatest(getBillsByIdRequest, getBillsById);
    yield takeLatest(createBillsInvoiceRequest, createBillsInvoice);
}

function* fetchBillsList({ payload }) {
    try {
        const response = yield requestBillsList(payload);
        yield put(fetchBillsListSuccess(response));
    } catch (error) {
        yield put(
            fetchBillsListFailure("Failed to get list of bills")
        );
    }
}

function* createBills({ payload }) {
    try {
        // service add
        const response = yield requestCreateBills(payload);
        yield put(createBillsSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create bills";
        yield put(createBillsFailure(message));
    }
}

function* updateBills({ payload }) {
    try {
        const response = yield requestUpdateBills(payload);
        yield put(updateBillsSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update bills";
        yield put(updateBillsFailure(message));
    }
}

function* deleteBills({ payload }) {
    try {
        const response = yield requestDeleteBills(payload);
        yield put(deleteBillsSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete bills";
        yield put(deleteBillsFailure(message));
    }
}

function* getBillsById({ payload }) {
    try {
        const response = yield requestBillsDataById(payload);
        yield put(getBillsByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get bills details";
        yield put(getBillsByIdFailure(message));
    }
}
function* createBillsInvoice({ payload }) {
    try {
        // service add
        const response = yield requestCreateBillsInvoice(payload);
        yield put(createBillsInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create bills invoice";
        yield put(createBillsInvoiceFailure(message));
    }
}