import { put, takeLatest } from "redux-saga/effects";

import {
    requestGenerateEInvoiceList,
    requestCreateGenerateEInvoice,
    requestUpdateGenerateEInvoice,
    requestDeleteGenerateEInvoice,
    requestGenerateEInvoiceDataById,
} from "services/generateEInvoice";

import {
    fetchGenerateEInvoiceListRequest,
    fetchGenerateEInvoiceListSuccess,
    fetchGenerateEInvoiceListFailure,
    createGenerateEInvoiceRequest,
    createGenerateEInvoiceSuccess,
    createGenerateEInvoiceFailure,
    updateGenerateEInvoiceRequest,
    updateGenerateEInvoiceSuccess,
    updateGenerateEInvoiceFailure,
    deleteGenerateEInvoiceRequest,
    deleteGenerateEInvoiceSuccess,
    deleteGenerateEInvoiceFailure,
    getGenerateEInvoiceByIdRequest,
    getGenerateEInvoiceByIdSuccess,
    getGenerateEInvoiceByIdFailure,
} from "redux/reducers/generateEInvoice";

export function* watchGenerateEInvoiceSaga() {
    yield takeLatest(fetchGenerateEInvoiceListRequest, fetchGenerateEInvoiceList);
    yield takeLatest(createGenerateEInvoiceRequest, createGenerateEInvoice);
    yield takeLatest(updateGenerateEInvoiceRequest, updateGenerateEInvoice);
    yield takeLatest(deleteGenerateEInvoiceRequest, deleteGenerateEInvoice);
    yield takeLatest(getGenerateEInvoiceByIdRequest, getGenerateEInvoiceById);
}

function* fetchGenerateEInvoiceList({ payload }) {
    try {
        const response = yield requestGenerateEInvoiceList(payload);
        yield put(fetchGenerateEInvoiceListSuccess(response));
    } catch (error) {
        yield put(
            fetchGenerateEInvoiceListFailure("Failed to get list of E Invoices")
        );
    }
}

function* createGenerateEInvoice({ payload }) {
    try {
        // service add
        const response = yield requestCreateGenerateEInvoice(payload);
        yield put(createGenerateEInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create E Invoice";
        yield put(createGenerateEInvoiceFailure(message));
    }
}

function* updateGenerateEInvoice({ payload }) {
    try {
        const response = yield requestUpdateGenerateEInvoice(payload);
        yield put(updateGenerateEInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update E Invoice";
        yield put(updateGenerateEInvoiceFailure(message));
    }
}

function* deleteGenerateEInvoice({ payload }) {
    try {
        const response = yield requestDeleteGenerateEInvoice(payload);
        yield put(deleteGenerateEInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete E Invoice";
        yield put(deleteGenerateEInvoiceFailure(message));
    }
}

function* getGenerateEInvoiceById({ payload }) {
    try {
        const response = yield requestGenerateEInvoiceDataById(payload);
        yield put(getGenerateEInvoiceByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get E Invoice details";
        yield put(getGenerateEInvoiceByIdFailure(message));
    }
}