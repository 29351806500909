import { put, takeLatest } from "redux-saga/effects";

import {
  requestDeleteSparePartRequest,
  requestSparePartCreate,
  requestSparePartList,
  requestUpdateSparePartStatus,
  requestRequestedSparePartDataById,
} from "../../../services/requestspare";

import {
  requestedSparePartListRequest,
  requestedSparePartListSuccess,
  requestedSparePartListFaliure,
  requestedSparePartDeleteRequest,
  requestedSparePartDeleteSuccess,
  requestedSparePartDeleteFaliure,
  requestedSparePartStatusUpdateRequest,
  requestedSparePartStatusUpdateSuccess,
  requestedSparePartStatusUpdateFaliure,
  getRequestedSparePartByIdRequest,
  getRequestedSparePartByIdSuccess,
  getRequestedSparePartByIdFailure,
} from "redux/reducers/requestSparePart";

export function* watchRequestedSparePartSaga() {
  yield takeLatest(requestedSparePartListRequest, fetchRequestedSpareList);
  yield takeLatest(requestedSparePartDeleteRequest, fetchRequestedSpareDelete);
  yield takeLatest(
    requestedSparePartStatusUpdateRequest,
    fetchRequestedSpareStatusUpdate
  );
  yield takeLatest(getRequestedSparePartByIdRequest, getRequestedSparePartById);
}

function* fetchRequestedSpareList({ payload }) {
  try {
    const response = yield requestSparePartList(payload);
    yield put(requestedSparePartListSuccess(response));
  } catch (error) {
    yield put(
      requestedSparePartListFaliure("Failed to get list of Requested SparePart")
    );
  }
}

function* fetchRequestedSpareDelete({ payload }) {
  try {
    const response = yield requestDeleteSparePartRequest(payload);
    yield put(requestedSparePartDeleteSuccess(response));
  } catch (error) {
    yield put(
      requestedSparePartDeleteFaliure("Failed to Delete Requested SparePart")
    );
  }
}

function* fetchRequestedSpareStatusUpdate({ payload }) {
  try {
    const response = yield requestUpdateSparePartStatus(payload);
    yield put(requestedSparePartStatusUpdateSuccess(response.data));
  } catch (error) {
    yield put(
      requestedSparePartStatusUpdateFaliure(
        "Failed to Update Requested SparePart Status"
      )
    );
  }
}

function* getRequestedSparePartById({ payload }) {
  try {
    const response = yield requestRequestedSparePartDataById(payload);
    yield put(getRequestedSparePartByIdSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to get requestedSparePart details";
    yield put(getRequestedSparePartByIdFailure(message));
  }
}
