import { notification } from "antd";
import * as CryptoJS from "crypto-js";

(function () {
  /**
   * Secret key should be stored in a safe place. This is only for demo purposes.
   */
  const _key = "12345";
  function encrypt(txt) {
    return CryptoJS.AES.encrypt(txt, _key).toString();
  }

  function decrypt(txtToDecrypt) {
    try {
      return CryptoJS.AES.decrypt(txtToDecrypt, _key).toString(
        CryptoJS.enc.Utf8
      );
    } catch (e) {
      localStorage.clear();
      notification.warn({ message: "Login again" });
    }
  }

  function manipulateLocalStorage() {
    // Using "function" syntax to ensure "this" context is correct
    Storage.prototype.setEncryptedItem = function (key, value) {
      this.setItem(key, encrypt(JSON.stringify(value)));
    };

    Storage.prototype.getDecryptedItem = function (key) {
      const data = this.getItem(key) || "";
      return decrypt(data) || null;
    };
  }

  manipulateLocalStorage();
})();
