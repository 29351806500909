import { put, takeLatest } from "redux-saga/effects";

import {
    requestWorkLogList,
    requestCreateWorkLog,
    requestUpdateWorkLog,
    requestDeleteWorkLog,
    requestWorkLogDataById,
} from "services/workLog";

import {
    fetchWorkLogListRequest,
    fetchWorkLogListSuccess,
    fetchWorkLogListFailure,
    createWorkLogRequest,
    createWorkLogSuccess,
    createWorkLogFailure,
    updateWorkLogRequest,
    updateWorkLogSuccess,
    updateWorkLogFailure,
    deleteWorkLogRequest,
    deleteWorkLogSuccess,
    deleteWorkLogFailure,
    getWorkLogByIdRequest,
    getWorkLogByIdSuccess,
    getWorkLogByIdFailure,
} from "redux/reducers/workLog";

export function* watchWorkLogSaga() {
    yield takeLatest(fetchWorkLogListRequest, fetchWorkLogList);
    yield takeLatest(createWorkLogRequest, createWorkLog);
    yield takeLatest(updateWorkLogRequest, updateWorkLog);
    yield takeLatest(deleteWorkLogRequest, deleteWorkLog);
    yield takeLatest(getWorkLogByIdRequest, getWorkLogById);
}

function* fetchWorkLogList({ payload }) {
    try {
        const response = yield requestWorkLogList(payload);
        yield put(fetchWorkLogListSuccess(response));
    } catch (error) {
        yield put(
            fetchWorkLogListFailure("Failed to get list of work log")
        );
    }
}

function* createWorkLog({ payload }) {
    try {
        // service add
        const response = yield requestCreateWorkLog(payload);
        yield put(createWorkLogSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create work log";
        yield put(createWorkLogFailure(message));
    }
}

function* updateWorkLog({ payload }) {
    try {
        const response = yield requestUpdateWorkLog(payload);
        yield put(updateWorkLogSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update work log";
        yield put(updateWorkLogFailure(message));
    }
}

function* deleteWorkLog({ payload }) {
    try {
        const response = yield requestDeleteWorkLog(payload);
        yield put(deleteWorkLogSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete work log";
        yield put(deleteWorkLogFailure(message));
    }
}

function* getWorkLogById({ payload }) {
    try {
        const response = yield requestWorkLogDataById(payload);
        yield put(getWorkLogByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get work lFog details";
        yield put(getWorkLogByIdFailure(message));
    }
}