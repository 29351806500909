import { put, takeLatest } from "redux-saga/effects";

import {
  requestSupplierList,
  requestCreateSupplier,
  requestUpdateSupplier,
  requestDeleteSupplier,
  requestSupplierDataById,
  requestSupplierDropdownList,
} from "services/supplier";

import {
  fetchSupplierListRequest,
  fetchSupplierListSuccess,
  fetchSupplierListFailure,
  createSupplierRequest,
  createSupplierSuccess,
  createSupplierFailure,
  updateSupplierRequest,
  updateSupplierSuccess,
  updateSupplierFailure,
  deleteSupplierRequest,
  deleteSupplierSuccess,
  deleteSupplierFailure,
  getSupplierByIdRequest,
  getSupplierByIdSuccess,
  getSupplierByIdFailure,
  fetchSupplierDropdownListRequest,
  fetchSupplierDropdownListSuccess,
  fetchSupplierDropdownListFailure,
} from "redux/reducers/supplier";

export function* watchSupplierSaga() {
  yield takeLatest(fetchSupplierListRequest, fetchSupplierList);
  yield takeLatest(createSupplierRequest, createSupplier);
  yield takeLatest(updateSupplierRequest, updateSupplier);
  yield takeLatest(deleteSupplierRequest, deleteSupplier);
  yield takeLatest(getSupplierByIdRequest, getSupplierById);
  yield takeLatest(
    fetchSupplierDropdownListRequest,
    fetchSupplierDropdownListData
  );
}

function* fetchSupplierList({ payload }) {
  try {
    const response = yield requestSupplierList(payload);
    yield put(fetchSupplierListSuccess(response));
  } catch (error) {
    yield put(fetchSupplierListFailure("Failed to get list of supplier"));
  }
}

function* createSupplier({ payload }) {
  try {
    // service add
    const response = yield requestCreateSupplier(payload);
    yield put(createSupplierSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create supplier";
    yield put(createSupplierFailure(message));
  }
}

function* updateSupplier({ payload }) {
  try {
    const response = yield requestUpdateSupplier(payload);
    yield put(updateSupplierSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update supplier";
    yield put(updateSupplierFailure(message));
  }
}

function* deleteSupplier({ payload }) {
  try {
    const response = yield requestDeleteSupplier(payload);
    yield put(deleteSupplierSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to delete supplier";
    yield put(deleteSupplierFailure(message));
  }
}

function* getSupplierById({ payload }) {
  try {
    const response = yield requestSupplierDataById(payload);
    yield put(getSupplierByIdSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to get supplier details";
    yield put(getSupplierByIdFailure(message));
  }
}

function* fetchSupplierDropdownListData({ payload }) {
  try {
    const response = yield requestSupplierDropdownList(payload);
    yield put(fetchSupplierDropdownListSuccess(response));
  } catch (error) {
    yield put(
      fetchSupplierDropdownListFailure("Failed to get list of supplier")
    );
  }
}
