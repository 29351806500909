import { put, takeLatest } from "redux-saga/effects";
import {
  addLeadNoteFailure,
  addLeadNoteRequest,
  addLeadNoteSuccess,
  createLeadFailure,
  createLeadRequest,
  createLeadSuccess,
  deleteLeadFailure,
  deleteLeadNoteFailure,
  deleteLeadNoteRequest,
  deleteLeadNoteSuccess,
  deleteLeadRequest,
  deleteLeadSuccess,
  fetchLeadListFailure,
  fetchLeadListRequest,
  fetchLeadListSuccess,
  getLeadDetailsFailure,
  getLeadDetailsRequest,
  getLeadDetailsSuccess,
  getLeadKPIFailure,
  getLeadKPIRequest,
  getLeadKPISuccess,
  updateLeadFailure,
  updateLeadRequest,
  updateLeadStageFailure,
  updateLeadStageRequest,
  updateLeadStageSuccess,
  updateLeadSuccess,
} from "redux/reducers/sales-pipeline/lead";
import {
  requestAddNoteToLead,
  requestCreateLead,
  requestDeleteLead,
  requestDeleteLeadNote,
  requestLeadDataById,
  requestLeadKPIData,
  requestLeadList,
  requestLeadStageChange,
  requestUpdateLead,
} from "services/sales-pipeline/lead";

export function* watchLeadSaga() {
  yield takeLatest(fetchLeadListRequest, FetchLeadList);
  yield takeLatest(createLeadRequest, CreateLead);
  yield takeLatest(updateLeadRequest, UpdateLead);
  yield takeLatest(deleteLeadRequest, deleteLead);
  yield takeLatest(getLeadDetailsRequest, getLeadGetByIdData);
  yield takeLatest(addLeadNoteRequest, addLeadNote);
  yield takeLatest(deleteLeadNoteRequest, deleteLeadNote);
  yield takeLatest(updateLeadStageRequest, UpdateLeadStage);
  yield takeLatest(getLeadKPIRequest, leadKpi);
}

function* FetchLeadList({ payload }) {
  try {
    const response = yield requestLeadList(payload);
    yield put(fetchLeadListSuccess(response));
  } catch (error) {
    yield put(fetchLeadListFailure("Failed to get list of Leads"));
  }
}

function* CreateLead({ payload }) {
  try {
    const response = yield requestCreateLead(payload);
    yield put(createLeadSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create lead";
    yield put(createLeadFailure(message));
  }
}

function* UpdateLead({ payload }) {
  try {
    const response = yield requestUpdateLead(payload);
    yield put(updateLeadSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update lead";
    yield put(updateLeadFailure(message));
  }
}

function* deleteLead({ payload }) {
  try {
    const response = yield requestDeleteLead(payload);
    yield put(deleteLeadSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to delete lead data";
    yield put(deleteLeadFailure(message));
  }
}

function* getLeadGetByIdData({ payload }) {
  try {
    const response = yield requestLeadDataById(payload);
    yield put(getLeadDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to get lead details";
    yield put(getLeadDetailsFailure(message));
  }
}

function* addLeadNote({ payload }) {
  try {
    const response = yield requestAddNoteToLead(payload);
    yield put(addLeadNoteSuccess(response.data));
  } catch (error) {
    yield put(addLeadNoteFailure("Failed to add note to lead"));
  }
}

function* deleteLeadNote({ payload }) {
  try {
    yield requestDeleteLeadNote(payload);
    yield put(deleteLeadNoteSuccess(payload));
  } catch (error) {
    yield put(deleteLeadNoteFailure("Failed to delete note to lead"));
  }
}

function* UpdateLeadStage({ payload }) {
  try {
    const response = yield requestLeadStageChange(payload);
    yield put(updateLeadStageSuccess(response.data));
  } catch (error) {
    yield put(updateLeadStageFailure("Failed to update lead stage"));
  }
}

function* leadKpi({ payload }) {
  try {
    const response = yield requestLeadKPIData(payload);
    yield put(getLeadKPISuccess(response));
  } catch (error) {
    yield put(getLeadKPIFailure("Failed to get lead KPI data"));
  }
}
