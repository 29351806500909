import {
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Layout } from "antd";
import MyAccount from "components/reusable-components/My-Account";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { onMobileNavToggle, toggleCollapsedNav } from "redux/actions/Theme";
import { CLIENT_ID, CLIENT_NAME } from "redux/constants/Auth";
import {
  getByIdUserDetailData,
  getByIdUserDetailsRequest,
} from "redux/reducers/manageUser";
import utils from "utils";
import { checkPermission } from "utils/permissions";
import Logo from "./Logo";
import NavPanel from "./NavPanel";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
  } = props;

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  const [userData, setUserData] = useState();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getByIdUserDetailsRequest({ id: localStorage.getItem("user") }));
  }, []);

  const getUserData = useSelector(getByIdUserDetailData);
  useEffect(() => {
    setUserData(getUserData?.data?.data);
    if (getUserData?.data?.data?.userType === "Client-User") {
      localStorage.setItem(CLIENT_ID, getUserData?.data?.data?.client?._id);
      localStorage.setItem(
        CLIENT_NAME,
        getUserData?.data?.data?.client?.companyName
      );
    }
  }, [getUserData]);
  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            {checkPermission("SITE_CONFIGURATION", "SHOW_NAVIGATION") ? (
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                {isNavTop && !isMobile ? null : (
                  <li
                    className="ant-menu-item ant-menu-item-only-child"
                    onClick={() => {
                      onToggle();
                    }}
                  >
                    {navCollapsed || isMobile ? (
                      <MenuUnfoldOutlined className="nav-icon" />
                    ) : (
                      <MenuFoldOutlined className="nav-icon" />
                    )}
                  </li>
                )}
              </ul>
            ) : (
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                {isNavTop && !isMobile ? null : (
                  <li
                    className="ant-menu-item ant-menu-item-only-child"
                    onClick={() => {
                      history.push("/app/home-button");
                    }}
                  >
                    <HomeOutlined className="nav-icon" />
                  </li>
                )}
              </ul>
            )}
            <Logo
              logoType={navMode}
              headerImagesResponseData={props.headerImagesResponse}
            />
          </div>
        </div>
        <div className="nav-right">
          {/* <NavNotification /> */}
          <MyAccount data={userData} />
          <NavPanel direction={direction} />
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
