import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestDraftPOList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/purchase-order/`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/purchase-order/?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestGetByIdDraftPO(data) {
  return fetch.get(`${BASE_URL}/api/app/purchase-order/getById/${data.id}`);
}

export function createDraftPO(params) {
  return fetch.post(
    `${BASE_URL}/api/app/purchase-order/createPurchaseOrder`,
    params
  );
}

export function updateDraftPO(params) {
  return fetch.put(`${BASE_URL}/api/app/purchase-order/update-po`, params);
}

export function requestDeletePO(data) {
  return fetch.delete(`${BASE_URL}/api/app/purchase-order/${data}`);
}
