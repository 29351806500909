import { put, takeLatest } from "redux-saga/effects";

import {
  fetchInvoiceItemMasterListFailure,
  fetchInvoiceItemMasterListRequest,
  fetchInvoiceItemMasterListSuccess,
} from "redux/reducers/invoiceItemMaster";
import { requestInvoiceMasterList } from "services/invoiceItemMaster";

export function* watchInvoiceItemMasterSaga() {
  yield takeLatest(fetchInvoiceItemMasterListRequest, fetcheInvoiceItemList);
}

function* fetcheInvoiceItemList({ payload }) {
  try {
    const response = yield requestInvoiceMasterList(payload);
    yield put(fetchInvoiceItemMasterListSuccess(response));
  } catch (error) {
    yield put(
      fetchInvoiceItemMasterListFailure(
        "Failed to get list of Invoices Item Master"
      )
    );
  }
}
