import { createSlice } from "@reduxjs/toolkit";

/**
 *
 * @type IndustryStoreState
 */
const initialState = {
  getSideMenuList: {
    loading: false,
    data: JSON.parse(localStorage.getItem("m1e2n3u4")),
    error: null,
  },
};

export const SideMenuSlice = createSlice({
  name: "getSideMenu",
  initialState,
  reducers: {
    fetchSideMenuRequest: (state, action) => {
      state.getSideMenuList.loading = true;
      state.getSideMenuList.error = null;
    },
    fetchSideMenuSuccess: (state, action) => {
      localStorage.setItem("m1e2n3u4", JSON.stringify(action.payload.data));
      state.getSideMenuList.data = action.payload.data;
      state.getSideMenuList.loading = false;
      state.getSideMenuList.error = null;
    },
    fetchSideMenuFailure: (state, action) => {
      state.getSideMenuList.data = null;
      state.getSideMenuList.loading = false;
      state.getSideMenuList.error = action.payload;
    },
  },
});

export const {
  fetchSideMenuRequest,
  fetchSideMenuSuccess,
  fetchSideMenuFailure,
} = SideMenuSlice.actions;

export const getSideMenuState = (store) => store.sidemenu?.getSideMenuList;

export default SideMenuSlice.reducer;
