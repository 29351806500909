import { put, takeLatest } from "redux-saga/effects";

import {
    requestManualPaymentList,
    requestCreateManualPayment,
    requestUpdateManualPayment,
    requestDeleteManualPayment,
    requestManualPaymentDataById,
    requestUpdatePaymentStage,
} from "services/payments/manual-payment";

import {
    fetchManualPaymentListRequest,
    fetchManualPaymentListSuccess,
    fetchManualPaymentListFailure,
    createManualPaymentRequest,
    createManualPaymentSuccess,
    createManualPaymentFailure,
    updateManualPaymentRequest,
    updateManualPaymentSuccess,
    updateManualPaymentFailure,
    deleteManualPaymentRequest,
    deleteManualPaymentSuccess,
    deleteManualPaymentFailure,
    getManualPaymentByIdRequest,
    getManualPaymentByIdSuccess,
    getManualPaymentByIdFailure,
    updatePaymentStageRequest,
    updatePaymentStageSuccess,
    updatePaymentStageFailure,
} from "redux/reducers/payments/manual-payment";

export function* watchManualPaymentSaga() {
    yield takeLatest(fetchManualPaymentListRequest, fetchManualPaymentList);
    yield takeLatest(createManualPaymentRequest, createManualPayment);
    yield takeLatest(updateManualPaymentRequest, updateManualPayment);
    yield takeLatest(deleteManualPaymentRequest, deleteManualPayment);
    yield takeLatest(getManualPaymentByIdRequest, getManualPaymentById);
    yield takeLatest(updatePaymentStageRequest, updatePaymentStage);
}

function* fetchManualPaymentList({ payload }) {
    try {
        const response = yield requestManualPaymentList(payload);
        yield put(fetchManualPaymentListSuccess(response));
    } catch (error) {
        yield put(
            fetchManualPaymentListFailure("Failed to get list of payments")
        );
    }
}

function* createManualPayment({ payload }) {
    try {
        // service add
        const response = yield requestCreateManualPayment(payload);
        yield put(createManualPaymentSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create record payments";
        yield put(createManualPaymentFailure(message));
    }
}

function* updateManualPayment({ payload }) {
    try {
        const response = yield requestUpdateManualPayment(payload);
        yield put(updateManualPaymentSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update record payments";
        yield put(updateManualPaymentFailure(message));
    }
}

function* deleteManualPayment({ payload }) {
    try {
        const response = yield requestDeleteManualPayment(payload);
        yield put(deleteManualPaymentSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete record payments";
        yield put(deleteManualPaymentFailure(message));
    }
}

function* getManualPaymentById({ payload }) {
    try {
        const response = yield requestManualPaymentDataById(payload);
        yield put(getManualPaymentByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get record payment details";
        yield put(getManualPaymentByIdFailure(message));
    }
}

function* updatePaymentStage({ payload }) {
    try {
        const response = yield requestUpdatePaymentStage(payload);
        yield put(updatePaymentStageSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.message || "Failed to update record payments status";
        yield put(updatePaymentStageFailure(message));
    }
}