import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientMasterList: { loading: false, data: null, error: null },
    createClientMaster: { loading: false, data: null, error: null },
    updateClientMaster: { loading: false, data: null, error: null },
    deleteClientMaster: { loading: false, data: null, error: null },
    getClientMasterById: { loading: false, data: null, error: null },
};

export const clientMasterSlice = createSlice({
    name: "clientMaster",
    initialState,
    reducers: {
        fetchClientMasterListRequest: (clientMaster, action) => {
            clientMaster.clientMasterList.loading = true;
            clientMaster.clientMasterList.error = null;
        },
        fetchClientMasterListSuccess: (clientMaster, action) => {
            clientMaster.clientMasterList.data = action.payload;
            clientMaster.clientMasterList.loading = false;
            clientMaster.clientMasterList.error = null;
        },
        fetchClientMasterListFailure: (clientMaster, action) => {
            clientMaster.clientMasterList.data = null;
            clientMaster.clientMasterList.loading = false;
            clientMaster.clientMasterList.error = action.payload;
        },

        //create ClientMaster
        createClientMasterRequest: (clientMaster, action) => {
            clientMaster.createClientMaster.loading = true;
            clientMaster.createClientMaster.error = null;
        },
        createClientMasterSuccess: (clientMaster, action) => {
            clientMaster.createClientMaster.data = action.payload;
            clientMaster.createClientMaster.loading = false;
            clientMaster.createClientMaster.error = null;
            clientMaster.clientMasterList =
                addNewClientMasterSuccess(action.payload, {
                    ...clientMaster.clientMasterList,
                });
        },
        createClientMasterFailure: (clientMaster, action) => {
            clientMaster.createClientMaster.data = null;
            clientMaster.createClientMaster.loading = false;
            clientMaster.createClientMaster.error = action.payload;
        },

        //update clientMaster details
        updateClientMasterRequest: (clientMaster, action) => {
            clientMaster.updateClientMaster.loading = true;
            clientMaster.updateClientMaster.error = null;
        },
        updateClientMasterSuccess: (clientMaster, action) => {
            clientMaster.updateClientMaster.data = action.payload;
            clientMaster.updateClientMaster.loading = false;
            clientMaster.updateClientMaster.error = null;
            clientMaster.clientMasterList =
                updateNewClientMasterSuccess(
                    action.payload,
                    clientMaster.clientMasterList
                );
        },
        updateClientMasterFailure: (clientMaster, action) => {
            clientMaster.updateClientMaster.data = null;
            clientMaster.updateClientMaster.loading = false;
            clientMaster.updateClientMaster.error = action.payload;
        },

        // delete clientMaster
        deleteClientMasterRequest: (clientMaster, action) => {
            clientMaster.deleteClientMaster.loading = true;
            clientMaster.deleteClientMaster.error = null;
        },
        deleteClientMasterSuccess: (clientMaster, action) => {
            clientMaster.deleteClientMaster.data = action.payload;
            clientMaster.deleteClientMaster.loading = false;
            clientMaster.deleteClientMaster.error = null;
            clientMaster.clientMasterList =
                deleteNewClientMasterSuccess(action.payload, {
                    ...clientMaster.clientMasterList,
                });
        },
        deleteClientMasterFailure: (clientMaster, action) => {
            clientMaster.deleteClientMaster.data = null;
            clientMaster.deleteClientMaster.loading = false;
            clientMaster.deleteClientMaster.error = action.payload;
        },

        // getById clientMaster details
        getClientMasterByIdRequest: (clientMaster, action) => {
            clientMaster.getClientMasterById.loading = true;
            clientMaster.getClientMasterById.error = null;
        },
        getClientMasterByIdSuccess: (clientMaster, action) => {
            clientMaster.getClientMasterById.data = action.payload;
            clientMaster.getClientMasterById.loading = false;
            clientMaster.getClientMasterById.error = null;
        },
        getClientMasterByIdFailure: (clientMaster, action) => {
            clientMaster.getClientMasterById.data = null;
            clientMaster.getClientMasterById.loading = false;
            clientMaster.getClientMasterById.error = action.payload;
        },

        // reset clientMaster details
        resetClientMaster: (clientMaster, action) => {
            clientMaster.createClientMaster.data = null;
            clientMaster.createClientMaster.loading = false;
            clientMaster.createClientMaster.error = null;

            clientMaster.updateClientMaster.data = null;
            clientMaster.updateClientMaster.loading = false;
            clientMaster.updateClientMaster.error = null;

            clientMaster.deleteClientMaster.data = null;
            clientMaster.deleteClientMaster.loading = false;
            clientMaster.deleteClientMaster.error = null;

            clientMaster.getClientMasterById.data = null;
            clientMaster.getClientMasterById.loading = false;
            clientMaster.getClientMasterById.error = null;
        },

        resetClientMasterList: (clientMaster, action) => {
            clientMaster.clientMasterList.data = null;
            clientMaster.clientMasterList.loading = false;
            clientMaster.clientMasterList.error = null;
        },
    },
});

function addNewClientMasterSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewClientMasterSuccess(dataToUpdate, state) {
    if (state.data?.data.length) {
        const updatedData = state.data.data.map((value) => {
            if (value._id !== dataToUpdate._id) return value;
            return { ...value, ...dataToUpdate };
        });
        return { ...state, data: { ...state.data, data: updatedData } };
    } else {
        return state;
    }
}

function deleteNewClientMasterSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchClientMasterListRequest,
    fetchClientMasterListSuccess,
    fetchClientMasterListFailure,

    createClientMasterRequest,
    createClientMasterSuccess,
    createClientMasterFailure,

    updateClientMasterRequest,
    updateClientMasterSuccess,
    updateClientMasterFailure,

    deleteClientMasterRequest,
    deleteClientMasterSuccess,
    deleteClientMasterFailure,

    getClientMasterByIdRequest,
    getClientMasterByIdSuccess,
    getClientMasterByIdFailure,

    resetClientMaster,
    resetClientMasterList,
} = clientMasterSlice.actions;

export const getClientMasterListState = (store) =>
    store?.clientMaster?.clientMasterList;
export const getClientMasterCreateState = (store) =>
    store?.clientMaster?.createClientMaster;
export const getClientMasterUpdateState = (store) =>
    store?.clientMaster?.updateClientMaster;
export const getClientMasterDeleteState = (store) =>
    store?.clientMaster?.deleteClientMaster;
export const getClientMasterGetByIdState = (store) =>
    store?.clientMaster?.getClientMasterById;
export default clientMasterSlice.reducer;
