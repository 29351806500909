import { notification } from "antd";
import ESignature from "components/shared-components/Esignature";
import React from "react";
import { useDispatch } from "react-redux";
import { getByIdUserDetailsRequest } from "redux/reducers/manageUser";
import { requestUpdateSignature } from "services/manageUser";

function AddSignature(props) {
  const dispatch = useDispatch();
  const handleChange = async (x) => {
    {
      try {
        await requestUpdateSignature({ esgin: JSON.parse(x) });
        notification.success({ message: "Signature Added Successfully" });
        dispatch(
          getByIdUserDetailsRequest({ id: localStorage.getItem("user") })
        );
        props.closeModal();
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <div>
      <p className="text-center">Add Signature</p>
      <ESignature onSignatureCapture={handleChange} />
    </div>
  );
}

export default AddSignature;
