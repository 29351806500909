import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestResourceViewist(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/svc-ticket`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/svc-ticket?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}


export function requestUpdateResourceView(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/svc-ticket/${data._id}`, body);
}