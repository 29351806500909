import { put, takeLatest } from "redux-saga/effects";

import {
    requestFeedBackList,
    requestCreateFeedBack,
    requestUpdateFeedBack,
    requestDeleteFeedBack,
    requestFeedBackDataById,
} from "services/feedBack";

import {
    fetchFeedBackListRequest,
    fetchFeedBackListSuccess,
    fetchFeedBackListFailure,
    createFeedBackRequest,
    createFeedBackSuccess,
    createFeedBackFailure,
    updateFeedBackRequest,
    updateFeedBackSuccess,
    updateFeedBackFailure,
    deleteFeedBackRequest,
    deleteFeedBackSuccess,
    deleteFeedBackFailure,
    getFeedBackByIdRequest,
    getFeedBackByIdSuccess,
    getFeedBackByIdFailure,
} from "redux/reducers/feedBack";

export function* watchFeedBackSaga() {
    yield takeLatest(fetchFeedBackListRequest, fetchFeedBackList);
    yield takeLatest(createFeedBackRequest, createFeedBack);
    yield takeLatest(updateFeedBackRequest, updateFeedBack);
    yield takeLatest(deleteFeedBackRequest, deleteFeedBack);
    yield takeLatest(getFeedBackByIdRequest, getFeedBackById);
}

function* fetchFeedBackList({ payload }) {
    try {
        const response = yield requestFeedBackList(payload);
        yield put(fetchFeedBackListSuccess(response));
    } catch (error) {
        yield put(
            fetchFeedBackListFailure("Failed to get list of work log")
        );
    }
}

function* createFeedBack({ payload }) {
    try {
        // service add
        const response = yield requestCreateFeedBack(payload);
        yield put(createFeedBackSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create work log";
        yield put(createFeedBackFailure(message));
    }
}

function* updateFeedBack({ payload }) {
    try {
        const response = yield requestUpdateFeedBack(payload);
        yield put(updateFeedBackSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update work log";
        yield put(updateFeedBackFailure(message));
    }
}

function* deleteFeedBack({ payload }) {
    try {
        const response = yield requestDeleteFeedBack(payload);
        yield put(deleteFeedBackSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete work log";
        yield put(deleteFeedBackFailure(message));
    }
}

function* getFeedBackById({ payload }) {
    try {
        const response = yield requestFeedBackDataById(payload);
        yield put(getFeedBackByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get work lFog details";
        yield put(getFeedBackByIdFailure(message));
    }
}