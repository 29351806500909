import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    generateBillsInvoice: { loading: false, data: null, error: null },
}

export const billsInvoiceSlice = createSlice({
    name: "billsInvoice",
    initialState,
    reducers: {

        generateBillsInvoiceRequest: (billsInvoice, action) => {
            billsInvoice.generateBillsInvoice.loading = true;
            billsInvoice.generateBillsInvoice.error = null;
        },
        generateBillsInvoiceSuccess: (billsInvoice, action) => {
            billsInvoice.generateBillsInvoice.data = action.payload;
            billsInvoice.generateBillsInvoice.loading = false;
            billsInvoice.generateBillsInvoice.error = null;
        },
        generateBillsInvoiceFailure: (billsInvoice, action) => {
            billsInvoice.generateBillsInvoice.data = null;
            billsInvoice.generateBillsInvoice.loading = false;
            billsInvoice.generateBillsInvoice.error = action.payload;
        },
        resetBillsInvoice: (billsInvoice, action) => {
            billsInvoice.generateBillsInvoice.data = null;
            billsInvoice.generateBillsInvoice.loading = false;
            billsInvoice.generateBillsInvoice.error = null;

        }

    }
})

export const {

    generateBillsInvoiceRequest,
    generateBillsInvoiceSuccess,
    generateBillsInvoiceFailure,

    resetBillsInvoice
} = billsInvoiceSlice.actions;

export const getBillsInvoiceGenerateState = (store) =>
    store?.billsInvoice?.generateBillsInvoice;
export default billsInvoiceSlice.reducer;