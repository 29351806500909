import { put, takeLatest } from "redux-saga/effects";
import {
  createDraftPOFailure,
  createDraftPORequest,
  createDraftPOSuccess,
  deleteDraftPOFailure,
  deleteDraftPORequest,
  deleteDraftPOSuccess,
  editDraftPOFailure,
  editDraftPORequest,
  editDraftPOSuccess,
  fetchDraftPOGetByIdFailure,
  fetchDraftPOGetByIdRequest,
  fetchDraftPOGetByIdSuccess,
  fetchDraftPOListFailure,
  fetchDraftPOListRequest,
  fetchDraftPOListSuccess,
} from "redux/reducers/manage-po";
import {
  createDraftPO,
  requestDeletePO,
  requestDraftPOList,
  requestGetByIdDraftPO,
  updateDraftPO,
} from "services/manage-po";

export function* watchDraftPOSaga() {
  yield takeLatest(fetchDraftPOListRequest, fetchDraftPOList);
  yield takeLatest(fetchDraftPOGetByIdRequest, fetchDraftPOById);
  yield takeLatest(createDraftPORequest, createDraftPOService);
  yield takeLatest(editDraftPORequest, editDraftPOService);
  yield takeLatest(deleteDraftPORequest, deleteDraftPO);
}

function* fetchDraftPOList({ payload }) {
  try {
    const response = yield requestDraftPOList(payload);
    yield put(fetchDraftPOListSuccess(response));
  } catch (error) {
    yield put(fetchDraftPOListFailure("Failed to get list of Draft PO"));
  }
}

function* fetchDraftPOById({ payload }) {
  try {
    const response = yield requestGetByIdDraftPO(payload);
    yield put(fetchDraftPOGetByIdSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to get Draft PO data";
    yield put(fetchDraftPOGetByIdFailure(message));
  }
}

function* createDraftPOService({ payload }) {
  try {
    const response = yield createDraftPO(payload);
    yield put(createDraftPOSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create Draft PO";
    yield put(createDraftPOFailure(message));
  }
}

function* editDraftPOService({ payload }) {
  try {
    const response = yield updateDraftPO(payload);
    yield put(editDraftPOSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update Draft PO";
    yield put(editDraftPOFailure(message));
  }
}

function* deleteDraftPO({ payload }) {
  try {
    const response = yield requestDeletePO(payload);
    yield put(deleteDraftPOSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to Delete PO";
    yield put(deleteDraftPOFailure(message));
  }
}
