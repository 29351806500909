import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteDocument: { loading: false, data: null, error: null },
  updateDocument: { loading: false, data: null, error: null }
};

export const DocumentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    //update document details
    updateDocumentRequest: (document, action) => {
      document.updateDocument.loading = true;
      document.updateDocument.error = null;
    },
    updateDocumentSuccess: (document, action) => {
      document.updateDocument.data = action.payload;
      document.updateDocument.loading = false;
      document.updateDocument.error = null;
    },
    updateDocumentFailure: (document, action) => {
      document.updateDocument.data = null;
      document.updateDocument.loading = false;
      document.updateDocument.error = action.payload;
    },

    // delete document
    deleteDocumentRequest: (document, action) => {
      document.deleteDocument.loading = true;
      document.deleteDocument.error = null;
    },
    deleteDocumentSuccess: (document, action) => {
      document.deleteDocument.loading = false;
      document.deleteDocument.error = null;
      document.deleteDocument.data = action.payload;
    },
    deleteDocumentFailure: (document, action) => {
      document.deleteDocument.data = null;
      document.deleteDocument.loading = false;
      document.deleteDocument.error = action.payload;
    },

    //Reset features state
    resetDocument: (document, action) => {
      document.updateDocument.data = null;
      document.updateDocument.loading = false;
      document.updateDocument.error = null;

      document.deleteDocument.data = null;
      document.deleteDocument.loading = false;
      document.deleteDocument.error = null;
    },
  },
});

export const {
  updateDocumentRequest,
  updateDocumentSuccess,
  updateDocumentFailure,

  deleteDocumentRequest,
  deleteDocumentSuccess,
  deleteDocumentFailure,

  resetDocument,
} = DocumentSlice.actions;

export const getDocumentUpdateState = (store) =>
  store?.document?.updateDocument;
export const getDeleteDocumentState = (store) =>
  store?.document?.deleteDocument;
export default DocumentSlice.reducer;
