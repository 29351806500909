import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    feedBackList: { loading: false, data: null, error: null },
    createFeedBack: { loading: false, data: null, error: null },
    updateFeedBack: { loading: false, data: null, error: null },
    deleteFeedBack: { loading: false, data: null, error: null },
    getFeedBackById: { loading: false, data: null, error: null }
}

export const feedBackSlice = createSlice({
    name: "feedBack",
    initialState,
    reducers: {
        fetchFeedBackListRequest: (feedBack, action) => {
            feedBack.feedBackList.loading = true;
            feedBack.feedBackList.error = null;
        },
        fetchFeedBackListSuccess: (feedBack, action) => {
            feedBack.feedBackList.data = action.payload;
            feedBack.feedBackList.loading = false;
            feedBack.feedBackList.error = null;
        },
        fetchFeedBackListFailure: (feedBack, action) => {
            feedBack.feedBackList.data = null;
            feedBack.feedBackList.loading = false;
            feedBack.feedBackList.error = action.payload;
        },

        //create FeedBack
        createFeedBackRequest: (feedBack, action) => {
            feedBack.createFeedBack.loading = true;
            feedBack.createFeedBack.error = null;
        },
        createFeedBackSuccess: (feedBack, action) => {
            feedBack.createFeedBack.data = action.payload;
            feedBack.createFeedBack.loading = false;
            feedBack.createFeedBack.error = null;
        },
        createFeedBackFailure: (feedBack, action) => {
            feedBack.createFeedBack.data = null;
            feedBack.createFeedBack.loading = false;
            feedBack.createFeedBack.error = action.payload;
        },

        //update feedBack details
        updateFeedBackRequest: (feedBack, action) => {
            feedBack.updateFeedBack.loading = true;
            feedBack.updateFeedBack.error = null;
        },
        updateFeedBackSuccess: (feedBack, action) => {
            feedBack.updateFeedBack.data = action.payload;
            feedBack.updateFeedBack.loading = false;
            feedBack.updateFeedBack.error = null;
        },
        updateFeedBackFailure: (feedBack, action) => {
            feedBack.updateFeedBack.data = null;
            feedBack.updateFeedBack.loading = false;
            feedBack.updateFeedBack.error = action.payload;
        },

        // delete feedBack
        deleteFeedBackRequest: (feedBack, action) => {
            feedBack.deleteFeedBack.loading = true;
            feedBack.deleteFeedBack.error = null;
        },
        deleteFeedBackSuccess: (feedBack, action) => {
            feedBack.deleteFeedBack.data = action.payload;
            feedBack.deleteFeedBack.loading = false;
            feedBack.deleteFeedBack.error = null;
        },
        deleteFeedBackFailure: (feedBack, action) => {
            feedBack.deleteFeedBack.data = null;
            feedBack.deleteFeedBack.loading = false;
            feedBack.deleteFeedBack.error = action.payload;
        },

        // getById feedBack details
        getFeedBackByIdRequest: (feedBack, action) => {
            feedBack.getFeedBackById.loading = true;
            feedBack.getFeedBackById.error = null;
        },
        getFeedBackByIdSuccess: (feedBack, action) => {
            feedBack.getFeedBackById.data = action.payload;
            feedBack.getFeedBackById.loading = false;
            feedBack.getFeedBackById.error = null;
        },
        getFeedBackByIdFailure: (feedBack, action) => {
            feedBack.getFeedBackById.data = null;
            feedBack.getFeedBackById.loading = false;
            feedBack.getFeedBackById.error = action.payload;
        },

        // reset feedBack details
        resetFeedBack: (feedBack, action) => {
            feedBack.createFeedBack.data = null;
            feedBack.createFeedBack.loading = false;
            feedBack.createFeedBack.error = null;

            feedBack.updateFeedBack.data = null;
            feedBack.updateFeedBack.loading = false;
            feedBack.updateFeedBack.error = null;

            feedBack.deleteFeedBack.data = null;
            feedBack.deleteFeedBack.loading = false;
            feedBack.deleteFeedBack.error = null;
        }
    }
})

export const {
    fetchFeedBackListRequest,
    fetchFeedBackListSuccess,
    fetchFeedBackListFailure,

    createFeedBackRequest,
    createFeedBackSuccess,
    createFeedBackFailure,

    updateFeedBackRequest,
    updateFeedBackSuccess,
    updateFeedBackFailure,

    deleteFeedBackRequest,
    deleteFeedBackSuccess,
    deleteFeedBackFailure,

    getFeedBackByIdRequest,
    getFeedBackByIdSuccess,
    getFeedBackByIdFailure,

    resetFeedBack,
} = feedBackSlice.actions;

export const getFeedBackListState = (store) =>
    store?.feedBack?.feedBackList;
export const getFeedBackCreateState = (store) =>
    store?.feedBack?.createFeedBack;
export const getFeedBackUpdateState = (store) =>
    store?.feedBack?.updateFeedBack;
export const getFeedBackDeleteState = (store) =>
    store?.feedBack?.deleteFeedBack;
export const getFeedBackGetByIdState = (store) =>
    store?.feedBack?.getFeedBackById;
export default feedBackSlice.reducer;