import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    organizationScreenList: { loading: false, data: null, error: null },
    createOrganizationScreen: { loading: false, data: null, error: null },
    updateOrganizationScreen: { loading: false, data: null, error: null },
    deleteOrganizationScreen: { loading: false, data: null, error: null },
    organizationScreenGetById: { loading: false, data: null, error: null },
}

export const organizationScreenSlice = createSlice({
    name: "organizationScreen",
    initialState,
    reducers: {
        fetchOrganizationScreenListRequest: (organizationScreen, action) => {
            organizationScreen.organizationScreenList.loading = true;
            organizationScreen.organizationScreenList.error = null;
        },
        fetchOrganizationScreenListSuccess: (organizationScreen, action) => {
            organizationScreen.organizationScreenList.data = action.payload;
            organizationScreen.organizationScreenList.loading = false;
            organizationScreen.organizationScreenList.error = null
        },
        fetchOrganizationScreenListFailure: (organizationScreen, action) => {
            organizationScreen.organizationScreenList.data = null;
            organizationScreen.organizationScreenList.loading = false;
            organizationScreen.organizationScreenList.error = action.payload;
        },
        createOrganizationScreenRequest: (organizationScreen, action) => {
            organizationScreen.createOrganizationScreen.loading = true;
            organizationScreen.createOrganizationScreen.error = null;
        },
        createOrganizationScreenSuccess: (organizationScreen, action) => {
            organizationScreen.createOrganizationScreen.data = action.payload;
            organizationScreen.createOrganizationScreen.loading = false;
            organizationScreen.createOrganizationScreen.error = null;
            organizationScreen.organizationScreenList = addNewOrganizationScreenSuccess(action.payload, {
                ...organizationScreen.organizationScreenList,
            });
        },
        createOrganizationScreenFailure: (organizationScreen, action) => {
            organizationScreen.createOrganizationScreen.data = null;
            organizationScreen.createOrganizationScreen.loading = false;
            organizationScreen.createOrganizationScreen.error = action.payload;
        },

        // update organizationScreen
        updateOrganizationScreenRequest: (organizationScreen, action) => {
            organizationScreen.updateOrganizationScreen.loading = true;
            organizationScreen.updateOrganizationScreen.error = null;
        },
        updateOrganizationScreenSuccess: (organizationScreen, action) => {
            organizationScreen.updateOrganizationScreen.data = action.payload;
            organizationScreen.updateOrganizationScreen.loading = false;
            organizationScreen.updateOrganizationScreen.error = null;
            organizationScreen.organizationScreenList = updateOrganizationScreenListSuccess(action.payload, {
                ...organizationScreen.organizationScreenList,
            });
        },
        updateOrganizationScreenFailure: (organizationScreen, action) => {
            organizationScreen.updateOrganizationScreen.data = null;
            organizationScreen.updateOrganizationScreen.loading = false;
            organizationScreen.updateOrganizationScreen.error = action.payload;
        },

        // delete organizationScreen
        deleteOrganizationScreenRequest: (organizationScreen, action) => {
            organizationScreen.deleteOrganizationScreen.loading = true;
            organizationScreen.deleteOrganizationScreen.error = null;
        },
        deleteOrganizationScreenSuccess: (organizationScreen, action) => {
            organizationScreen.deleteOrganizationScreen.data = action.payload;
            organizationScreen.deleteOrganizationScreen.loading = false;
            organizationScreen.deleteOrganizationScreen.error = null;
            organizationScreen.organizationScreenList =
                deleteNewOrganizationScreenSuccess(action.payload, {
                    ...organizationScreen.organizationScreenList,
                });
        },
        deleteOrganizationScreenFailure: (organizationScreen, action) => {
            organizationScreen.deleteOrganizationScreen.data = null;
            organizationScreen.deleteOrganizationScreen.loading = false;
            organizationScreen.deleteOrganizationScreen.error = action.payload;
        },

        // organizationScreen get by id
        organizationScreenGetByIdRequest: (organizationScreen, action) => {
            organizationScreen.organizationScreenGetById.loading = true;
            organizationScreen.organizationScreenGetById.error = null;
        },
        organizationScreenGetByIdSuccess: (organizationScreen, action) => {
            organizationScreen.organizationScreenGetById.data = action.payload;
            organizationScreen.organizationScreenGetById.loading = false;
            organizationScreen.organizationScreenGetById.error = null;
        },
        organizationScreenGetByIdFailure: (organizationScreen, action) => {
            organizationScreen.organizationScreenGetById.data = null;
            organizationScreen.organizationScreenGetById.loading = false;
            organizationScreen.organizationScreenGetById.error = action.payload;
        },

        // reset create, update
        resetOrganizationScreenData: (organizationScreen, action) => {
            organizationScreen.createOrganizationScreen.data = null;
            organizationScreen.createOrganizationScreen.loading = false;
            organizationScreen.createOrganizationScreen.error = null;

            organizationScreen.updateOrganizationScreen.data = null;
            organizationScreen.updateOrganizationScreen.loading = false;
            organizationScreen.updateOrganizationScreen.error = null;

            organizationScreen.deleteOrganizationScreen.data = null;
            organizationScreen.deleteOrganizationScreen.loading = false;
            organizationScreen.deleteOrganizationScreen.error = null;
        },
    }
});

function addNewOrganizationScreenSuccess(dataToUpdate, state) {
    state.data.data = [dataToUpdate, ...state.data.data];
    state.data.total++;
    return state;
}

function updateOrganizationScreenListSuccess(dataToUpdate, state) {
    state.data.data = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return state;
}

function deleteNewOrganizationScreenSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchOrganizationScreenListRequest,
    fetchOrganizationScreenListSuccess,
    fetchOrganizationScreenListFailure,

    createOrganizationScreenRequest,
    createOrganizationScreenSuccess,
    createOrganizationScreenFailure,

    updateOrganizationScreenRequest,
    updateOrganizationScreenSuccess,
    updateOrganizationScreenFailure,

    deleteOrganizationScreenRequest,
    deleteOrganizationScreenSuccess,
    deleteOrganizationScreenFailure,

    organizationScreenGetByIdRequest,
    organizationScreenGetByIdSuccess,
    organizationScreenGetByIdFailure,

    resetOrganizationScreenData,
} = organizationScreenSlice.actions

export const getOrganizationScreenListState = (store) => store.organizationScreen?.organizationScreenList;
export const getOrganizationScreenCreateState = (store) => store.organizationScreen?.createOrganizationScreen;
export const getOrganizationScreenUpdateState = (store) => store.organizationScreen?.updateOrganizationScreen;
export const getOrganizationScreenDeleteState = (store) =>
    store?.organizationScreen?.deleteOrganizationScreen;
export const getByIdStateOrganizationScreen = (store) => store.organizationScreen?.organizationScreenGetById;
export default organizationScreenSlice.reducer;