import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestBillsList(params) {
  if (params.alternateId) {
    return fetch.post(
      `${BASE_URL}/api/app/Bills/getDataFromTicketId`,
      params
    );
  } else {
    return fetch.get(`${BASE_URL}/api/app/Bills`, {
      params: { limit: Number.MAX_SAFE_INTEGER,...params },
    });
  }
}
// params: { limit: Number.MAX_SAFE_INTEGER },

export function requestCreateBills(data) {
  return fetch.post(`${BASE_URL}/api/app/Bills`, data);
}

export function requestCreateBillsInvoice(data) {
  return fetch.put(`${BASE_URL}/api/app/Bills/update/list`, data);
}

export function requestUpdateBills(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/Bills/${data._id}`, body);
}

export function requestDeleteBills(data) {
  return fetch.delete(`${BASE_URL}/api/app/Bills/${data}`);
}

export function requestBillsDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/Bills/getById/${data.id}`);
}
