import { put, takeLatest } from "redux-saga/effects";

import {
    requestOperativeTicketList,
    requestCreateOperativeTicket,
    requestUpdateOperativeTicket,
    requestDeleteOperativeTicket,
    requestOperativeTicketDataById,
} from "services/operativeTicket";

import {
    fetchOperativeTicketListRequest,
    fetchOperativeTicketListSuccess,
    fetchOperativeTicketListFailure,
    createOperativeTicketRequest,
    createOperativeTicketSuccess,
    createOperativeTicketFailure,
    updateOperativeTicketRequest,
    updateOperativeTicketSuccess,
    updateOperativeTicketFailure,
    deleteOperativeTicketRequest,
    deleteOperativeTicketSuccess,
    deleteOperativeTicketFailure,
    getOperativeTicketByIdRequest,
    getOperativeTicketByIdSuccess,
    getOperativeTicketByIdFailure,
} from "redux/reducers/operativeTicket";

export function* watchOperativeTicketSaga() {
    yield takeLatest(fetchOperativeTicketListRequest, fetchOperativeTicketList);
    yield takeLatest(createOperativeTicketRequest, createOperativeTicket);
    yield takeLatest(updateOperativeTicketRequest, updateOperativeTicket);
    yield takeLatest(deleteOperativeTicketRequest, deleteOperativeTicket);
    yield takeLatest(getOperativeTicketByIdRequest, getOperativeTicketById);
}

function* fetchOperativeTicketList({ payload }) {
    try {
        const response = yield requestOperativeTicketList(payload);
        yield put(fetchOperativeTicketListSuccess(response));
    } catch (error) {
        yield put(
            fetchOperativeTicketListFailure("Failed to get list of operativeTicket")
        );
    }
}

function* createOperativeTicket({ payload }) {
    try {
        // service add
        const response = yield requestCreateOperativeTicket(payload);
        yield put(createOperativeTicketSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create operativeTicket";
        yield put(createOperativeTicketFailure(message));
    }
}

function* updateOperativeTicket({ payload }) {
    try {
        const response = yield requestUpdateOperativeTicket(payload);
        yield put(updateOperativeTicketSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update operativeTicket";
        yield put(updateOperativeTicketFailure(message));
    }
}

function* deleteOperativeTicket({ payload }) {
    try {
        const response = yield requestDeleteOperativeTicket(payload);
        yield put(deleteOperativeTicketSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete operativeTicket";
        yield put(deleteOperativeTicketFailure(message));
    }
}

function* getOperativeTicketById({ payload }) {
    try {
        const response = yield requestOperativeTicketDataById(payload);
        yield put(getOperativeTicketByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get operativeTicket details";
        yield put(getOperativeTicketByIdFailure(message));
    }
}