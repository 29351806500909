import React from "react";
import { APP_NAME } from "configs/AppConfig";

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <a
          className="font-weight-semibold"
          href="https://jetatech.in"
          target="_blank"
        >{`${APP_NAME}`}</a>{" "}
        All Rights Reserved.
      </span>
    </footer>
  );
}
