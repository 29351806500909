import { put, takeLatest } from "redux-saga/effects";
import {
  createFeActivityFailure,
  createFeActivityRequest,
  createFeActivitySuccess,
  deleteFeActivityFailure,
  deleteFeActivityRequest,
  deleteFeActivitySuccess,
  fetchFeActivityListFailure,
  fetchFeActivityListRequest,
  fetchFeActivityListSuccess,
  getFeActivityByIdFailure,
  getFeActivityByIdRequest,
  getFeActivityByIdSuccess,
  updateFeActivityRequest,
  updateFeActivitySuccess,
  updateFeActivityFailure,
} from "redux/reducers/fe/feActivity";
import {
  requestCreateFeActivity,
  requestDeleteFeActivity,
  requestFeActivityDataById,
  requestFeActivityList,
  requestUpdateFeActivity,
} from "services/fe/feActivity";

export function* watchFeActivitySaga() {
  yield takeLatest(fetchFeActivityListRequest, fetchFeActivityList);
  yield takeLatest(createFeActivityRequest, createFeActivity);
  yield takeLatest(updateFeActivityRequest, updateFeActivity);
  yield takeLatest(deleteFeActivityRequest, deleteFeActivity);
  yield takeLatest(getFeActivityByIdRequest, getFeActivityById);
}

function* fetchFeActivityList({ payload }) {
  try {
    const response = yield requestFeActivityList(payload);
    yield put(fetchFeActivityListSuccess(response));
  } catch (error) {
    yield put(fetchFeActivityListFailure("Failed to get list of Fe Activity"));
  }
}

function* createFeActivity({ payload }) {
  try {
    // service add
    const response = yield requestCreateFeActivity(payload);
    yield put(createFeActivitySuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create Fe Activity";
    yield put(createFeActivityFailure(message));
  }
}

function* updateFeActivity({ payload }) {
  try {
    //service update
    const response = yield requestUpdateFeActivity(payload);
    yield put(updateFeActivitySuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update FE Activity";
    yield put(updateFeActivityFailure(message));
  }
}

function* deleteFeActivity({ payload }) {
  try {
    const response = yield requestDeleteFeActivity(payload);
    yield put(deleteFeActivitySuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to delete Fe Activity";
    yield put(deleteFeActivityFailure(message));
  }
}

function* getFeActivityById({ payload }) {
  try {
    const response = yield requestFeActivityDataById(payload);
    yield put(getFeActivityByIdSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get Fe Activity details";
    yield put(getFeActivityByIdFailure(message));
  }
}
