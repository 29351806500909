import React, { useEffect, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useRef } from "react";
import "./sign.css";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { getByIdUserDetailData } from "redux/reducers/manageUser";

function ESignature(props) {
  const sigCanvas = useRef();

  const create = () => {
    const file = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    props.onSignatureCapture(JSON.stringify(file));
  };

  const clear = () => {
    sigCanvas.current.clear();
  };
  const getUserData = useSelector(getByIdUserDetailData);
  useEffect(() => {
    if (getUserData?.data?.data?.eSign) {
      sigCanvas.current.fromDataURL(getUserData?.data?.data?.eSign);
    }
  }, [getUserData]);
  return (
    <div className="app">
      <div className="sigPadContainer">
        <SignatureCanvas
          canvasProps={{ className: "sigCanvas" }}
          ref={sigCanvas}
          penColor="#17bcff"
        />
      </div>
      <hr />
      <Button type="ghost" onClick={clear}>
        Clear Signature
      </Button>{" "}
      &nbsp;
      <Button type="primary" onClick={create}>
        Add Signature
      </Button>
    </div>
  );
}
export default ESignature;
