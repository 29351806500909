import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    invoiceItemMasterScreenList: { loading: false, data: null, error: null },
    createInvoiceItemMasterScreen: { loading: false, data: null, error: null },
    updateInvoiceItemMasterScreen: { loading: false, data: null, error: null },
    deleteInvoiceItemMasterScreen: { loading: false, data: null, error: null },
    getInvoiceItemMasterScreenById: { loading: false, data: null, error: null },
};

export const invoiceItemMasterScreenSlice = createSlice({
    name: "invoiceItemMasterScreen",
    initialState,
    reducers: {
        fetchInvoiceItemMasterScreenListRequest: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.invoiceItemMasterScreenList.loading = true;
            invoiceItemMasterScreen.invoiceItemMasterScreenList.error = null;
        },
        fetchInvoiceItemMasterScreenListSuccess: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.invoiceItemMasterScreenList.data = action.payload;
            invoiceItemMasterScreen.invoiceItemMasterScreenList.loading = false;
            invoiceItemMasterScreen.invoiceItemMasterScreenList.error = null;
        },
        fetchInvoiceItemMasterScreenListFailure: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.invoiceItemMasterScreenList.data = null;
            invoiceItemMasterScreen.invoiceItemMasterScreenList.loading = false;
            invoiceItemMasterScreen.invoiceItemMasterScreenList.error = action.payload;
        },

        //create InvoiceItemMasterScreen
        createInvoiceItemMasterScreenRequest: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.loading = true;
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.error = null;
        },
        createInvoiceItemMasterScreenSuccess: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.data = action.payload;
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.loading = false;
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.error = null;
            invoiceItemMasterScreen.invoiceItemMasterScreenList =
                addNewInvoiceItemMasterScreenSuccess(action.payload, {
                    ...invoiceItemMasterScreen.invoiceItemMasterScreenList,
                });
        },
        createInvoiceItemMasterScreenFailure: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.data = null;
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.loading = false;
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.error = action.payload;
        },

        //update invoiceItemMasterScreen details
        updateInvoiceItemMasterScreenRequest: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.loading = true;
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.error = null;
        },
        updateInvoiceItemMasterScreenSuccess: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.data = action.payload;
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.loading = false;
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.error = null;
            invoiceItemMasterScreen.invoiceItemMasterScreenList =
                updateNewInvoiceItemMasterScreenSuccess(
                    action.payload,
                    invoiceItemMasterScreen.invoiceItemMasterScreenList
                );
        },
        updateInvoiceItemMasterScreenFailure: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.data = null;
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.loading = false;
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.error = action.payload;
        },

        // delete invoiceItemMasterScreen
        deleteInvoiceItemMasterScreenRequest: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.loading = true;
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.error = null;
        },
        deleteInvoiceItemMasterScreenSuccess: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.data = action.payload;
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.loading = false;
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.error = null;
            invoiceItemMasterScreen.invoiceItemMasterScreenList =
                deleteNewInvoiceItemMasterScreenSuccess(action.payload, {
                    ...invoiceItemMasterScreen.invoiceItemMasterScreenList,
                });
        },
        deleteInvoiceItemMasterScreenFailure: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.data = null;
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.loading = false;
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.error = action.payload;
        },

        // getById invoiceItemMasterScreen details
        getInvoiceItemMasterScreenByIdRequest: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.loading = true;
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.error = null;
        },
        getInvoiceItemMasterScreenByIdSuccess: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.data = action.payload;
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.loading = false;
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.error = null;
        },
        getInvoiceItemMasterScreenByIdFailure: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.data = null;
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.loading = false;
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.error = action.payload;
        },

        // reset invoiceItemMasterScreen details
        resetInvoiceItemMasterScreen: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.data = null;
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.loading = false;
            invoiceItemMasterScreen.createInvoiceItemMasterScreen.error = null;

            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.data = null;
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.loading = false;
            invoiceItemMasterScreen.updateInvoiceItemMasterScreen.error = null;

            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.data = null;
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.loading = false;
            invoiceItemMasterScreen.deleteInvoiceItemMasterScreen.error = null;

            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.data = null;
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.loading = false;
            invoiceItemMasterScreen.getInvoiceItemMasterScreenById.error = null;
        },

        resetInvoiceItemMasterScreenList: (invoiceItemMasterScreen, action) => {
            invoiceItemMasterScreen.invoiceItemMasterScreenList.data = null;
            invoiceItemMasterScreen.invoiceItemMasterScreenList.loading = false;
            invoiceItemMasterScreen.invoiceItemMasterScreenList.error = null;
        },
    },
});

function addNewInvoiceItemMasterScreenSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewInvoiceItemMasterScreenSuccess(dataToUpdate, state) {
    if (state.data?.data.length) {
        const updatedData = state.data.data.map((value) => {
            if (value._id !== dataToUpdate._id) return value;
            return { ...value, ...dataToUpdate };
        });
        return { ...state, data: { ...state.data, data: updatedData } };
    } else {
        return state;
    }
}

function deleteNewInvoiceItemMasterScreenSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchInvoiceItemMasterScreenListRequest,
    fetchInvoiceItemMasterScreenListSuccess,
    fetchInvoiceItemMasterScreenListFailure,

    createInvoiceItemMasterScreenRequest,
    createInvoiceItemMasterScreenSuccess,
    createInvoiceItemMasterScreenFailure,

    updateInvoiceItemMasterScreenRequest,
    updateInvoiceItemMasterScreenSuccess,
    updateInvoiceItemMasterScreenFailure,

    deleteInvoiceItemMasterScreenRequest,
    deleteInvoiceItemMasterScreenSuccess,
    deleteInvoiceItemMasterScreenFailure,

    getInvoiceItemMasterScreenByIdRequest,
    getInvoiceItemMasterScreenByIdSuccess,
    getInvoiceItemMasterScreenByIdFailure,

    resetInvoiceItemMasterScreen,
    resetInvoiceItemMasterScreenList,
} = invoiceItemMasterScreenSlice.actions;

export const getInvoiceItemMasterScreenListState = (store) =>
    store?.invoiceItemMasterScreen?.invoiceItemMasterScreenList;
export const getInvoiceItemMasterScreenCreateState = (store) =>
    store?.invoiceItemMasterScreen?.createInvoiceItemMasterScreen;
export const getInvoiceItemMasterScreenUpdateState = (store) =>
    store?.invoiceItemMasterScreen?.updateInvoiceItemMasterScreen;
export const getInvoiceItemMasterScreenDeleteState = (store) =>
    store?.invoiceItemMasterScreen?.deleteInvoiceItemMasterScreen;
export const getInvoiceItemMasterScreenGetByIdState = (store) =>
    store?.invoiceItemMasterScreen?.getInvoiceItemMasterScreenById;
export default invoiceItemMasterScreenSlice.reducer;
