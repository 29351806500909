import axios from "axios";
import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestSubscriptionRequestMongoDbList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/subscription-request/`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/subscription-request/?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestSubscriptionTermsAndConditionList(params) {
  if (params === undefined) {
    return fetch.get(
      `${BASE_URL}/api/public/public-terms-and-conditions/getLatestTandC`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  } else {
    return fetch.get(
      `${BASE_URL}/api/public/public-terms-and-conditions/getLatestTandC?${params}`
    );
  }
}

export function requestSubscriptionRequestList(data) {
  if (data === undefined) {
    return axios.get(
      "https://3a6fqol6sgrnlyhuhqsiczw2qa0vntuu.lambda-url.ap-south-1.on.aws/"
    );
  } else {
    return axios.get(
      `https://3a6fqol6sgrnlyhuhqsiczw2qa0vntuu.lambda-url.ap-south-1.on.aws/${data}`
    );
  }
}

export function requestSubscriptionRequestCreate(data) {
  return axios.post(
    "https://mq5lb6dilhtbijzmgmeq3cqr3i0nzmzh.lambda-url.ap-south-1.on.aws/",
    data
  );
}

export function requestSubscriptionRequestById(data) {
  return axios.get(
    `https://3a6fqol6sgrnlyhuhqsiczw2qa0vntuu.lambda-url.ap-south-1.on.aws/${data.id}`
  );
}

export function requestSubscriptionGetById(data) {
  return fetch.get(
    `${BASE_URL}/api/public/public-subscription-request/getById/${data.id}`
  );
}

export function requestUpdateSubscriptionMongoDb(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(
    `${BASE_URL}/api/public/public-subscription-request/${data._id}`,
    body
  );
}

export function requestPullFromDynamoDB(params) {
  if (params === undefined) {
    return fetch.get(
      `${BASE_URL}/api/app/subscription-request/getDataFromDynamoDb`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  } else {
    return fetch.get(
      `${BASE_URL}/api/app/subscription-request/getDataFromDynamoDb?${params}`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  }
}

export function requestUpdateSubscriptionRequestForMongoDb(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(
    `${BASE_URL}/api/app/subscription-request/${data._id}`,
    body
  );
}

export function requestSubscriptionRequestQAList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/subscription-request-QA`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/subscription-request-QA?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestSubscriptionRequestQACreate(data) {
  return fetch.post(`${BASE_URL}/api/app/subscription-request-QA`, data);
}

export function requestSubscriptionRequestQAChecklist(data) {
  return fetch.patch(
    `${BASE_URL}/api/app/subscription-request-QA/editSubscriptionRequestQA`,
    data
  );
}

export function requestSubscriptionRequestQAUpdate(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(
    `${BASE_URL}/api/app/subscription-request-QA/${data._id}`,
    body
  );
}
