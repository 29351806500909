import { put, takeLatest } from "redux-saga/effects";
import {
  createRequisitionFailure,
  createRequisitionRequest,
  createRequisitionSuccess,
  deleteRequisitionFailure,
  deleteRequisitionRequest,
  deleteRequisitionSuccess,
  fetchRequisitionListFailure,
  fetchRequisitionListRequest,
  fetchRequisitionListSuccess,
  getRequisitionByIdFailure,
  getRequisitionByIdRequest,
  getRequisitionByIdSuccess,
  sendToPurchaseRequisitionFailure,
  sendToPurchaseRequisitionRequest,
  sendToPurchaseRequisitionSuccess,
  updateRequisitionFailure,
  updateRequisitionRequest,
  updateRequisitionSuccess,
} from "redux/reducers/manage-requisition";
import {
  requestCreateRequisition,
  requestDeleteRequisition,
  requestRequisitionDataById,
  requestRequisitionList,
  requestSendToPurchaseRequisition,
  requestUpdateRequisition,
} from "services/manage-requisition";

export function* watchRequisitionSaga() {
  yield takeLatest(fetchRequisitionListRequest, fetchRequisitionList);
  yield takeLatest(createRequisitionRequest, createRequisition);
  yield takeLatest(updateRequisitionRequest, updateRequisition);
  yield takeLatest(deleteRequisitionRequest, deleteRequisition);
  yield takeLatest(getRequisitionByIdRequest, getRequisitionById);
  yield takeLatest(
    sendToPurchaseRequisitionRequest,
    getSendToPurchaseRequisition
  );
}

function* fetchRequisitionList({ payload }) {
  try {
    const response = yield requestRequisitionList(payload);
    yield put(fetchRequisitionListSuccess(response));
  } catch (error) {
    yield put(fetchRequisitionListFailure("Failed to get list of supplier"));
  }
}

function* createRequisition({ payload }) {
  try {
    // service add
    const response = yield requestCreateRequisition(payload);
    yield put(createRequisitionSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create supplier";
    yield put(createRequisitionFailure(message));
  }
}

function* updateRequisition({ payload }) {
  try {
    const response = yield requestUpdateRequisition(payload);
    yield put(updateRequisitionSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update supplier";
    yield put(updateRequisitionFailure(message));
  }
}

function* deleteRequisition({ payload }) {
  try {
    const response = yield requestDeleteRequisition(payload);
    yield put(deleteRequisitionSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to delete supplier";
    yield put(deleteRequisitionFailure(message));
  }
}

function* getRequisitionById({ payload }) {
  try {
    const response = yield requestRequisitionDataById(payload);
    yield put(getRequisitionByIdSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to get supplier details";
    yield put(getRequisitionByIdFailure(message));
  }
}

function* getSendToPurchaseRequisition({ payload }) {
  try {
    const response = yield requestSendToPurchaseRequisition(payload);
    yield put(sendToPurchaseRequisitionSuccess(response));
  } catch (error) {
    yield put(
      sendToPurchaseRequisitionFailure(
        "Failed to create send to purchase requisition"
      )
    );
  }
}
