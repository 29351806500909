import { createSlice } from "@reduxjs/toolkit";
import { AUTH_TOKEN, LOGGEDIN, USER, TIMEZONE, SUBSCRIBER_ID, CLIENT_ID, CLIENT_NAME } from "redux/constants/Auth";

const initialState = {
  loginResponse: {
    data: { token: localStorage.getItem(AUTH_TOKEN) },
    loading: false,
    error: null,
  },
};

export const loginResponseSlice = createSlice({
  name: "loginResponse",
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(USER);
      localStorage.removeItem(LOGGEDIN);
      localStorage.removeItem(TIMEZONE);
      localStorage.removeItem(SUBSCRIBER_ID);
      localStorage.removeItem(CLIENT_ID);
      localStorage.removeItem(CLIENT_NAME);
      localStorage.clear();
      state.loginResponse.data = null;
      state.loginResponse.error = null;
      state.loginResponse.loading = false;
    },
    setAuthToken: (state, action) => {
      localStorage.setItem(AUTH_TOKEN, action.payload.token);
      state.loginResponse.loading = false;
      state.loginResponse.error = null;
      if (!state.loginResponse.data) {
        state.loginResponse.data = {};
      }
      state.loginResponse.data.token = action.payload.token;
    },
  },
});

export const { setAuthToken, logout } = loginResponseSlice.actions;

export const isUserLoggedIn = (state) =>
  !!state?.auth?.loginResponse?.data?.token;
export const getLoginResponse = (state) => state.auth?.loginResponse;

export default loginResponseSlice.reducer;
