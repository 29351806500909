import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agreementList: { loading: false, data: null, error: null },
  createAgreement: { loading: false, data: null, error: null },
  updateAgreement: { loading: false, data: null, error: null },
  getAgreementById: { loading: false, data: null, error: null },
  checkMachineAvailability: { loading: false, data: null, error: null },
  agreementKpi: { loading: false, data: null, error: null },
  createAgreementClient: { loading: false, data: null, error: null },
};

export const agreementSlice = createSlice({
  name: "agreement",
  initialState,
  reducers: {
    fetchAgreementListRequest: (agreement, action) => {
      agreement.agreementList.loading = true;
      agreement.agreementList.error = null;
    },
    fetchAgreementListSuccess: (agreement, action) => {
      agreement.agreementList.data = action.payload;
      agreement.agreementList.loading = false;
      agreement.agreementList.error = null;
    },
    fetchAgreementListFailure: (agreement, action) => {
      agreement.agreementList.data = null;
      agreement.agreementList.loading = false;
      agreement.agreementList.error = action.payload;
    },

    // create agreement
    createAgreementRequest: (agreement, action) => {
      agreement.createAgreement.loading = true;
      agreement.createAgreement.error = null;
    },
    createAgreementSuccess: (agreement, action) => {
      agreement.createAgreement.data = action.payload;
      agreement.createAgreement.loading = false;
      agreement.createAgreement.error = null;
    },
    createAgreementFailure: (agreement, action) => {
      agreement.createAgreement.data = null;
      agreement.createAgreement.loading = false;
      agreement.createAgreement.error = action.payload;
    },

    // update agreement details
    updateAgreementRequest: (agreement, action) => {
      agreement.updateAgreement.loading = true;
      agreement.updateAgreement.error = null;
    },
    updateAgreementSuccess: (agreement, action) => {
      agreement.updateAgreement.data = action.payload;
      agreement.updateAgreement.loading = false;
      agreement.updateAgreement.error = null;
      agreement.agreementList = updateAgreement(
        action.payload,
        agreement.agreementList
      );
    },
    updateAgreementFailure: (agreement, action) => {
      agreement.updateAgreement.data = null;
      agreement.updateAgreement.loading = false;
      agreement.updateAgreement.error = action.payload;
    },

    // get by id agreement details
    getAgreementDetailsRequest: (agreement, action) => {
      agreement.getAgreementById.loading = true;
      agreement.getAgreementById.error = null;
    },
    getAgreementDetailsSuccess: (agreement, action) => {
      agreement.getAgreementById.loading = false;
      agreement.getAgreementById.error = null;
      agreement.getAgreementById.data = action.payload;
    },
    getAgreementDetailsFailure: (agreement, action) => {
      agreement.getAgreementById.data = null;
      agreement.getAgreementById.loading = false;
      agreement.getAgreementById.error = action.payload;
    },

    // check machine availability
    checkMachineAvailabilityRequest: (agreement, action) => {
      agreement.checkMachineAvailability.loading = true;
      agreement.checkMachineAvailability.error = null;
    },
    checkMachineAvailabilitySuccess: (agreement, action) => {
      agreement.checkMachineAvailability.data = action.payload;
      agreement.checkMachineAvailability.loading = false;
      agreement.checkMachineAvailability.error = null;
    },
    checkMachineAvailabilityFailure: (agreement, action) => {
      agreement.checkMachineAvailability.data = null;
      agreement.checkMachineAvailability.loading = false;
      agreement.checkMachineAvailability.error = action.payload;
    },

    getAgreementKPIRequest: (state, action) => {
      state.agreementKpi.loading = true;
      state.agreementKpi.error = null;
    },
    getAgreementKPISuccess: (state, action) => {
      state.agreementKpi.data = action.payload;
      state.agreementKpi.loading = false;
      state.agreementKpi.error = null;
    },
    getAgreementKPIFailure: (state, action) => {
      state.agreementKpi.data = null;
      state.agreementKpi.loading = false;
      state.agreementKpi.error = action.payload;
    },

    // create client
    createAgreementClientRequest: (agreement, action) => {
      agreement.createAgreementClient.loading = true;
      agreement.createAgreementClient.error = null;
    },
    createAgreementClientSuccess: (agreement, action) => {
      agreement.createAgreementClient.data = action.payload;
      agreement.createAgreementClient.loading = false;
      agreement.createAgreementClient.error = null;
    },
    createAgreementClientFailure: (agreement, action) => {
      agreement.createAgreementClient.data = null;
      agreement.createAgreementClient.loading = false;
      agreement.createAgreementClient.error = action.payload;
    },

    //Reset agreement state
    resetAgreement: (agreement, action) => {
      agreement.createAgreement.data = null;
      agreement.createAgreement.loading = false;
      agreement.createAgreement.error = null;

      agreement.updateAgreement.data = null;
      agreement.updateAgreement.loading = false;
      agreement.updateAgreement.error = null;

      agreement.createAgreementClient.data = null;
      agreement.createAgreementClient.loading = false;
      agreement.createAgreementClient.error = null;
    },
  },
});

// update agreement function
function updateAgreement(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

export const {
  fetchAgreementListRequest,
  fetchAgreementListSuccess,
  fetchAgreementListFailure,

  createAgreementRequest,
  createAgreementSuccess,
  createAgreementFailure,

  updateAgreementRequest,
  updateAgreementSuccess,
  updateAgreementFailure,

  getAgreementDetailsRequest,
  getAgreementDetailsSuccess,
  getAgreementDetailsFailure,

  checkMachineAvailabilityRequest,
  checkMachineAvailabilitySuccess,
  checkMachineAvailabilityFailure,

  getAgreementKPIRequest,
  getAgreementKPISuccess,
  getAgreementKPIFailure,

  createAgreementClientRequest,
  createAgreementClientSuccess,
  createAgreementClientFailure,

  resetAgreement,
} = agreementSlice.actions;

export const getAgreementListState = (store) => store?.agreement?.agreementList;
export const getAgreementCreateState = (store) =>
  store?.agreement?.createAgreement;
export const getAgreementUpdateState = (store) =>
  store?.agreement?.updateAgreement;
export const getAgreementGetByIdState = (store) =>
  store?.agreement?.getAgreementById;
export const checkMachineAvailabilityState = (store) =>
  store?.agreement?.checkMachineAvailability;
export const getAgreementKPIState = (state) => state.agreement?.agreementKpi;
export const getAgreementClientCreateState = (store) =>
  store?.agreement?.createAgreementClient;

export default agreementSlice.reducer;
