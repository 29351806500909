import { put, takeLatest } from "redux-saga/effects";

import {
    requestGenerateInvoiceList,
    requestCreateGenerateInvoice,
    requestUpdateGenerateInvoice,
    requestDeleteGenerateInvoice,
    requestGenerateInvoiceDataById,
} from "services/generateInvoice";

import {
    fetchGenerateInvoiceListRequest,
    fetchGenerateInvoiceListSuccess,
    fetchGenerateInvoiceListFailure,
    createGenerateInvoiceRequest,
    createGenerateInvoiceSuccess,
    createGenerateInvoiceFailure,
    updateGenerateInvoiceRequest,
    updateGenerateInvoiceSuccess,
    updateGenerateInvoiceFailure,
    deleteGenerateInvoiceRequest,
    deleteGenerateInvoiceSuccess,
    deleteGenerateInvoiceFailure,
    getGenerateInvoiceByIdRequest,
    getGenerateInvoiceByIdSuccess,
    getGenerateInvoiceByIdFailure,
} from "redux/reducers/generateInvoice";

export function* watchGenerateInvoiceSaga() {
    yield takeLatest(fetchGenerateInvoiceListRequest, fetchGenerateInvoiceList);
    yield takeLatest(createGenerateInvoiceRequest, createGenerateInvoice);
    yield takeLatest(updateGenerateInvoiceRequest, updateGenerateInvoice);
    yield takeLatest(deleteGenerateInvoiceRequest, deleteGenerateInvoice);
    yield takeLatest(getGenerateInvoiceByIdRequest, getGenerateInvoiceById);
}

function* fetchGenerateInvoiceList({ payload }) {
    try {
        const response = yield requestGenerateInvoiceList(payload);
        yield put(fetchGenerateInvoiceListSuccess(response));
    } catch (error) {
        yield put(
            fetchGenerateInvoiceListFailure("Failed to get list of Invoices")
        );
    }
}

function* createGenerateInvoice({ payload }) {
    try {
        // service add
        const response = yield requestCreateGenerateInvoice(payload);
        yield put(createGenerateInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create Invoice";
        yield put(createGenerateInvoiceFailure(message));
    }
}

function* updateGenerateInvoice({ payload }) {
    try {
        const response = yield requestUpdateGenerateInvoice(payload);
        yield put(updateGenerateInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update Invoice";
        yield put(updateGenerateInvoiceFailure(message));
    }
}

function* deleteGenerateInvoice({ payload }) {
    try {
        const response = yield requestDeleteGenerateInvoice(payload);
        yield put(deleteGenerateInvoiceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete Invoice";
        yield put(deleteGenerateInvoiceFailure(message));
    }
}

function* getGenerateInvoiceById({ payload }) {
    try {
        const response = yield requestGenerateInvoiceDataById(payload);
        yield put(getGenerateInvoiceByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get Invoice details";
        yield put(getGenerateInvoiceByIdFailure(message));
    }
}