import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    generateInvoiceList: { loading: false, data: null, error: null },
    createGenerateInvoice: { loading: false, data: null, error: null },
    updateGenerateInvoice: { loading: false, data: null, error: null },
    deleteGenerateInvoice: { loading: false, data: null, error: null },
    getGenerateInvoiceById: { loading: false, data: null, error: null },
};

export const generateInvoiceSlice = createSlice({
    name: "generateInvoice",
    initialState,
    reducers: {
        fetchGenerateInvoiceListRequest: (generateInvoice, action) => {
            generateInvoice.generateInvoiceList.loading = true;
            generateInvoice.generateInvoiceList.error = null;
        },
        fetchGenerateInvoiceListSuccess: (generateInvoice, action) => {
            generateInvoice.generateInvoiceList.data = action.payload;
            generateInvoice.generateInvoiceList.loading = false;
            generateInvoice.generateInvoiceList.error = null;
        },
        fetchGenerateInvoiceListFailure: (generateInvoice, action) => {
            generateInvoice.generateInvoiceList.data = null;
            generateInvoice.generateInvoiceList.loading = false;
            generateInvoice.generateInvoiceList.error = action.payload;
        },

        //create GenerateInvoice
        createGenerateInvoiceRequest: (generateInvoice, action) => {
            generateInvoice.createGenerateInvoice.loading = true;
            generateInvoice.createGenerateInvoice.error = null;
        },
        createGenerateInvoiceSuccess: (generateInvoice, action) => {
            generateInvoice.createGenerateInvoice.data = action.payload;
            generateInvoice.createGenerateInvoice.loading = false;
            generateInvoice.createGenerateInvoice.error = null;
            generateInvoice.generateInvoiceList =
                addNewGenerateInvoiceSuccess(action.payload, {
                    ...generateInvoice.generateInvoiceList,
                });
        },
        createGenerateInvoiceFailure: (generateInvoice, action) => {
            generateInvoice.createGenerateInvoice.data = null;
            generateInvoice.createGenerateInvoice.loading = false;
            generateInvoice.createGenerateInvoice.error = action.payload;
        },

        //update generateInvoice details
        updateGenerateInvoiceRequest: (generateInvoice, action) => {
            generateInvoice.updateGenerateInvoice.loading = true;
            generateInvoice.updateGenerateInvoice.error = null;
        },
        updateGenerateInvoiceSuccess: (generateInvoice, action) => {
            generateInvoice.updateGenerateInvoice.data = action.payload;
            generateInvoice.updateGenerateInvoice.loading = false;
            generateInvoice.updateGenerateInvoice.error = null;
            generateInvoice.generateInvoiceList =
                updateNewGenerateInvoiceSuccess(
                    action.payload,
                    generateInvoice.generateInvoiceList
                );
        },
        updateGenerateInvoiceFailure: (generateInvoice, action) => {
            generateInvoice.updateGenerateInvoice.data = null;
            generateInvoice.updateGenerateInvoice.loading = false;
            generateInvoice.updateGenerateInvoice.error = action.payload;
        },

        // delete generateInvoice
        deleteGenerateInvoiceRequest: (generateInvoice, action) => {
            generateInvoice.deleteGenerateInvoice.loading = true;
            generateInvoice.deleteGenerateInvoice.error = null;
        },
        deleteGenerateInvoiceSuccess: (generateInvoice, action) => {
            generateInvoice.deleteGenerateInvoice.data = action.payload;
            generateInvoice.deleteGenerateInvoice.loading = false;
            generateInvoice.deleteGenerateInvoice.error = null;
            generateInvoice.generateInvoiceList =
                deleteNewGenerateInvoiceSuccess(action.payload, {
                    ...generateInvoice.generateInvoiceList,
                });
        },
        deleteGenerateInvoiceFailure: (generateInvoice, action) => {
            generateInvoice.deleteGenerateInvoice.data = null;
            generateInvoice.deleteGenerateInvoice.loading = false;
            generateInvoice.deleteGenerateInvoice.error = action.payload;
        },

        // getById generateInvoice details
        getGenerateInvoiceByIdRequest: (generateInvoice, action) => {
            generateInvoice.getGenerateInvoiceById.loading = true;
            generateInvoice.getGenerateInvoiceById.error = null;
        },
        getGenerateInvoiceByIdSuccess: (generateInvoice, action) => {
            generateInvoice.getGenerateInvoiceById.data = action.payload;
            generateInvoice.getGenerateInvoiceById.loading = false;
            generateInvoice.getGenerateInvoiceById.error = null;
        },
        getGenerateInvoiceByIdFailure: (generateInvoice, action) => {
            generateInvoice.getGenerateInvoiceById.data = null;
            generateInvoice.getGenerateInvoiceById.loading = false;
            generateInvoice.getGenerateInvoiceById.error = action.payload;
        },

        // reset generateInvoice details
        resetGenerateInvoice: (generateInvoice, action) => {
            generateInvoice.createGenerateInvoice.data = null;
            generateInvoice.createGenerateInvoice.loading = false;
            generateInvoice.createGenerateInvoice.error = null;

            generateInvoice.updateGenerateInvoice.data = null;
            generateInvoice.updateGenerateInvoice.loading = false;
            generateInvoice.updateGenerateInvoice.error = null;

            generateInvoice.deleteGenerateInvoice.data = null;
            generateInvoice.deleteGenerateInvoice.loading = false;
            generateInvoice.deleteGenerateInvoice.error = null;

            generateInvoice.getGenerateInvoiceById.data = null;
            generateInvoice.getGenerateInvoiceById.loading = false;
            generateInvoice.getGenerateInvoiceById.error = null;
        },

        resetGenerateInvoiceList: (generateInvoice, action) => {
            generateInvoice.generateInvoiceList.data = null;
            generateInvoice.generateInvoiceList.loading = false;
            generateInvoice.generateInvoiceList.error = null;
        },
    },
});

function addNewGenerateInvoiceSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewGenerateInvoiceSuccess(dataToUpdate, state) {
    if (state.data?.data.length) {
        const updatedData = state.data.data.map((value) => {
            if (value._id !== dataToUpdate._id) return value;
            return { ...value, ...dataToUpdate };
        });
        return { ...state, data: { ...state.data, data: updatedData } };
    } else {
        return state;
    }
}

function deleteNewGenerateInvoiceSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchGenerateInvoiceListRequest,
    fetchGenerateInvoiceListSuccess,
    fetchGenerateInvoiceListFailure,

    createGenerateInvoiceRequest,
    createGenerateInvoiceSuccess,
    createGenerateInvoiceFailure,

    updateGenerateInvoiceRequest,
    updateGenerateInvoiceSuccess,
    updateGenerateInvoiceFailure,

    deleteGenerateInvoiceRequest,
    deleteGenerateInvoiceSuccess,
    deleteGenerateInvoiceFailure,

    getGenerateInvoiceByIdRequest,
    getGenerateInvoiceByIdSuccess,
    getGenerateInvoiceByIdFailure,

    resetGenerateInvoice,
    resetGenerateInvoiceList,
} = generateInvoiceSlice.actions;

export const getGenerateInvoiceListState = (store) =>
    store?.generateInvoice?.generateInvoiceList;
export const getGenerateInvoiceCreateState = (store) =>
    store?.generateInvoice?.createGenerateInvoice;
export const getGenerateInvoiceUpdateState = (store) =>
    store?.generateInvoice?.updateGenerateInvoice;
export const getGenerateInvoiceDeleteState = (store) =>
    store?.generateInvoice?.deleteGenerateInvoice;
export const getGenerateInvoiceGetByIdState = (store) =>
    store?.generateInvoice?.getGenerateInvoiceById;
export default generateInvoiceSlice.reducer;
