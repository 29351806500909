import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  commentList: { loading: false, data: null, error: null },
  createComment: { loading: false, data: null, error: null },
  updateComment: { loading: false, data: null, error: null },
  deleteComment: { loading: false, data: null, error: null },
  getCommentById: { loading: false, data: null, error: null },
};

export const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    fetchCommentListRequest: (comment, action) => {
      comment.commentList.loading = true;
      comment.commentList.error = null;
    },
    fetchCommentListSuccess: (comment, action) => {
      comment.commentList.data = action.payload;
      comment.commentList.loading = false;
      comment.commentList.error = null;
    },
    fetchCommentListFailure: (comment, action) => {
      comment.commentList.data = null;
      comment.commentList.loading = false;
      comment.commentList.error = action.payload;
    },

    //create Comment
    createCommentRequest: (comment, action) => {
      comment.createComment.loading = true;
      comment.createComment.error = null;
    },
    createCommentSuccess: (comment, action) => {
      comment.createComment.data = action.payload;
      comment.createComment.loading = false;
      comment.createComment.error = null;
      comment.commentList = addNewCommentSuccess(
        action.payload,
        {
          ...comment.commentList,
        }
      );
    },
    createCommentFailure: (comment, action) => {
      comment.createComment.data = null;
      comment.createComment.loading = false;
      comment.createComment.error = action.payload;
    },

    //update comment details
    updateCommentRequest: (comment, action) => {
      comment.updateComment.loading = true;
      comment.updateComment.error = null;
    },
    updateCommentSuccess: (comment, action) => {
      comment.updateComment.data = action.payload;
      comment.updateComment.loading = false;
      comment.updateComment.error = null;
      comment.commentList = updateNewCommentSuccess(
        action.payload,
        comment.commentList
      );
    },
    updateCommentFailure: (comment, action) => {
      comment.updateComment.data = null;
      comment.updateComment.loading = false;
      comment.updateComment.error = action.payload;
    },

    // delete comment
    deleteCommentRequest: (comment, action) => {
      comment.deleteComment.loading = true;
      comment.deleteComment.error = null;
    },
    deleteCommentSuccess: (comment, action) => {
      comment.deleteComment.data = action.payload;
      comment.deleteComment.loading = false;
      comment.deleteComment.error = null;
      comment.commentList = deleteNewCommentSuccess(
        action.payload,
        {
          ...comment.commentList,
        }
      );
    },
    deleteCommentFailure: (comment, action) => {
      comment.deleteComment.data = null;
      comment.deleteComment.loading = false;
      comment.deleteComment.error = action.payload;
    },

    // getById comment details
    getCommentByIdRequest: (comment, action) => {
      comment.getCommentById.loading = true;
      comment.getCommentById.error = null;
    },
    getCommentByIdSuccess: (comment, action) => {
      comment.getCommentById.data = action.payload;
      comment.getCommentById.loading = false;
      comment.getCommentById.error = null;
    },
    getCommentByIdFailure: (comment, action) => {
      comment.getCommentById.data = null;
      comment.getCommentById.loading = false;
      comment.getCommentById.error = action.payload;
    },

    // reset comment details
    resetComment: (comment, action) => {
      comment.createComment.data = null;
      comment.createComment.loading = false;
      comment.createComment.error = null;

      comment.updateComment.data = null;
      comment.updateComment.loading = false;
      comment.updateComment.error = null;

      comment.deleteComment.data = null;
      comment.deleteComment.loading = false;
      comment.deleteComment.error = null;
    },
  },
});

function addNewCommentSuccess(dataToAdd, state) {
  state.data.data = [dataToAdd, ...state.data.data];
  state.data.total++;
  return state;
}

function updateNewCommentSuccess(dataToUpdate, state) {
  if (state.data?.data.length) {
    const updatedData = state.data.data.map((value) => {
      if (value._id !== dataToUpdate._id) return value;
      return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
  } else {
    return state;
  }
}

function deleteNewCommentSuccess(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
  return state;
}

export const {
  fetchCommentListRequest,
  fetchCommentListSuccess,
  fetchCommentListFailure,

  createCommentRequest,
  createCommentSuccess,
  createCommentFailure,

  updateCommentRequest,
  updateCommentSuccess,
  updateCommentFailure,

  deleteCommentRequest,
  deleteCommentSuccess,
  deleteCommentFailure,

  getCommentByIdRequest,
  getCommentByIdSuccess,
  getCommentByIdFailure,

  resetComment,
} = commentSlice.actions;

export const getCommentListState = (store) => 
   store?.comment?.commentList;
export const getCommentCreateState = (store) =>
  store?.comment?.createComment;
export const getCommentUpdateState = (store) =>
  store?.comment?.updateComment;
export const getCommentDeleteState = (store) =>
  store?.comment?.deleteComment;
export const getCommentGetByIdState = (store) =>
  store?.comment?.getCommentById;
export default commentSlice.reducer;
