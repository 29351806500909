import { put, takeLatest } from "redux-saga/effects";
import {
  createSubscriptionRequestFailure,
  createSubscriptionRequestQAFailure,
  createSubscriptionRequestQARequest,
  createSubscriptionRequestQASuccess,
  createSubscriptionRequestRequest,
  createSubscriptionRequestSuccess,
  fetchPullFromDynamoDBFailure,
  fetchPullFromDynamoDBRequest,
  fetchPullFromDynamoDBSuccess,
  fetchSubscriptionRequestListFailure,
  fetchSubscriptionRequestListRequest,
  fetchSubscriptionRequestListSuccess,
  fetchSubscriptionRequestQAListFailure,
  fetchSubscriptionRequestQAListRequest,
  fetchSubscriptionRequestQAListSuccess,
  fetchTermsConditionRequestListFailure,
  fetchTermsConditionRequestListRequest,
  fetchTermsConditionRequestListSuccess,
  getSubscriptionRequestDetailsFailure,
  getSubscriptionRequestDetailsRequest,
  getSubscriptionRequestDetailsSuccess,
  updateSubscriberRequest,
  updateSubscriberRequestFailure,
  updateSubscriberRequestSuccess,
  updateSubscriptionRequestFailure,
  updateSubscriptionRequestQAFailure,
  updateSubscriptionRequestQARequest,
  updateSubscriptionRequestQASuccess,
  updateSubscriptionRequestRequest,
  updateSubscriptionRequestSuccess,
} from "redux/reducers/subscription-request";
import {
  requestPullFromDynamoDB,
  requestSubscriptionGetById,
  requestSubscriptionRequestCreate,
  requestSubscriptionRequestMongoDbList,
  requestSubscriptionRequestQACreate,
  requestSubscriptionRequestQAList,
  requestSubscriptionRequestQAUpdate,
  requestSubscriptionTermsAndConditionList,
  requestUpdateSubscriptionMongoDb,
  requestUpdateSubscriptionRequestForMongoDb,
} from "services/subscription-request";

export function* watchSubscriptionRequestSaga() {
  yield takeLatest(
    fetchSubscriptionRequestListRequest,
    fetchSubscriptionRequestList
  );
  yield takeLatest(createSubscriptionRequestRequest, createSubscriptionRequest);
  yield takeLatest(
    getSubscriptionRequestDetailsRequest,
    getSubscriptionRequestGetByIdData
  );
  yield takeLatest(updateSubscriptionRequestRequest, updateSubscriptionRequest);
  yield takeLatest(
    fetchTermsConditionRequestListRequest,
    fetchTermsConditionList
  );
  yield takeLatest(fetchPullFromDynamoDBRequest, fetchPullFromDynamoDB);
  yield takeLatest(updateSubscriberRequest, updateSubscriberRequestMongoDB);
  yield takeLatest(
    fetchSubscriptionRequestQAListRequest,
    fetchSubscriptionRequestQAList
  );
  yield takeLatest(
    createSubscriptionRequestQARequest,
    createQASubscriptionRequest
  );
  yield takeLatest(
    updateSubscriptionRequestQARequest,
    updateSubscriptionRequestQA
  );
}

function* fetchSubscriptionRequestList({ payload }) {
  try {
    const response = yield requestSubscriptionRequestMongoDbList(payload);
    yield put(fetchSubscriptionRequestListSuccess(response));
  } catch (error) {
    yield put(
      fetchSubscriptionRequestListFailure(
        "Failed to get list of Subscription Request"
      )
    );
  }
}

function* createSubscriptionRequest({ payload }) {
  try {
    //service add
    const response = yield requestSubscriptionRequestCreate(payload);
    yield put(createSubscriptionRequestSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to create Subscription Request";
    yield put(createSubscriptionRequestFailure(message));
  }
}

function* getSubscriptionRequestGetByIdData({ payload }) {
  try {
    const response = yield requestSubscriptionGetById(payload);
    yield put(getSubscriptionRequestDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get Subscription details";
    yield put(getSubscriptionRequestDetailsFailure(message));
  }
}

function* updateSubscriptionRequest({ payload }) {
  try {
    //service update
    const response = yield requestUpdateSubscriptionMongoDb(payload);
    yield put(updateSubscriptionRequestSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to update subscription request";
    yield put(updateSubscriptionRequestFailure(message));
  }
}

function* fetchTermsConditionList({ payload }) {
  try {
    const response = yield requestSubscriptionTermsAndConditionList(payload);
    yield put(fetchTermsConditionRequestListSuccess(response));
  } catch (error) {
    yield put(
      fetchTermsConditionRequestListFailure(
        "Failed to get list of terms and condition data"
      )
    );
  }
}

function* fetchPullFromDynamoDB({ payload }) {
  try {
    const response = yield requestPullFromDynamoDB(payload);
    yield put(fetchPullFromDynamoDBSuccess(response));
  } catch (error) {
    yield put(fetchPullFromDynamoDBFailure("Failed to pull dynamoDB data"));
  }
}

function* updateSubscriberRequestMongoDB({ payload }) {
  try {
    //service update
    const response = yield requestUpdateSubscriptionRequestForMongoDb(payload);
    yield put(updateSubscriberRequestSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to update subscription request";
    yield put(updateSubscriberRequestFailure(message));
  }
}

function* fetchSubscriptionRequestQAList({ payload }) {
  try {
    const response = yield requestSubscriptionRequestQAList(payload);
    yield put(fetchSubscriptionRequestQAListSuccess(response));
  } catch (error) {
    yield put(
      fetchSubscriptionRequestQAListFailure(
        "Failed to get list of Question answer of Subscription Request"
      )
    );
  }
}

function* createQASubscriptionRequest({ payload }) {
  try {
    //service add
    const response = yield requestSubscriptionRequestQACreate(payload);
    yield put(createSubscriptionRequestQASuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to create QA Subscription Request";
    yield put(createSubscriptionRequestQAFailure(message));
  }
}

function* updateSubscriptionRequestQA({ payload }) {
  try {
    //service update
    const response = yield requestSubscriptionRequestQAUpdate(payload);
    yield put(updateSubscriptionRequestQASuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to update QA subscription request";
    yield put(updateSubscriptionRequestQAFailure(message));
  }
}
