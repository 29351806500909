import React, { useState, useEffect, useCallback } from "react";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormikContext } from "formik";
import htmlToDraft from "html-to-draftjs";

function TextEditor({ editValue, convertedText, initialValue, toolbarHidden, readOnly }) {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    ContentState.createFromText(initialValue || "")
  ));
  useEffect(() => {
    const html = initialValue;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [initialValue]);
  const onChange = (e) => {
    setEditorState(e);
  };
  const formikContext = useFormikContext();

  const updateFormikField = useCallback(() => {
    const isEditorContentEmpty = editorState.getCurrentContent().hasText();
    formikContext?.setFieldValue(
      "description",
      isEditorContentEmpty ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : ""
    );
  }, [editorState, formikContext?.setFieldValue]);
  
  useEffect(() => {
    updateFormikField();
  }, [updateFormikField]);

  return (
    <div style={{ height: "400px", overflowY: "auto" }}>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onChange}
        toolbarHidden={toolbarHidden}
        readOnly={readOnly}
        onBlur={(e) => {
          formikContext.setFieldValue("description", draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }}
      />
    </div>
  );
}

export default TextEditor;
