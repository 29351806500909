import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestManageUserList(params) {
  return fetch.get(`${BASE_URL}/api/app/subscriber-user`, {
    params: { limit: Number.MAX_SAFE_INTEGER },
  });
}

export function requestCreateUser(data) {
  return fetch.post(`${BASE_URL}/api/app/subscriber-user`, data);
}

export function requestUpdateUser(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/subscriber-user/${data._id}`, body);
}

export function requestDeleteUser(data) {
  return fetch.delete(`${BASE_URL}/api/app/subscriber-user/${data}`);
}

export function requestUpdateUserDetails(data) {
  const body = { ...data };
  return fetch.post(
    `${BASE_URL}/api/app/subscriber-user/update-user/${data.id}`,
    body
  );
}

export function requestUserDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/subscriber-user/getById/${data.id}`);
}

export function requestGetUserByRoleList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/subscriber-user/get-users-by-role`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(
      `${BASE_URL}/api/app/subscriber-user/get-users-by-role?${params}`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  }
}

export function requestGetUserByMultipleRoleList(data) {
  return fetch.patch(
    `${BASE_URL}/api/app/subscriber-user/get-users-for-multi-role`,
    data
  );
}

export function requestAccessAlternateId(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/asset/assetWithAlternateId`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(
      `${BASE_URL}/api/app/asset/assetWithAlternateId?${params}`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  }
}

export function requestUpdateSignature(data) {
  return fetch.post(`${BASE_URL}/api/app/subscriber-user/addSignature`, data);
}
