import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestFireExtinguisherList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/fire-extinguisher`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/fire-extinguisher?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateFireExtinguisher(data) {
  return fetch.post(`${BASE_URL}/api/app/fire-extinguisher`, data);
}

export function requestUpdateFireExtinguisher(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/fire-extinguisher/${data._id}`, body);
}

export function requestDeleteFireExtinguisher(data) {
  return fetch.delete(`${BASE_URL}/api/app/fire-extinguisher/${data}`);
}

export function requestFireExtinguisherDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/fire-extinguisher/getById/${data.id}`);
}

export function fireSafetyCertificatePDF(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.post(
    `${BASE_URL}/api/app/fire-extinguisher/generatePdf/${data._id}`,
    body
  );
}
