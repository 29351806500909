import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestFeedBackList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/svc-feedBack`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/svc-feedBack?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER }
        });
    }
}

export function requestCreateFeedBack(data) {
    return fetch.post(`${BASE_URL}/api/app/svc-feedBack`, data)
}

export function requestUpdateFeedBack(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/svc-feedBack/${data._id}`, body);
}

export function requestDeleteFeedBack(data) {
    return fetch.delete(`${BASE_URL}/api/app/svc-feedBack/${data}`);
}

export function requestFeedBackDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/svc-feedback/getById/${data._id}`);
}