import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestLeadList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/lead`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/lead?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateLead(data) {
  return fetch.post(`${BASE_URL}/api/app/lead/`, data);
}

export function requestUpdateLead(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/lead/${data._id}`, body);
}

export function requestDeleteLead(data) {
  return fetch.delete(`${BASE_URL}/api/app/lead/${data}`);
}

export function requestLeadDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/lead/getById/${data.id}`);
}

export function requestAddNoteToLead(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.patch(`${BASE_URL}/api/app/lead/add-note/${data._id}`, body);
}

export function requestDeleteLeadNote(data) {
  return fetch.delete(
    `${BASE_URL}/api/app/lead/delete-note/${data.leadId}/${data.noteId}`
  );
}

export function requestLeadStageChange(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.patch(`${BASE_URL}/api/app/lead/change-stage/${data._id}`, body);
}

export function requestLeadKPIData(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/lead/getKpi`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/lead/getKpi?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}
