import { put, takeLatest } from "redux-saga/effects";

import { requestDeleteDocument, requestUpdateDocument } from "../../../services/document";

import {
  updateDocumentRequest,
  updateDocumentSuccess,
  updateDocumentFailure,
  deleteDocumentRequest,
  deleteDocumentSuccess,
  deleteDocumentFailure,
} from "../../reducers/document";

export function* watchDocumentSaga() {
  yield takeLatest(updateDocumentRequest, updateDocument);
  yield takeLatest(deleteDocumentRequest, deleteDocument);
}

function* updateDocument({ payload }) {
  try {
    const response = yield requestUpdateDocument(payload);
    yield put(updateDocumentSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update document";
    yield put(updateDocumentFailure(message));
  }
}

function* deleteDocument({ payload }) {
  try {
    const response = yield requestDeleteDocument(payload);
    yield put(deleteDocumentSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to Delete document";
    yield put(deleteDocumentFailure(message));
  }
}
