import { put, takeLatest } from "redux-saga/effects";

import {
    requestClientMasterList,
    requestCreateClientMaster,
    requestUpdateClientMaster,
    requestDeleteClientMaster,
    requestClientMasterDataById,
} from "services/clientMaster";

import {
    fetchClientMasterListRequest,
    fetchClientMasterListSuccess,
    fetchClientMasterListFailure,
    createClientMasterRequest,
    createClientMasterSuccess,
    createClientMasterFailure,
    updateClientMasterRequest,
    updateClientMasterSuccess,
    updateClientMasterFailure,
    deleteClientMasterRequest,
    deleteClientMasterSuccess,
    deleteClientMasterFailure,
    getClientMasterByIdRequest,
    getClientMasterByIdSuccess,
    getClientMasterByIdFailure,
} from "redux/reducers/clientMaster";

export function* watchClientMasterSaga() {
    yield takeLatest(fetchClientMasterListRequest, fetchClientMasterList);
    yield takeLatest(createClientMasterRequest, createClientMaster);
    yield takeLatest(updateClientMasterRequest, updateClientMaster);
    yield takeLatest(deleteClientMasterRequest, deleteClientMaster);
    yield takeLatest(getClientMasterByIdRequest, getClientMasterById);
}

function* fetchClientMasterList({ payload }) {
    try {
        const response = yield requestClientMasterList(payload);
        yield put(fetchClientMasterListSuccess(response));
    } catch (error) {
        yield put(
            fetchClientMasterListFailure("Failed to get list of Client Master")
        );
    }
}

function* createClientMaster({ payload }) {
    try {
        // service add
        const response = yield requestCreateClientMaster(payload);
        yield put(createClientMasterSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create client master";
        yield put(createClientMasterFailure(message));
    }
}

function* updateClientMaster({ payload }) {
    try {
        const response = yield requestUpdateClientMaster(payload);
        yield put(updateClientMasterSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update client master";
        yield put(updateClientMasterFailure(message));
    }
}

function* deleteClientMaster({ payload }) {
    try {
        const response = yield requestDeleteClientMaster(payload);
        yield put(deleteClientMasterSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete client master";
        yield put(deleteClientMasterFailure(message));
    }
}

function* getClientMasterById({ payload }) {
    try {
        const response = yield requestClientMasterDataById(payload);
        yield put(getClientMasterByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get client master details";
        yield put(getClientMasterByIdFailure(message));
    }
}