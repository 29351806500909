import { put, takeLatest } from "redux-saga/effects";

import {
    requestInvoiceItemMasterScreenList,
    requestCreateInvoiceItemMasterScreen,
    requestUpdateInvoiceItemMasterScreen,
    requestDeleteInvoiceItemMasterScreen,
    requestInvoiceItemMasterScreenDataById,
} from "services/invoiceItemMasterScreen";

import {
    fetchInvoiceItemMasterScreenListRequest,
    fetchInvoiceItemMasterScreenListSuccess,
    fetchInvoiceItemMasterScreenListFailure,
    createInvoiceItemMasterScreenRequest,
    createInvoiceItemMasterScreenSuccess,
    createInvoiceItemMasterScreenFailure,
    updateInvoiceItemMasterScreenRequest,
    updateInvoiceItemMasterScreenSuccess,
    updateInvoiceItemMasterScreenFailure,
    deleteInvoiceItemMasterScreenRequest,
    deleteInvoiceItemMasterScreenSuccess,
    deleteInvoiceItemMasterScreenFailure,
    getInvoiceItemMasterScreenByIdRequest,
    getInvoiceItemMasterScreenByIdSuccess,
    getInvoiceItemMasterScreenByIdFailure,
} from "redux/reducers/invoiceItemMasterScreen";

export function* watchInvoiceItemMasterScreenSaga() {
    yield takeLatest(fetchInvoiceItemMasterScreenListRequest, fetchInvoiceItemMasterScreenList);
    yield takeLatest(createInvoiceItemMasterScreenRequest, createInvoiceItemMasterScreen);
    yield takeLatest(updateInvoiceItemMasterScreenRequest, updateInvoiceItemMasterScreen);
    yield takeLatest(deleteInvoiceItemMasterScreenRequest, deleteInvoiceItemMasterScreen);
    yield takeLatest(getInvoiceItemMasterScreenByIdRequest, getInvoiceItemMasterScreenById);
}

function* fetchInvoiceItemMasterScreenList({ payload }) {
    try {
        const response = yield requestInvoiceItemMasterScreenList(payload);
        yield put(fetchInvoiceItemMasterScreenListSuccess(response));
    } catch (error) {
        yield put(
            fetchInvoiceItemMasterScreenListFailure("Failed to get list of invoice item master")
        );
    }
}

function* createInvoiceItemMasterScreen({ payload }) {
    try {
        // service add
        const response = yield requestCreateInvoiceItemMasterScreen(payload);
        yield put(createInvoiceItemMasterScreenSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create invoice item master";
        yield put(createInvoiceItemMasterScreenFailure(message));
    }
}

function* updateInvoiceItemMasterScreen({ payload }) {
    try {
        const response = yield requestUpdateInvoiceItemMasterScreen(payload);
        yield put(updateInvoiceItemMasterScreenSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update invoice item master";
        yield put(updateInvoiceItemMasterScreenFailure(message));
    }
}

function* deleteInvoiceItemMasterScreen({ payload }) {
    try {
        const response = yield requestDeleteInvoiceItemMasterScreen(payload);
        yield put(deleteInvoiceItemMasterScreenSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete invoice item master";
        yield put(deleteInvoiceItemMasterScreenFailure(message));
    }
}

function* getInvoiceItemMasterScreenById({ payload }) {
    try {
        const response = yield requestInvoiceItemMasterScreenDataById(payload);
        yield put(getInvoiceItemMasterScreenByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get invoice item master details";
        yield put(getInvoiceItemMasterScreenByIdFailure(message));
    }
}