import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestCommentList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/svc-ticket-comments`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/svc-ticket-comments?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER }
        });
    }
}

export function requestCreateComment(data) {
    return fetch.post(`${BASE_URL}/api/app/svc-ticket-comments`, data)
}

export function requestUpdateComment(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/svc-ticket-comments/${data._id}`, body);
}

export function requestDeleteComment(data) {
    return fetch.delete(`${BASE_URL}/api/app/svc-ticket-comments/${data}`);
}

export function requestCommentDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/svc-ticket-comments/getById/${data.id}`);
}