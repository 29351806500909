import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requisitionList: { loading: false, data: null, error: null },
  createRequisition: { loading: false, data: null, error: null },
  updateRequisition: { loading: false, data: null, error: null },
  deleteRequisition: { loading: false, data: null, error: null },
  getRequisitionById: { loading: false, data: null, error: null },
  sendToPurchaseRequisition: { loading: false, data: null, error: null },
};

export const requisitionSlice = createSlice({
  name: "requisition",
  initialState,
  reducers: {
    fetchRequisitionListRequest: (requisition, action) => {
      requisition.requisitionList.loading = true;
      requisition.requisitionList.error = null;
    },
    fetchRequisitionListSuccess: (requisition, action) => {
      requisition.requisitionList.data = action.payload;
      requisition.requisitionList.loading = false;
      requisition.requisitionList.error = null;
    },
    fetchRequisitionListFailure: (requisition, action) => {
      requisition.requisitionList.data = null;
      requisition.requisitionList.loading = false;
      requisition.requisitionList.error = action.payload;
    },

    //create Requisition
    createRequisitionRequest: (requisition, action) => {
      requisition.createRequisition.loading = true;
      requisition.createRequisition.error = null;
    },
    createRequisitionSuccess: (requisition, action) => {
      requisition.createRequisition.data = action.payload;
      requisition.createRequisition.loading = false;
      requisition.createRequisition.error = null;
      requisition.requisitionList = addNewRequisitionSuccess(action.payload, {
        ...requisition.requisitionList,
      });
    },
    createRequisitionFailure: (requisition, action) => {
      requisition.createRequisition.data = null;
      requisition.createRequisition.loading = false;
      requisition.createRequisition.error = action.payload;
    },

    //update requisition details
    updateRequisitionRequest: (requisition, action) => {
      requisition.updateRequisition.loading = true;
      requisition.updateRequisition.error = null;
    },
    updateRequisitionSuccess: (requisition, action) => {
      requisition.updateRequisition.data = action.payload;
      requisition.updateRequisition.loading = false;
      requisition.updateRequisition.error = null;
      requisition.requisitionList = updateNewRequisitionSuccess(
        action.payload,
        requisition.requisitionList
      );
    },
    updateRequisitionFailure: (requisition, action) => {
      requisition.updateRequisition.data = null;
      requisition.updateRequisition.loading = false;
      requisition.updateRequisition.error = action.payload;
    },

    // delete requisition
    deleteRequisitionRequest: (requisition, action) => {
      requisition.deleteRequisition.loading = true;
      requisition.deleteRequisition.error = null;
    },
    deleteRequisitionSuccess: (requisition, action) => {
      requisition.deleteRequisition.data = action.payload;
      requisition.deleteRequisition.loading = false;
      requisition.deleteRequisition.error = null;
      requisition.requisitionList = deleteNewRequisitionSuccess(
        action.payload,
        {
          ...requisition.requisitionList,
        }
      );
    },
    deleteRequisitionFailure: (requisition, action) => {
      requisition.deleteRequisition.data = null;
      requisition.deleteRequisition.loading = false;
      requisition.deleteRequisition.error = action.payload;
    },

    // getById requisition details
    getRequisitionByIdRequest: (requisition, action) => {
      requisition.getRequisitionById.loading = true;
      requisition.getRequisitionById.error = null;
    },
    getRequisitionByIdSuccess: (requisition, action) => {
      requisition.getRequisitionById.data = action.payload;
      requisition.getRequisitionById.loading = false;
      requisition.getRequisitionById.error = null;
    },
    getRequisitionByIdFailure: (requisition, action) => {
      requisition.getRequisitionById.data = null;
      requisition.getRequisitionById.loading = false;
      requisition.getRequisitionById.error = action.payload;
    },

    // send to purchase requisition details
    sendToPurchaseRequisitionRequest: (requisition, action) => {
      requisition.sendToPurchaseRequisition.loading = true;
      requisition.sendToPurchaseRequisition.error = null;
    },
    sendToPurchaseRequisitionSuccess: (requisition, action) => {
      requisition.sendToPurchaseRequisition.data = action.payload;
      requisition.sendToPurchaseRequisition.loading = false;
      requisition.sendToPurchaseRequisition.error = null;
    },
    sendToPurchaseRequisitionFailure: (requisition, action) => {
      requisition.sendToPurchaseRequisition.data = null;
      requisition.sendToPurchaseRequisition.loading = false;
      requisition.sendToPurchaseRequisition.error = action.payload;
    },

    // reset requisition details
    resetRequisition: (requisition, action) => {
      requisition.requisitionList.data = null;
      requisition.requisitionList.loading = false;
      requisition.requisitionList.error = null;

      requisition.createRequisition.data = null;
      requisition.createRequisition.loading = false;
      requisition.createRequisition.error = null;

      requisition.updateRequisition.data = null;
      requisition.updateRequisition.loading = false;
      requisition.updateRequisition.error = null;

      requisition.deleteRequisition.data = null;
      requisition.deleteRequisition.loading = false;
      requisition.deleteRequisition.error = null;

      requisition.getRequisitionById.data = null;
      requisition.getRequisitionById.loading = false;
      requisition.getRequisitionById.error = null;

      requisition.sendToPurchaseRequisition.data = null;
      requisition.sendToPurchaseRequisition.loading = false;
      requisition.sendToPurchaseRequisition.error = null;
    },
  },
});

function addNewRequisitionSuccess(dataToAdd, state) {
  state.data.data = [dataToAdd, ...state.data.data];
  state.data.total++;
  return state;
}

function updateNewRequisitionSuccess(dataToUpdate, state) {
  if (state.data?.data.length) {
    const updatedData = state.data.data.map((value) => {
      if (value._id !== dataToUpdate._id) return value;
      return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
  } else {
    return state;
  }
}

function deleteNewRequisitionSuccess(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
  return state;
}

export const {
  fetchRequisitionListRequest,
  fetchRequisitionListSuccess,
  fetchRequisitionListFailure,

  createRequisitionRequest,
  createRequisitionSuccess,
  createRequisitionFailure,

  updateRequisitionRequest,
  updateRequisitionSuccess,
  updateRequisitionFailure,

  deleteRequisitionRequest,
  deleteRequisitionSuccess,
  deleteRequisitionFailure,

  getRequisitionByIdRequest,
  getRequisitionByIdSuccess,
  getRequisitionByIdFailure,

  sendToPurchaseRequisitionRequest,
  sendToPurchaseRequisitionSuccess,
  sendToPurchaseRequisitionFailure,

  resetRequisition,
} = requisitionSlice.actions;

export const getRequisitionListState = (store) =>
  store?.requisition?.requisitionList;
export const getRequisitionCreateState = (store) =>
  store?.requisition?.createRequisition;
export const getRequisitionUpdateState = (store) =>
  store?.requisition?.updateRequisition;
export const getRequisitionDeleteState = (store) =>
  store?.requisition?.deleteRequisition;
export const getRequisitionGetByIdState = (store) =>
  store?.requisition?.getRequisitionById;
export const sendToPurchaseRequisitionState = (store) =>
  store?.requisition?.sendToPurchaseRequisition;
export default requisitionSlice.reducer;
