import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workLogList: { loading: false, data: null, error: null },
    createWorkLog: { loading: false, data: null, error: null },
    updateWorkLog: { loading: false, data: null, error: null },
    deleteWorkLog: { loading: false, data: null, error: null },
    getWorkLogById: { loading: false, data: null, error: null }
}

export const workLogSlice = createSlice({
    name: "workLog",
    initialState,
    reducers: {
        fetchWorkLogListRequest: (workLog, action) => {
            workLog.workLogList.loading = true;
            workLog.workLogList.error = null;
        },
        fetchWorkLogListSuccess: (workLog, action) => {
            workLog.workLogList.data = action.payload;
            workLog.workLogList.loading = false;
            workLog.workLogList.error = null;
        },
        fetchWorkLogListFailure: (workLog, action) => {
            workLog.workLogList.data = null;
            workLog.workLogList.loading = false;
            workLog.workLogList.error = action.payload;
        },

        //create WorkLog
        createWorkLogRequest: (workLog, action) => {
            workLog.createWorkLog.loading = true;
            workLog.createWorkLog.error = null;
        },
        createWorkLogSuccess: (workLog, action) => {
            workLog.createWorkLog.data = action.payload;
            workLog.createWorkLog.loading = false;
            workLog.createWorkLog.error = null;
        },
        createWorkLogFailure: (workLog, action) => {
            workLog.createWorkLog.data = null;
            workLog.createWorkLog.loading = false;
            workLog.createWorkLog.error = action.payload;
        },

        //update workLog details
        updateWorkLogRequest: (workLog, action) => {
            workLog.updateWorkLog.loading = true;
            workLog.updateWorkLog.error = null;
        },
        updateWorkLogSuccess: (workLog, action) => {
            workLog.updateWorkLog.data = action.payload;
            workLog.updateWorkLog.loading = false;
            workLog.updateWorkLog.error = null;
        },
        updateWorkLogFailure: (workLog, action) => {
            workLog.updateWorkLog.data = null;
            workLog.updateWorkLog.loading = false;
            workLog.updateWorkLog.error = action.payload;
        },

        // delete workLog
        deleteWorkLogRequest: (workLog, action) => {
            workLog.deleteWorkLog.loading = true;
            workLog.deleteWorkLog.error = null;
        },
        deleteWorkLogSuccess: (workLog, action) => {
            workLog.deleteWorkLog.data = action.payload;
            workLog.deleteWorkLog.loading = false;
            workLog.deleteWorkLog.error = null;
        },
        deleteWorkLogFailure: (workLog, action) => {
            workLog.deleteWorkLog.data = null;
            workLog.deleteWorkLog.loading = false;
            workLog.deleteWorkLog.error = action.payload;
        },

        // getById workLog details
        getWorkLogByIdRequest: (workLog, action) => {
            workLog.getWorkLogById.loading = true;
            workLog.getWorkLogById.error = null;
        },
        getWorkLogByIdSuccess: (workLog, action) => {
            workLog.getWorkLogById.data = action.payload;
            workLog.getWorkLogById.loading = false;
            workLog.getWorkLogById.error = null;
        },
        getWorkLogByIdFailure: (workLog, action) => {
            workLog.getWorkLogById.data = null;
            workLog.getWorkLogById.loading = false;
            workLog.getWorkLogById.error = action.payload;
        },

        // reset workLog details
        resetWorkLog: (workLog, action) => {
            workLog.createWorkLog.data = null;
            workLog.createWorkLog.loading = false;
            workLog.createWorkLog.error = null;

            workLog.updateWorkLog.data = null;
            workLog.updateWorkLog.loading = false;
            workLog.updateWorkLog.error = null;

            workLog.deleteWorkLog.data = null;
            workLog.deleteWorkLog.loading = false;
            workLog.deleteWorkLog.error = null;
        }
    }
})

export const {
    fetchWorkLogListRequest,
    fetchWorkLogListSuccess,
    fetchWorkLogListFailure,

    createWorkLogRequest,
    createWorkLogSuccess,
    createWorkLogFailure,

    updateWorkLogRequest,
    updateWorkLogSuccess,
    updateWorkLogFailure,

    deleteWorkLogRequest,
    deleteWorkLogSuccess,
    deleteWorkLogFailure,

    getWorkLogByIdRequest,
    getWorkLogByIdSuccess,
    getWorkLogByIdFailure,

    resetWorkLog,
} = workLogSlice.actions;

export const getWorkLogListState = (store) =>
    store?.workLog?.workLogList;
export const getWorkLogCreateState = (store) =>
    store?.workLog?.createWorkLog;
export const getWorkLogUpdateState = (store) =>
    store?.workLog?.updateWorkLog;
export const getWorkLogDeleteState = (store) =>
    store?.workLog?.deleteWorkLog;
export const getWorkLogGetByIdState = (store) =>
    store?.workLog?.getWorkLogById;
export default workLogSlice.reducer;