import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestSparePartList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/request-spare`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/request-spare`, {
      params: { limit: Number.MAX_SAFE_INTEGER, ...params },
    });
  }
}

export function requestSparePartCreate(data) {
  return fetch.post(`${BASE_URL}/api/app/request-spare`, data);
}

export function requestDeleteSparePartRequest(data) {
  return fetch.delete(`${BASE_URL}/api/app/request-spare/${data}`);
}

export function requestUpdateSparePartStatus(data) {
  return fetch.patch(`${BASE_URL}/api/app/request-spare/updateRequest`, {
    ...data,
  });
}

export function requestRequestedSparePartDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/request-spare/getById/${data.id}`);
}