import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  draftPOList: { loading: false, data: null, error: null },
  draftPOGetById: { loading: false, data: null, error: null },
  createDraftPO: { loading: false, data: null, error: null },
  editDraftPO: { loading: false, data: null, error: null },
  deleteDraftPO: { loading: false, data: null, error: null },
};

export const DraftPOSlice = createSlice({
  name: "draftPO",
  initialState,
  reducers: {
    fetchDraftPOListRequest: (state, action) => {
      state.draftPOList.loading = true;
      state.draftPOList.error = null;
    },
    fetchDraftPOListSuccess: (state, action) => {
      state.draftPOList.data = action.payload;
      state.draftPOList.loading = false;
      state.draftPOList.error = null;
    },
    fetchDraftPOListFailure: (state, action) => {
      state.draftPOList.data = null;
      state.draftPOList.loading = false;
      state.draftPOList.error = action.payload;
    },
    fetchDraftPOGetByIdRequest: (state, action) => {
      state.draftPOGetById.loading = true;
      state.draftPOGetById.error = null;
    },
    fetchDraftPOGetByIdSuccess: (state, action) => {
      state.draftPOGetById.data = action.payload;
      state.draftPOGetById.loading = false;
      state.draftPOGetById.error = null;
    },
    fetchDraftPOGetByIdFailure: (state, action) => {
      state.draftPOGetById.data = null;
      state.draftPOGetById.loading = false;
      state.draftPOGetById.error = action.payload;
    },
    createDraftPORequest: (state, action) => {
      state.createDraftPO.loading = true;
      state.createDraftPO.error = null;
    },
    createDraftPOSuccess: (state, action) => {
      state.createDraftPO.data = action.payload;
      state.createDraftPO.loading = false;
      state.createDraftPO.error = null;
      // state.draftPOList = addNewDraftPOSuccess(action.payload, {
      //   ...state.draftPOList,
      // });
    },
    createDraftPOFailure: (state, action) => {
      state.createDraftPO.data = null;
      state.createDraftPO.loading = false;
      state.createDraftPO.error = action.payload;
    },
    editDraftPORequest: (state, action) => {
      state.editDraftPO.loading = true;
      state.editDraftPO.error = null;
    },
    editDraftPOSuccess: (state, action) => {
      state.editDraftPO.data = action.payload;
      state.editDraftPO.loading = false;
      state.editDraftPO.error = null;
      state.draftPOList = updateDraftPOListSuccess(action.payload, {
        ...state.draftPOList,
      });
    },
    editDraftPOFailure: (state, action) => {
      state.editDraftPO.data = null;
      state.editDraftPO.loading = false;
      state.editDraftPO.error = action.payload;
    },
    deleteDraftPORequest: (state, action) => {
      state.deleteDraftPO.loading = true;
      state.deleteDraftPO.error = null;
    },
    deleteDraftPOSuccess: (state, action) => {
      state.deleteDraftPO.loading = false;
      state.deleteDraftPO.error = null;
      state.deleteDraftPO.data = action.payload;
      // add data to list
      state.draftPOList = deletePO(action.payload, { ...state.draftPOList });
    },
    deleteDraftPOFailure: (state, action) => {
      state.deleteDraftPO.data = null;
      state.deleteDraftPO.loading = false;
      state.deleteDraftPO.error = action.payload;
    },

    resetDraftPO: (data, action) => {
      data.createDraftPO.data = null;
      data.createDraftPO.error = null;
      data.createDraftPO.loading = false;

      data.editDraftPO.data = null;
      data.editDraftPO.error = null;
      data.editDraftPO.loading = false;

      data.deleteDraftPO.data = null;
      data.deleteDraftPO.error = null;
      data.deleteDraftPO.loading = false;
    },
  },
});

function addNewDraftPOSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

function updateDraftPOListSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((lead) => {
    if (lead._id !== dataToUpdate._id) return lead;
    return { ...lead, ...dataToUpdate };
  });
  return state;
}

function deletePO(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchDraftPOListRequest,
  fetchDraftPOListSuccess,
  fetchDraftPOListFailure,
  fetchDraftPOGetByIdRequest,
  fetchDraftPOGetByIdSuccess,
  fetchDraftPOGetByIdFailure,
  createDraftPORequest,
  createDraftPOSuccess,
  createDraftPOFailure,
  editDraftPORequest,
  editDraftPOSuccess,
  editDraftPOFailure,
  deleteDraftPORequest,
  deleteDraftPOSuccess,
  deleteDraftPOFailure,

  resetDraftPO,
} = DraftPOSlice.actions;

export const getDraftPOListState = (store) => store.draftPO?.draftPOList;
export const getDraftPOGetByIdState = (store) => store.draftPO?.draftPOGetById;
export const createDraftPOState = (store) => store.draftPO?.createDraftPO;
export const editDraftPOState = (store) => store.draftPO?.editDraftPO;
export const deleteDraftPOState = (store) => store.draftPO?.deleteDraftPO;

export default DraftPOSlice.reducer;
