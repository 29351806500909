import agreementSlice from "./agreement";
import leadSlice from "./lead";
import proposalSlice from "./proposal";

const reducerSalesPipeline = {
  lead: leadSlice,
  proposal: proposalSlice,
  agreement: agreementSlice,
};

export default reducerSalesPipeline;
