import { put, takeLatest } from "redux-saga/effects";

import {
  requestResourceViewist,
  requestUpdateResourceView,
} from "services/resourceView";

import {
  fetchresourceViewListRequest,
  fetchresourceViewListSuccess,
  fetchresourceViewListFailure,
  updateresourceViewRequest,
  updateresourceViewSuccess,
  updateresourceViewFailure,
} from "redux/reducers/resourceView";

export function* watchResourceViewSaga() {
  yield takeLatest(
    fetchresourceViewListRequest,
    fetchResourceViewList
  );

  yield takeLatest(updateresourceViewRequest, updateresourceView);
}

function* fetchResourceViewList({ payload }) {
  try {
    const response = yield requestResourceViewist(payload);
    yield put(fetchresourceViewListSuccess(response));
  } catch (error) {
    yield put(fetchresourceViewListFailure("Failed to get list "));
  }
}

function* updateresourceView({ payload }) {
  try {
    const response = yield requestUpdateResourceView(payload);
    yield put(updateresourceViewSuccess(response.data));
  } catch (error) {
    const message = error?.response?.data?.data?.message || "Failed to update ";
    yield put(updateresourceViewFailure(message));
  }
}
