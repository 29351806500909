import { put, takeLatest } from "redux-saga/effects";
import {
  fetchClientSpocListRequest,
  fetchClientSpocListSuccess,
  fetchClientSpocListFailure,
  createClientSpocRequest,
  createClientSpocSuccess,
  createClientSpocFailure,
  updateClientSpocRequest,
  updateClientSpocSuccess,
  updateClientSpocFailure,
  clientSpocGetByIdRequest,
  clientSpocGetByIdSuccess,
  clientSpocGetByIdFailure,
  deleteClientSpocRequest,
  deleteClientSpocSuccess,
  deleteClientSpocFailure,
  fetchClientSpocDropdownListRequest,
  fetchClientSpocDropdownListFailure,
  fetchClientSpocDropdownListSuccess,
} from "redux/reducers/clientSpoc";
import {
  requestClientSpocList,
  requestCreateClientSpoc,
  requestUpdateClientSpoc,
  requestClientSpocDataById,
  requestDeleteClientSpoc,
  requestClientSpocDropdownList,
} from "services/clientSpoc";

export function* watchClientSpocSaga() {
  yield takeLatest(fetchClientSpocListRequest, fetchClientSpocList);
  yield takeLatest(createClientSpocRequest, createClientSpoc);
  yield takeLatest(updateClientSpocRequest, updateClientSpoc);
  yield takeLatest(clientSpocGetByIdRequest, getClientSpocGetById);
  yield takeLatest(deleteClientSpocRequest, deleteClientSpocData);
  yield takeLatest(
    fetchClientSpocDropdownListRequest,
    fetchClientSpocDropdownListData
  );
}

function* fetchClientSpocList({ payload }) {
  try {
    const response = yield requestClientSpocList(payload);
    yield put(fetchClientSpocListSuccess(response));
  } catch (error) {
    yield put(fetchClientSpocListFailure("Failed to get list of client spoc"));
  }
}

function* createClientSpoc({ payload }) {
  try {
    const response = yield requestCreateClientSpoc(payload);
    yield put(createClientSpocSuccess(response.data));
  } catch (error) {
    yield put(createClientSpocFailure("Failed to create client spoc"));
  }
}

function* updateClientSpoc({ payload }) {
  try {
    const response = yield requestUpdateClientSpoc(payload);
    yield put(updateClientSpocSuccess(response.data));
  } catch (error) {
    yield put(updateClientSpocFailure("Failed to update client spoc"));
  }
}

function* getClientSpocGetById({ payload }) {
  try {
    const response = yield requestClientSpocDataById(payload);
    yield put(clientSpocGetByIdSuccess(response));
  } catch (error) {
    yield put(clientSpocGetByIdFailure("Failed to get client spoc deatils"));
  }
}

function* deleteClientSpocData({ payload }) {
  try {
    const response = yield requestDeleteClientSpoc(payload);
    yield put(deleteClientSpocSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to Delete client spoc";
    yield put(deleteClientSpocFailure(message));
  }
}

function* fetchClientSpocDropdownListData({ payload }) {
  try {
    const response = yield requestClientSpocDropdownList(payload);
    yield put(fetchClientSpocDropdownListSuccess(response));
  } catch (error) {
    yield put(
      fetchClientSpocDropdownListFailure("Failed to get list of client spoc")
    );
  }
}
