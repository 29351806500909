import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestSparePartList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/spare-part-inventory/`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/spare-part-inventory/?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateSparePart(data) {
  return fetch.post(`${BASE_URL}/api/app/spare-part-inventory/`, data);
}

export function requestUpdateSparePart(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(
    `${BASE_URL}/api/app/spare-part-inventory/${data._id}`,
    body
  );
}

export function requestDeleteSparePart(data) {
  return fetch.delete(`${BASE_URL}/api/app/spare-part-inventory/${data}`);
}

export function requestSparePartDataById(data) {
  return fetch.get(
    `${BASE_URL}/api/app/spare-part-inventory/getById/${data.id}`
  );
}

export function requestAssociationOfMachinePartList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/association-of-machine-parts/`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(
      `${BASE_URL}/api/app/association-of-machine-parts/?${params}`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  }
}

export function createTransaction(params) {
  return fetch.post(`${BASE_URL}/api/app/transaction`, {
    ...params,
  });
}
export function getTransaction(params) {
  return fetch.get(`${BASE_URL}/api/app/transaction`, {
    params: { ...params, limit: 100 },
  });
}

export function requestSparePartDropdownList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/spare-part-inventory/getParts`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(
      `${BASE_URL}/api/app/spare-part-inventory/getParts?${params}`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  }
}
