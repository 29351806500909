import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestRequisitionList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/purchase-order-requisition`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(
      `${BASE_URL}/api/app/purchase-order-requisition?${params}`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  }
}

export function requestCreateRequisition(data) {
  return fetch.post(`${BASE_URL}/api/app/purchase-order-requisition`, data);
}

export function requestUpdateRequisition(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(
    `${BASE_URL}/api/app/purchase-order-requisition/${data._id}`,
    body
  );
}

export function requestDeleteRequisition(data) {
  return fetch.delete(`${BASE_URL}/api/app/purchase-order-requisition/${data}`);
}

export function requestRequisitionDataById(data) {
  return fetch.get(
    `${BASE_URL}/api/app/purchase-order-requisition/getById/${data.id}`
  );
}

export function requestSendToPurchaseRequisition(data) {
  return fetch.put(`${BASE_URL}/api/app/purchase-order-requisition/update/list`, data);
}
