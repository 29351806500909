import { put, takeLatest } from "redux-saga/effects";

import {
    requestCommentList,
    requestCreateComment,
    requestUpdateComment,
    requestDeleteComment,
    requestCommentDataById,
} from "services/comments";

import {
    fetchCommentListRequest,
    fetchCommentListSuccess,
    fetchCommentListFailure,
    createCommentRequest,
    createCommentSuccess,
    createCommentFailure,
    updateCommentRequest,
    updateCommentSuccess,
    updateCommentFailure,
    deleteCommentRequest,
    deleteCommentSuccess,
    deleteCommentFailure,
    getCommentByIdRequest,
    getCommentByIdSuccess,
    getCommentByIdFailure,
} from "redux/reducers/comments";

export function* watchCommentSaga() {
    yield takeLatest(fetchCommentListRequest, fetchCommentList);
    yield takeLatest(createCommentRequest, createComment);
    yield takeLatest(updateCommentRequest, updateComment);
    yield takeLatest(deleteCommentRequest, deleteComment);
    yield takeLatest(getCommentByIdRequest, getCommentById);
}

function* fetchCommentList({ payload }) {
    try {
        const response = yield requestCommentList(payload);
        yield put(fetchCommentListSuccess(response));
    } catch (error) {
        yield put(
            fetchCommentListFailure("Failed to get list of comment")
        );
    }
}

function* createComment({ payload }) {
    try {
        // service add
        const response = yield requestCreateComment(payload);
        yield put(createCommentSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create comment";
        yield put(createCommentFailure(message));
    }
}

function* updateComment({ payload }) {
    try {
        const response = yield requestUpdateComment(payload);
        yield put(updateCommentSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update comment";
        yield put(updateCommentFailure(message));
    }
}

function* deleteComment({ payload }) {
    try {
        const response = yield requestDeleteComment(payload);
        yield put(deleteCommentSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete comment";
        yield put(deleteCommentFailure(message));
    }
}

function* getCommentById({ payload }) {
    try {
        const response = yield requestCommentDataById(payload);
        yield put(getCommentByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get comment details";
        yield put(getCommentByIdFailure(message));
    }
}