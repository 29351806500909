import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    billsList: { loading: false, data: null, error: null },
    createBills: { loading: false, data: null, error: null },
    updateBills: { loading: false, data: null, error: null },
    deleteBills: { loading: false, data: null, error: null },
    getBillsById: { loading: false, data: null, error: null },
    createBillsInvoice: { loading: false, data: null, error: null },
}

export const billsSlice = createSlice({
    name: "bills",
    initialState,
    reducers: {
        fetchBillsListRequest: (bills, action) => {
            bills.billsList.loading = true;
            bills.billsList.error = null;
        },
        fetchBillsListSuccess: (bills, action) => {
            bills.billsList.data = action.payload;
            bills.billsList.loading = false;
            bills.billsList.error = null;
        },
        fetchBillsListFailure: (bills, action) => {
            bills.billsList.data = null;
            bills.billsList.loading = false;
            bills.billsList.error = action.payload;
        },

        //create bills
        createBillsRequest: (bills, action) => {
            bills.createBills.loading = true;
            bills.createBills.error = null;
        },
        createBillsSuccess: (bills, action) => {
            bills.createBills.data = action.payload;
            bills.createBills.loading = false;
            bills.createBills.error = null;
            bills.billsList = addNewBillsSuccess(action.payload, {
                ...bills.billsList,
            })
        },
        createBillsFailure: (bills, action) => {
            bills.createBills.data = null;
            bills.createBills.loading = false;
            bills.createBills.error = action.payload;
        },

        //update bills details
        updateBillsRequest: (bills, action) => {
            bills.updateBills.loading = true;
            bills.updateBills.error = null;
        },
        updateBillsSuccess: (bills, action) => {
            bills.updateBills.data = action.payload;
            bills.updateBills.loading = false;
            bills.updateBills.error = null;
            bills.billsList = updateNewBillsSuccess(
                action.payload,
                bills.billsList
            );
        },
        updateBillsFailure: (bills, action) => {
            bills.updateBills.data = null;
            bills.updateBills.loading = false;
            bills.updateBills.error = action.payload;
        },

        // delete bills
        deleteBillsRequest: (bills, action) => {
            bills.deleteBills.loading = true;
            bills.deleteBills.error = null;
        },
        deleteBillsSuccess: (bills, action) => {
            bills.deleteBills.data = action.payload;
            bills.deleteBills.loading = false;
            bills.deleteBills.error = null;
            bills.billsList = deleteNewBillsSuccess(action.payload, {
                ...bills.billsList,
            })
        },
        deleteBillsFailure: (bills, action) => {
            bills.deleteBills.data = null;
            bills.deleteBills.loading = false;
            bills.deleteBills.error = action.payload;
        },

        // getById bills details
        getBillsByIdRequest: (bills, action) => {
            bills.getBillsById.loading = true;
            bills.getBillsById.error = null;
        },
        getBillsByIdSuccess: (bills, action) => {
            bills.getBillsById.data = action.payload;
            bills.getBillsById.loading = false;
            bills.getBillsById.error = null;
        },
        getBillsByIdFailure: (bills, action) => {
            bills.getBillsById.data = null;
            bills.getBillsById.loading = false;
            bills.getBillsById.error = action.payload;
        },

        //create billsInvoice
        createBillsInvoiceRequest: (bills, action) => {
            bills.createBillsInvoice.loading = true;
            bills.createBillsInvoice.error = null;
        },
        createBillsInvoiceSuccess: (bills, action) => {
            bills.createBillsInvoice.data = action.payload;
            bills.createBillsInvoice.loading = false;
            bills.createBillsInvoice.error = null;
        },
        createBillsInvoiceFailure: (bills, action) => {
            bills.createBillsInvoice.data = null;
            bills.createBillsInvoice.loading = false;
            bills.createBillsInvoice.error = action.payload;
        },

        // reset bills details
        resetBills: (bills, action) => {
            bills.createBills.data = null;
            bills.createBills.loading = false;
            bills.createBills.error = null;

            bills.updateBills.data = null;
            bills.updateBills.loading = false;
            bills.updateBills.error = null;

            bills.deleteBills.data = null;
            bills.deleteBills.loading = false;
            bills.deleteBills.error = null;

            bills.createBillsInvoice.data = null;
            bills.createBillsInvoice.loading = false;
            bills.createBillsInvoice.error = null;
        }
    }
})

function addNewBillsSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewBillsSuccess(dataToUpdate, state) {
    const updatedData = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
}

function deleteNewBillsSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchBillsListRequest,
    fetchBillsListSuccess,
    fetchBillsListFailure,

    createBillsRequest,
    createBillsSuccess,
    createBillsFailure,

    createBillsInvoiceRequest,
    createBillsInvoiceSuccess,
    createBillsInvoiceFailure,

    updateBillsRequest,
    updateBillsSuccess,
    updateBillsFailure,

    deleteBillsRequest,
    deleteBillsSuccess,
    deleteBillsFailure,

    getBillsByIdRequest,
    getBillsByIdSuccess,
    getBillsByIdFailure,

    resetBills,
} = billsSlice.actions;

export const getBillsListState = (store) =>
    store?.bills?.billsList;
export const getBillsCreateState = (store) =>
    store?.bills?.createBills;
export const getBillsUpdateState = (store) =>
    store?.bills?.updateBills;
export const getBillsDeleteState = (store) =>
    store?.bills?.deleteBills;
export const getBillsGetByIdState = (store) =>
    store?.bills?.getBillsById;
export const getBillsInvoiceCreateState = (store) =>
    store?.bills?.createBillsInvoice;
export default billsSlice.reducer;