import { put, takeLatest } from "redux-saga/effects";
import {
  checkMachineAvailabilityFailure,
  checkMachineAvailabilityRequest,
  checkMachineAvailabilitySuccess,
  createAgreementFailure,
  createAgreementRequest,
  createAgreementSuccess,
  fetchAgreementListFailure,
  fetchAgreementListRequest,
  fetchAgreementListSuccess,
  getAgreementDetailsFailure,
  getAgreementDetailsRequest,
  getAgreementDetailsSuccess,
  getAgreementKPIFailure,
  getAgreementKPIRequest,
  getAgreementKPISuccess,
  updateAgreementFailure,
  updateAgreementRequest,
  updateAgreementSuccess,
  createAgreementClientRequest,
  createAgreementClientSuccess,
  createAgreementClientFailure,
} from "redux/reducers/sales-pipeline/agreement";
import {
  requestAgreementDataById,
  requestAgreementKPIData,
  requestAgreementList,
  requestCheckMachineAvailability,
  requestCreateAgreement,
  requestUpdateAgreement,
  requestCreateAgreementClient,
} from "services/sales-pipeline/agreement";

export function* watchAgreementSaga() {
  yield takeLatest(fetchAgreementListRequest, FetchAgreementList);
  yield takeLatest(createAgreementRequest, CreateAgreement);
  yield takeLatest(updateAgreementRequest, UpdateAgreement);
  yield takeLatest(getAgreementDetailsRequest, getAgreementGetByIdData);
  yield takeLatest(checkMachineAvailabilityRequest, CheckMachineAvailability);
  yield takeLatest(getAgreementKPIRequest, agreementKpi);
  yield takeLatest(createAgreementClientRequest, CreateAgreementClient);
}

function* FetchAgreementList({ payload }) {
  try {
    const response = yield requestAgreementList(payload);
    yield put(fetchAgreementListSuccess(response));
  } catch (error) {
    yield put(fetchAgreementListFailure("Failed to get list of agreements"));
  }
}

function* CreateAgreement({ payload }) {
  try {
    const response = yield requestCreateAgreement(payload);
    yield put(createAgreementSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create agreement";
    yield put(createAgreementFailure(message));
  }
}

function* UpdateAgreement({ payload }) {
  try {
    const response = yield requestUpdateAgreement(payload);
    yield put(updateAgreementSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update agreement";
    yield put(updateAgreementFailure(message));
  }
}

function* getAgreementGetByIdData({ payload }) {
  try {
    const response = yield requestAgreementDataById(payload);
    yield put(getAgreementDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to get lead details";
    yield put(getAgreementDetailsFailure(message));
  }
}

function* CheckMachineAvailability({ payload }) {
  try {
    const response = yield requestCheckMachineAvailability(payload);
    yield put(checkMachineAvailabilitySuccess(response.data));
  } catch (error) {
    const message =
      error.response?.data?.data || "Failed to get available machine";
    yield put(checkMachineAvailabilityFailure(message));
  }
}

function* agreementKpi({ payload }) {
  try {
    const response = yield requestAgreementKPIData(payload);
    yield put(getAgreementKPISuccess(response));
  } catch (error) {
    yield put(getAgreementKPIFailure("Failed to get agreement KPI data"));
  }
}

function* CreateAgreementClient({ payload }) {
  try {
    const response = yield requestCreateAgreementClient(payload);
    yield put(createAgreementClientSuccess(response.data));
  } catch (error) {
    const message =
      error.response?.data?.data || "Failed to create agreement client";
    yield put(createAgreementClientFailure(message));
  }
}
