import Loading from "components/shared-components/Loading";
import { PUBLIC_PREFIX_PATH } from "configs/AppConfig";
import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import SubscriptionRequestProcessing from "./subscription-request-processing";
import Proposal from "./proposal";

const PublicSubscriberRequestProcessing = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${PUBLIC_PREFIX_PATH}/businessProposal`}
          component={Proposal}
        />
        <Route
          path={`${PUBLIC_PREFIX_PATH}/subscriber-details`}
          component={SubscriptionRequestProcessing}
        />
      </Switch>
    </Suspense>
  );
};

export default PublicSubscriberRequestProcessing;
