import { put, takeLatest } from "redux-saga/effects";
import {
    requestcreditNoteList,
    requestCreatecreditNote,
    requestUpdatecreditNote,
    requestDeletecreditNote,
    requestcreditNoteDataById,
    requestApprovedcreditNote
} from "services/creditNote";

import {
    fetchcreditNoteListRequest,
    fetchcreditNoteListSuccess,
    fetchcreditNoteListFailure,
    createcreditNoteRequest,
    createcreditNoteSuccess,
    createcreditNoteFailure,
    updatecreditNoteRequest,
    updatecreditNoteSuccess,
    updatecreditNoteFailure,
    deletecreditNoteRequest,
    deletecreditNoteSuccess,
    deletecreditNoteFailure,
    getcreditNoteByIdRequest,
    getcreditNoteByIdSuccess,
    getcreditNoteByIdFailure,
    approvedcreditNoteRequest,
    approvedcreditNoteSuccess,
    approvedcreditNoteFailure
} from "redux/reducers/creditNote";

export function* watchcreditNoteSaga() {
    yield takeLatest(fetchcreditNoteListRequest, fetchcreditNoteList);
    yield takeLatest(createcreditNoteRequest, createcreditNote);
    yield takeLatest(updatecreditNoteRequest, updatecreditNote);
    yield takeLatest(deletecreditNoteRequest, deletecreditNote);
    yield takeLatest(getcreditNoteByIdRequest, getcreditNoteById);
    yield takeLatest(approvedcreditNoteRequest, approvedcreditNote);
}

function* fetchcreditNoteList({ payload }) {
    try {
        const response = yield requestcreditNoteList(payload);
        yield put(fetchcreditNoteListSuccess(response));
    } catch (error) {
        yield put(
            fetchcreditNoteListFailure("Failed to get list of create credit note")
        );
    }
}

function* createcreditNote({ payload }) {
    try {
        // service add
        const response = yield requestCreatecreditNote(payload);
        yield put(createcreditNoteSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create credit note";
        yield put(createcreditNoteFailure(message));
    }
}

function* updatecreditNote({ payload }) {
    try {
        const response = yield requestUpdatecreditNote(payload);
        yield put(updatecreditNoteSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to approve or reject credit note";
        yield put(approvedcreditNoteFailure(message));
    }
}

function* approvedcreditNote({ payload }) {
    try {
        const response = yield requestApprovedcreditNote(payload);
        yield put(approvedcreditNoteSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update credit note";
        yield put(updatecreditNoteFailure(message));
    }
}

function* deletecreditNote({ payload }) {
    try {
        const response = yield requestDeletecreditNote(payload);
        yield put(deletecreditNoteSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete credit note";
        yield put(deletecreditNoteFailure(message));
    }
}

function* getcreditNoteById({ payload }) {
    try {
        const response = yield requestcreditNoteDataById(payload);
        yield put(getcreditNoteByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get credit note details";
        yield put(getcreditNoteByIdFailure(message));
    }
}