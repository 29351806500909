import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sparePartList: { loading: false, data: null, error: null },
  createSparePart: { loading: false, data: null, error: null },
  updateSparePart: { loading: false, data: null, error: null },
  deleteSparePart: { loading: false, data: null, error: null },
  getSparePartById: { loading: false, data: null, error: null },
  associationOfMachinePartList: { loading: false, data: null, error: null },
  createTransaction: { loading: false, data: null, error: null },
  sparePartDropdownList: { loading: false, data: null, error: null },
};

export const ManageSparePart = createSlice({
  name: "manageSparePart",
  initialState,
  reducers: {
    fetchSparePartListRequest: (manageSparePart, action) => {
      manageSparePart.sparePartList.loading = true;
      manageSparePart.sparePartList.error = null;
    },
    fetchSparePartListSuccess: (manageSparePart, action) => {
      manageSparePart.sparePartList.data = action.payload;
      manageSparePart.sparePartList.loading = false;
      manageSparePart.sparePartList.error = null;
    },
    fetchSparePartListFailure: (manageSparePart, action) => {
      manageSparePart.sparePartList.data = null;
      manageSparePart.sparePartList.loading = false;
      manageSparePart.sparePartList.error = action.payload;
    },

    // create manageSparePart
    createSparePartRequest: (manageSparePart, action) => {
      manageSparePart.createSparePart.loading = true;
      manageSparePart.createSparePart.error = null;
    },
    createSparePartSuccess: (manageSparePart, action) => {
      manageSparePart.createSparePart.data = action.payload;
      manageSparePart.createSparePart.loading = false;
      manageSparePart.createSparePart.error = null;
      manageSparePart.sparePartList = addNewSparePartSuccessSuccess(
        action.payload,
        {
          ...manageSparePart.sparePartList,
        }
      );
    },
    createSparePartFailure: (manageSparePart, action) => {
      manageSparePart.createSparePart.data = null;
      manageSparePart.createSparePart.loading = false;
      manageSparePart.createSparePart.error = action.payload;
    },

    // update manageSparePart details
    updateSparePartRequest: (manageSparePart, action) => {
      manageSparePart.updateSparePart.loading = true;
      manageSparePart.updateSparePart.error = null;
    },
    updateSparePartSuccess: (manageSparePart, action) => {
      manageSparePart.updateSparePart.data = action.payload;
      manageSparePart.updateSparePart.loading = false;
      manageSparePart.updateSparePart.error = null;
      manageSparePart.sparePartList = updateFunctionSparePartSuccess(
        action.payload,
        manageSparePart.sparePartList
      );
    },
    updateSparePartFailure: (manageSparePart, action) => {
      manageSparePart.updateSparePart.data = null;
      manageSparePart.updateSparePart.loading = false;
      manageSparePart.updateSparePart.error = action.payload;
    },

    // delete manageSparePart
    deleteSparePartRequest: (manageSparePart, action) => {
      manageSparePart.deleteSparePart.loading = true;
      manageSparePart.deleteSparePart.error = null;
    },
    deleteSparePartSuccess: (manageSparePart, action) => {
      manageSparePart.deleteSparePart.loading = false;
      manageSparePart.deleteSparePart.error = null;
      manageSparePart.deleteSparePart.data = action.payload;
      manageSparePart.sparePartList = deleteSparePart(action.payload, {
        ...manageSparePart.sparePartList,
      });
    },
    deleteSparePartFailure: (manageSparePart, action) => {
      manageSparePart.deleteSparePart.data = null;
      manageSparePart.deleteSparePart.loading = false;
      manageSparePart.deleteSparePart.error = action.payload;
    },

    // get by id manageSparePart details
    getSparePartDetailsRequest: (manageSparePart, action) => {
      manageSparePart.getSparePartById.loading = true;
      manageSparePart.getSparePartById.error = null;
    },
    getSparePartDetailsSuccess: (manageSparePart, action) => {
      manageSparePart.getSparePartById.loading = false;
      manageSparePart.getSparePartById.error = null;
      manageSparePart.getSparePartById.data = action.payload;
    },
    getSparePartDetailsFailure: (manageSparePart, action) => {
      manageSparePart.getSparePartById.data = null;
      manageSparePart.getSparePartById.loading = false;
      manageSparePart.getSparePartById.error = action.payload;
    },

    // get by id association of machine part details
    getAssociationOfMachinePartDetailsRequest: (manageSparePart, action) => {
      manageSparePart.associationOfMachinePartList.loading = true;
      manageSparePart.associationOfMachinePartList.error = null;
    },
    getAssociationOfMachinePartDetailsSuccess: (manageSparePart, action) => {
      manageSparePart.associationOfMachinePartList.loading = false;
      manageSparePart.associationOfMachinePartList.error = null;
      manageSparePart.associationOfMachinePartList.data = action.payload;
    },
    getAssociationOfMachinePartDetailsFailure: (manageSparePart, action) => {
      manageSparePart.associationOfMachinePartList.data = null;
      manageSparePart.associationOfMachinePartList.loading = false;
      manageSparePart.associationOfMachinePartList.error = action.payload;
    },

    // sparePart Dropdown List
    fetchSparePartDropdownListRequest: (manageSparePart, action) => {
      manageSparePart.sparePartDropdownList.loading = true;
      manageSparePart.sparePartDropdownList.error = null;
    },
    fetchSparePartDropdownListSuccess: (manageSparePart, action) => {
      manageSparePart.sparePartDropdownList.data = action.payload;
      manageSparePart.sparePartDropdownList.loading = false;
      manageSparePart.sparePartDropdownList.error = null;
    },
    fetchSparePartDropdownListFailure: (manageSparePart, action) => {
      manageSparePart.sparePartDropdownList.data = null;
      manageSparePart.sparePartDropdownList.loading = false;
      manageSparePart.sparePartDropdownList.error = action.payload;
    },

    //Reset manageSparePart state
    resetSparePart: (manageSparePart, action) => {
      manageSparePart.createSparePart.data = null;
      manageSparePart.createSparePart.loading = false;
      manageSparePart.createSparePart.error = null;

      manageSparePart.updateSparePart.data = null;
      manageSparePart.updateSparePart.loading = false;
      manageSparePart.updateSparePart.error = null;

      manageSparePart.deleteSparePart.data = null;
      manageSparePart.deleteSparePart.loading = false;
      manageSparePart.deleteSparePart.error = null;

      manageSparePart.createTransaction.data = null;
      manageSparePart.createTransaction.loading = false;
      manageSparePart.createTransaction.error = null;
    },

    //Reset manageSparePart list state
    resetSparePartList: (manageSparePart, action) => {
      manageSparePart.sparePartList.data = null;
      manageSparePart.sparePartList.loading = false;
      manageSparePart.sparePartList.error = null;
    },

    createTransactionRequest: (manageSparePart, action) => {
      manageSparePart.createTransaction.loading = true;
      manageSparePart.createTransaction.error = null;
    },
    createTransactionSuccess: (manageSparePart, action) => {
      manageSparePart.createTransaction.loading = false;
      manageSparePart.createTransaction.error = null;
      manageSparePart.createTransaction.data = action.payload;
      manageSparePart.sparePartList = updateFunctionSparePartSuccess(
        action.payload,
        manageSparePart.sparePartList
      );
    },
    createTransactionFaliure: (manageSparePart, action) => {
      manageSparePart.createTransaction.data = null;
      manageSparePart.createTransaction.loading = false;
      manageSparePart.createTransaction.error = action.payload;
    },
  },
});

// addSparePart function
function addNewSparePartSuccessSuccess(dataToAdd, state) {
  if (state.data?.data.length) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
  } else {
    return state;
  }
}

// updateSparePart function
function updateFunctionSparePartSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// deleteSparePart function
function deleteSparePart(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchSparePartListRequest,
  fetchSparePartListSuccess,
  fetchSparePartListFailure,

  createSparePartRequest,
  createSparePartSuccess,
  createSparePartFailure,

  updateSparePartRequest,
  updateSparePartSuccess,
  updateSparePartFailure,

  deleteSparePartRequest,
  deleteSparePartSuccess,
  deleteSparePartFailure,

  getSparePartDetailsRequest,
  getSparePartDetailsSuccess,
  getSparePartDetailsFailure,

  getAssociationOfMachinePartDetailsRequest,
  getAssociationOfMachinePartDetailsSuccess,
  getAssociationOfMachinePartDetailsFailure,

  fetchSparePartDropdownListRequest,
  fetchSparePartDropdownListSuccess,
  fetchSparePartDropdownListFailure,

  resetSparePart,
  resetSparePartList,

  createTransactionRequest,
  createTransactionSuccess,
  createTransactionFaliure,
} = ManageSparePart.actions;

export const getSparePartListState = (store) =>
  store?.manageSparePart?.sparePartList;
export const getSparePartCreateState = (store) =>
  store?.manageSparePart?.createSparePart;
export const getUpdateSparePartState = (store) =>
  store?.manageSparePart?.updateSparePart;
export const getSparePartDeleteState = (store) =>
  store?.manageSparePart?.deleteSparePart;
export const getSparePartGetByIdState = (store) =>
  store?.manageSparePart?.getSparePartById;
export const getAssociationOfMachinePartListState = (store) =>
  store?.manageSparePart?.associationOfMachinePartList;
export const getSparePartDropdownListState = (store) =>
  store?.manageSparePart?.sparePartDropdownList;
export default ManageSparePart.reducer;
