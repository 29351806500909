import { Button, Form, notification } from "antd";
import TextEditor from "components/shared-components/TextEditor";
import { useEffect, useState } from "react";
import { requestSubscriptionTermsAndConditionList } from "services/subscription-request";

export function PrivacyPolicy({ showFooterButton, submitFormPrivacyPolicy }) {
  const LAYOUT = {
    labelCol: { span: 12 },
    wrapperCol: { span: 22 },
  };

  const [rowValue, setRowValue] = useState({});

  useEffect(() => {
    (async function () {
      try {
        const response = await requestSubscriptionTermsAndConditionList();
        setRowValue(response?.data?.privacyPolicyData);
      } catch (error) {
        console.log(error);
        notification.error({
          message: `${error}`,
        });
      }
    })();
  }, []);

  return (
    <>
      <div>
        <Form {...LAYOUT} layout="vertical">
          <div className="ant-col-sm-24">
            <TextEditor
              toolbarHidden={true}
              readOnly={true}
              initialValue={rowValue?.termsAndConditions || "NO DATA FOUND"}
            />
          </div>

          {/* form footer button */}
          {showFooterButton ? (
            <div
              style={{
                marginTop: "15px",
                textAlign: "right",
              }}
            >
              <Button
                danger
                style={{
                  marginRight: "15px",
                }}
                onClick={() => {
                  submitFormPrivacyPolicy(false);
                }}
                disabled={rowValue?.termsAndConditions ? false : true}
              >
                Decline
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  submitFormPrivacyPolicy(rowValue?._id);
                }}
                disabled={rowValue?.termsAndConditions ? false : true}
              >
                Accept
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </>
  );
}
export default PrivacyPolicy;
