import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Row,
  Col,
  notification,
  Popconfirm,
  Modal,
} from "antd";
import moment from "moment";
import fetch from "auth/FetchInterceptor";
import {
  requestAcceptProposal,
  requestProposalPDF,
  requestPublicProposalDetails,
  requestUpdateViewTime,
} from "services/sales-pipeline/proposal";
import Flex from "components/shared-components/Flex";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FloatInput from "components/shared-components/MaterialFormUI/FloatInput";
import imageX from "../../../assets/1.jpeg";
import { API_BASE_URL } from "configs/AppConfig";
let rowdata;
let startTime;
function Proposal() {
  const [rowValue, setRowvalue] = useState({});
  const [value, setValue] = useState("");
  const [modalValue, setModalValue] = useState(false);
  useEffect(() => {
    //get url search parmas
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const status = queryParams.get("acbS");
    const proposal = queryParams.get("zyxP");
    localStorage.setItem("tn_ah", token);
    window.addEventListener("scroll", debounce(handleScroll, 3000));
    startTime = new Date().getTime();
    (async function () {
      //get data from url search params
      const response = await requestPublicProposalDetails({
        id: proposal,
        status: status === "Sent(Draft)" ? "View" : status,
      });
      rowdata = response.data;
      setRowvalue(response.data);
    })();
  }, []);

  //identify active tab
  //unmount case update view time
  function debounce(func, wait) {
    let timeout;

    return function executedFunction() {
      const context = this;
      const args = arguments;

      const later = function () {
        timeout = null;
        func.apply(context, args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  function handleScroll() {
    // Do something when the user scrolls
    //end time
    const currentTime = new Date().getTime();
    const timeSpent = currentTime - startTime;
    updateTime(parseInt(timeSpent / 1000));
  }

  const updateTime = async (time) => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const proposal = queryParams.get("zyxP");

      let req = {
        time: time,
        _id: proposal,
        viewsId: rowdata?.totalViewTime[rowdata?.totalViewTime?.length - 1]?.id,
      };
      startTime = new Date().getTime();
      await requestUpdateViewTime(req);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdf = (link, filename) => {
    notification.success({
      message: "Downloading requested file",
    });
    let finalData = {
      path: link,
    };
    fetch
      .post(`${API_BASE_URL}/api/app/file-upload/downloadFile`, finalData)
      .then((response) => {
        // encode Base64 to PDF file
        let base64FileData = `data:application/pdf;base64,${response.data}`;

        const linkSource = `${base64FileData}`;
        const downloadLink = document.createElement("a");
        const fileName = filename || "Download.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((error) => {
        console.error("Error:", error);
        notification.error({
          message:
            "Something went wrong while downloading file. Please try again! ",
        });
      });
  };

  const menu1 = (
    <Menu>
      {rowValue?.attachementDocument?.map((e, i) => {
        return (
          <Menu.Item
            key={i}
            onClick={() => {
              downloadPdf(e?.url, e?.name);
            }}
          >
            {e?.name}
          </Menu.Item>
        );
      })}
      <Menu.Divider />
      <Menu.Item
        onClick={async () => {
          try {
            //get url search parmas
            const queryParams = new URLSearchParams(window.location.search);
            const response = await requestProposalPDF({
              id: queryParams.get("zyxP"),
            });
            const linkSource = `data:application/pdf;base64,${response.data}`;
            const downloadLink = document.createElement("a");
            const fileName =
              `Proposal-${rowValue?.alternateId}.pdf` || "Download.pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          } catch (e) {}
        }}
      >
        ServiceExpertProposal.pdf
      </Menu.Item>
    </Menu>
  );

  const handleUpdateStausProposal = async (req, resetForm) => {
    // API call
    //accept/decilne  service call
    try {
      const res = await requestAcceptProposal(req);
      setRowvalue(res.data);
      setModalValue(false);
      resetForm();
      //sucess notifications
      if (value === "Accepted") {
        notification.success({ message: "Proposal Accepted Successfully" });
      } else {
        notification.warn({ message: "Proposal Rejected" });
      }
    } catch (error) {
      notification.error({ message: "Failed to Update" });
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-between"
        style={{
          height: "80px",
          borderBottom: "1px solid rgba(0, 0, 0, .15)",
        }}
      >
        <div
          style={{
            width: "250px",
            marginTop: "22px",
            marginLeft: "17px",
          }}
        >
          <img src="/img/jetatechlogo.png" alt="JETATECh logo" />
        </div>
        <div className="d-flex float-right">
          {/* <div className="mt-4 ml-4">
            {" "}
            <Button className=" mr-2" onClick={() => {}} type="primary" ghost>
              Share
            </Button>
          </div> */}
          <div className="mt-4 ml-4">
            <Dropdown overlay={menu1} placement="topCenter">
              <Button className=" mr-2" type="primary" ghost>
                Download Files
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <Row className=" g-0">
        <Col sx={24} sm={24} md={4} lg={4}>
          {/* keep this for further ref */}
          <div className="mt-4">
            <Menu mode="inline">
              <hr />
              <Menu.Item key={0}>
                <span>
                  {" "}
                  <a onClick={(p) => {}}>Rental Proposal</a>
                </span>
              </Menu.Item>
              <hr />
              {/* {[{ name: "proposal.docx" }]?.map((e, i) => {
                return (
                  <>
                    <Menu.Item key={i + 1}>
                      <span>
                        {" "}
                        <a onClick={(p) => {}}>{e?.name}</a>
                      </span>
                    </Menu.Item>
                    <hr />
                  </>
                );
              })}
              {[{ name: "proposal-layout.docx" }]?.map((e, i) => {
                return (
                  <>
                    <Menu.Item key={i + rowValue?.addLayout?.length + 1}>
                      <span>
                        {" "}
                        <a onClick={(p) => {}}>{e?.name}</a>
                      </span>
                    </Menu.Item>
                    <hr />
                  </>
                );
              })} */}
            </Menu>
          </div>
        </Col>
        <>
          <Col id="businessProposal" sx={24} sm={24} md={20} lg={20}>
            <Row className="border-left g-0">
              <Col sx={24} sm={24} md={24} lg={24}>
                <div
                  style={{
                    paddingLeft: "0.8rem",
                    marginRight: "0.8rem",
                    color: "#222631",
                  }}
                >
                  <div className="d-sm-flex justify-content-sm-between mb-4">
                    <div className="mt-5 pl-2">
                      <div
                        className="row"
                        style={{
                          color: " #222631",
                          fontWeight: "600",
                          fontSize: "20px",
                        }}
                      >
                        <span>Head Terms-Commercial Proposal</span>
                      </div>
                      <div
                        className="ant-row mb-1"
                        style={{
                          color: " #222631",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        - {rowValue?.proposalName}
                      </div>
                      <div style={{ borderBottom: "3px solid #deb509" }}></div>
                    </div>
                  </div>
                </div>
                {[
                  {
                    name: "Machine Name",
                    value:
                      rowValue?.machineType?.machineName +
                        rowValue?.machineType?.alternateId || " ",
                  },
                  {
                    name: "Contract Term",
                    value: rowValue?.contractTerm + " Months" || " ",
                  },
                  {
                    name: "Lock-in Period",
                    value: rowValue?.lockInPeriod + " Months" || " ",
                  },
                  {
                    name: "Notice Period",
                    value:
                      rowValue?.noticePeriod +
                        " Months post contract locking" || " ",
                  },
                  {
                    name: "Monthly Rental",
                    value: rowValue?.monthlyRental || " ",
                    isRupee: true,
                  },
                  {
                    isRupee: true,
                    name: "Security Deposit",
                    value:
                      rowValue?.securityDeposit * rowValue?.monthlyRental ||
                      " ",
                  },
                  {
                    name: "Escalation",
                    value: rowValue?.escalation + "% year on year" || " ",
                  },
                ].map((data, index) => {
                  return index % 2 === 0 ? (
                    <div>
                      {data.value !== " " &&
                      String(data?.value)?.indexOf("null") === -1 &&
                      String(data?.value)?.indexOf("undefined") === -1 ? (
                        <div className="ant-row">
                          <div
                            className="ant-col-lg-12"
                            style={{
                              backgroundColor: "#cfd6e6",
                              padding: "15px",
                              borderRight: "1px solid #ffff",
                            }}
                          >
                            {data.name}
                          </div>
                          <div
                            className="ant-col-lg-12"
                            style={{
                              backgroundColor: "#cfd6e6",
                              padding: "15px",
                            }}
                          >
                            {data.isRupee ? <span>&#8377;</span> : ""}{" "}
                            {data.value}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      {data.value !== " " &&
                      String(data?.value)?.indexOf("null") === -1 &&
                      String(data?.value)?.indexOf("undefined") ? (
                        <div className="ant-row">
                          <div
                            className="ant-col-lg-12"
                            style={{
                              backgroundColor: "#e9eef4",
                              padding: "15px",
                              borderRight: "1px solid #ffff",
                            }}
                          >
                            {data.name}
                          </div>
                          <div
                            className="ant-col-lg-12"
                            style={{
                              backgroundColor: "#e9eef4",
                              padding: "15px",
                            }}
                          >
                            {data.isRupee ? <span>&#8377;</span> : ""}{" "}
                            {data.value}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </Col>

              <div className="d-flex justify-content-sm-between mt-4 mb-4 pb-4">
                <div>Proposal Owner :{rowValue?.owner?.name}</div>
                <br />
                {/* {rowValue?.client?.firstName ? (
                  <div>
                    {rowValue?.client?.firstName +
                      " " +
                      rowValue?.client?.lastName}
                  </div>
                ) : (
                  <div>
                    {rowValue?.lead?.firstName + " " + rowValue?.lead?.lastName}
                  </div>
                )} */}
              </div>
            </Row>
          </Col>
        </>
      </Row>

      {["Won", "Deal"].includes(rowValue?.status) ? (
        <Flex justifyContent="end" alignItems="center" className="p-3 ">
          <Button type="primary" disabled>
            Accepted
          </Button>
        </Flex>
      ) : moment().diff(rowValue?.proposalValidity) > 0 ? (
        <Flex justifyContent="center" alignItems="center" className="p-3 ">
          Proposal Validatiy Expired on &nbsp; &nbsp;
          {moment(rowValue?.proposalValidity).format("DD/MM/YYYY")}
        </Flex>
      ) : ["Sent(Final)"].includes(rowValue?.status) ? (
        <Flex justifyContent="end" alignItems="start" className="p-3">
          <Popconfirm
            title="Are you sure to Reject this Proposal?"
            description="Are you sure to Reject this Proposal?"
            onConfirm={() => {
              setValue("Rejected");
              setModalValue(true);
            }}
            okText="Yes"
            cancelText="Close"
            placement="leftTop"
          >
            {" "}
            <Button danger type="ghost">
              Reject
            </Button>
          </Popconfirm>{" "}
          &nbsp;&nbsp;
          <Popconfirm
            title="Accept this Proposal?"
            description="Accept this Proposal?"
            onConfirm={() => {
              setValue("Accepted");
              setModalValue(true);
            }}
            okText="Yes"
            cancelText="Close"
            placement="leftTop"
          >
            {" "}
            <Button type="primary">Accept</Button>
          </Popconfirm>
        </Flex>
      ) : (
        <></>
      )}

      {/* accept decline modal to get notes */}
      <Modal
        title={value === "Accepted" ? "Accept Proposal" : "Reject Proposal"}
        visible={modalValue}
        onCancel={() => {
          setModalValue(false);
        }}
        footer={null}
      >
        <Formik
          initialValues={{ notes: "" }}
          validationSchema={Yup.object().shape({
            notes: Yup.string().required("Required"),
          })}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            const queryParams = new URLSearchParams(window.location.search);
            const proposal = queryParams.get("zyxP");
            let req = { id: proposal };
            if (value === "Accepted") {
              req.acceptNote = values.notes;
              req.type = "Won";
            } else {
              req.declineNote = values.notes;
              req.type = "Lost";
            }
            handleUpdateStausProposal(req, resetForm);
          }}
        >
          {({ resetForm, values, errors, touched }) => {
            return (
              <Form>
                <Row gutter={[16, 16]} className="mt-2 mb-3">
                  <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                    {" "}
                    <FloatInput
                      label={
                        value === "Accepted" ? "Accept Notes" : "Reject Notes"
                      }
                      errors={errors.notes}
                      touched={touched.notes}
                      values={values.notes}
                      name="notes"
                      placeholder={"Enter Notes"}
                      required={true}
                    />
                  </Col>
                </Row>
                <Flex justifyContent="end">
                  <Button
                    type="ghost"
                    onClick={() => {
                      setModalValue(false); //modal close
                      resetForm();
                    }}
                  >
                    Close
                  </Button>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="primary"
                    danger={value === "Accepted" ? false : true}
                    htmlType="submit"
                  >
                    {value === "Accepted"
                      ? "Accept Proposal"
                      : "Reject Proposal"}
                  </Button>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
export default Proposal;

// show document direct if business proposal is false
