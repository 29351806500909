import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestOperativeTicketList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/svc-ticket`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/svc-ticket?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    }
}

export function requestCreateOperativeTicket(data) {
    return fetch.post(`${BASE_URL}/api/app/svc-ticket`, data);
}

export function requestUpdateOperativeTicket(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/svc-ticket/${data._id}`, body);
}

export function requestDeleteOperativeTicket(data) {
    return fetch.delete(`${BASE_URL}/api/app/svc-ticket/${data}`);
}

export function requestOperativeTicketDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/svc-ticket/getById/${data.id}`);
}
export function requestGeneratepdf(data) {
    return fetch.post(`${BASE_URL}/api/app/svc-ticket/generatePdf/${data._id}`);
}

