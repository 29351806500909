import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestGenerateEInvoiceList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/invoice`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/invoice?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    }
}

export function requestCreateGenerateEInvoice(data) {
    return fetch.post(`${BASE_URL}/api/app/invoice/generate-e-Invoice`, data);
}

export function requestUpdateGenerateEInvoice(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/invoice/${data._id}`, body);
}

export function requestDeleteGenerateEInvoice(data) {
    return fetch.delete(`${BASE_URL}/api/app/invoice/${data}`);
}

export function requestGenerateEInvoiceDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/invoice/getById/${data.id}`);
}

