import { all } from "redux-saga/effects";
import Auth from "./Auth";
import { watchmanageRoleSaga } from "./manageRole";
import { watchManageUserSaga } from "./manageUser";
import { watchFeatureSaga } from "./feature";
import { watchPermissionSaga } from "./permissions";
import { watchSideMenuSaga } from "./getSideMenu";
import { watchPrivilegeSaga } from "./privilege";
import { watchSensorProductSaga } from "./sensorProduct";
import { watchSensorInstanceSaga } from "./sensorInstance";
import { watchSensorDataSaga } from "./sensorData";
import { watchSensorAnalyticsSaga } from "./sensorAnalytics";
import { watchClientSaga } from "./client";
import { watchTicketSaga } from "./ticket";
import { watchMasterConfigSaga } from "./masterConfig";
import { watchManageSubscribersSaga } from "./manageSubscriber";
import { watchServiceProviderSaga } from "./serviceProvider";
import { watchDocumentSaga } from "./document";
import { watchFeActivitySaga } from "./feActivity";
import { watchQuestionsSaga } from "./questions";
import { watchFireExtinguisherSaga } from "./fireExtinguisher";
import { watchLocationSaga } from "./location";
import { watchOrganizationSaga } from "./organization";
import { watchAssetTypeSaga } from "./assetType";
import { watchPlantSaga } from "./plant";
import { watchUnitSaga } from "./unit";
import { watchManageSiteSaga } from "./siteMange";
import { watchAssetSaga } from "./asset";
import { watchSystemTicketSaga } from "./systemTIcket";
import { watchSensorInstanceUnitSaga } from "./sensorInstanceUnit";
import { watchPreventiveMaintenanceSaga } from "./preventiveMaintenance";
import { watchPreSaleMaintenenceSaga } from "./preSaleMaintenence";
import { watchMachineSaga } from "./machine";
import { watchSparePartsSaga } from "./spareParts";
import { watchChecklistSaga } from "./checklist";
import { watchActionNoteSaga } from "./actionNote";
import { watchExpenseSaga } from "./expense";
import { watchSvcMachineTypeSaga } from "./svcMachineType";
import { watchSvcMachineInstanceSaga } from "./svcMachineInstance";
import { watchSparePartSaga } from "./manageSparePart";
import { watchDiscussionSaga } from "./discussion";
import { watchBreakdownMaintenanceSaga } from "./breakdownMaintenance";
import { watchInstallationCommissioningSaga } from "./installationCommissioning";
import { watchSvcExpenseSaga } from "./svcExpenseList";
import { watchFileUploadSaga } from "./fileUpload";
import { watchActionTicketSaga } from "./actionTicket";
import { watchMaintenanceClientSaga } from "./maintenanceClient";
import { watchCommentSaga } from "./comments";
import { watchRequestedSparePartSaga } from "./requestedSparePart";
import { watchWorkLogSaga } from "./workLog";
import { watchFeedBackSaga } from "./feedBack";
import { watchResourceViewSaga } from "./resourceView";
import { watchBillsSaga } from "./bills";
import rootSagaFe from "./fe";
import rootSagaSalesPipeline from "./sales-pipeline";
import { watchBillsInvoiceSaga } from "./billsInvoice";
import { watchGenerateInvoiceSaga } from "./generateInvoice";
import { watchSubscriptionRequestSaga } from "./subscription-request";
import { watchGenerateEInvoiceSaga } from "./generateEInvoice";
import { watchInvoiceItemMasterSaga } from "./invoiceItemMaster";
import { watchFinalInvoiceSaga } from "./finalInvoice";
import { watchcreditNoteSaga } from "./creditNote";
import { watchClientMasterSaga } from "./clientMaster";
import { watchClientSpocSaga } from "./clientSpoc";
import { watchManualPaymentSaga } from "./payments/manual-payment";
import { watchInvoiceItemMasterScreenSaga } from "./invoiceItemMasterScreen";
import { watchSupplierSaga } from "./supplier";
import { watchDraftPOSaga } from "./manage-po";
import { watchRequisitionSaga } from "./manage-requisition";
import { watchOrganizationScreenSaga } from "./organizationScreen";
import { watchCheckListViewSaga } from "./checkListView";
import { watchOperativeTicketSaga } from "./operativeTicket";

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    watchmanageRoleSaga(),
    watchManageUserSaga(),
    watchFeatureSaga(),
    watchPermissionSaga(),
    watchSideMenuSaga(),
    watchPrivilegeSaga(),
    watchSensorProductSaga(),
    watchSensorInstanceSaga(),
    watchSensorDataSaga(),
    watchSensorAnalyticsSaga(),
    watchClientSaga(),
    watchTicketSaga(),
    watchMasterConfigSaga(),
    watchManageSubscribersSaga(),
    watchServiceProviderSaga(),
    watchDocumentSaga(),
    watchFeActivitySaga(),
    watchQuestionsSaga(),
    watchFireExtinguisherSaga(),
    watchLocationSaga(),
    watchOrganizationSaga(),
    watchAssetTypeSaga(),
    watchPlantSaga(),
    watchUnitSaga(),
    watchManageSiteSaga(),
    watchAssetSaga(),
    watchSystemTicketSaga(),
    watchSensorInstanceUnitSaga(),
    watchPreventiveMaintenanceSaga(),
    watchPreSaleMaintenenceSaga(),
    watchMachineSaga(),
    watchSparePartsSaga(),
    watchChecklistSaga(),
    watchActionNoteSaga(),
    watchExpenseSaga(),
    watchSvcMachineTypeSaga(),
    watchSvcMachineInstanceSaga(),
    watchSparePartSaga(),
    watchDiscussionSaga(),
    watchBreakdownMaintenanceSaga(),
    watchInstallationCommissioningSaga(),
    watchSvcExpenseSaga(),
    watchFileUploadSaga(),
    watchActionTicketSaga(),
    watchMaintenanceClientSaga(),
    watchCommentSaga(),
    watchRequestedSparePartSaga(),
    watchWorkLogSaga(),
    watchFeedBackSaga(),
    watchResourceViewSaga(),
    watchSubscriptionRequestSaga(),
    watchBillsSaga(),
    watchGenerateInvoiceSaga(),
    watchGenerateEInvoiceSaga(),
    watchInvoiceItemMasterSaga(),
    watchFinalInvoiceSaga(),
    watchClientMasterSaga(),
    ...rootSagaFe(),
    ...rootSagaSalesPipeline(),
    watchBillsInvoiceSaga(),
    watchcreditNoteSaga(),
    watchClientSpocSaga(),
    watchManualPaymentSaga(),
    watchInvoiceItemMasterScreenSaga(),
    watchSupplierSaga(),
    watchDraftPOSaga(),
    watchRequisitionSaga(),
    watchOrganizationScreenSaga(),
    watchCheckListViewSaga(),
    watchOperativeTicketSaga()
  ]);
}
