import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  feActivityList: { loading: false, data: null, error: null },
  createFeActivity: { loading: false, data: null, error: null },
  updateFeActivity: { loading: false, data: null, error: null },
  deleteFeActivity: { loading: false, data: null, error: null },
  getFeActivityById: { loading: false, data: null, error: null },
};

export const feActivitySlice = createSlice({
  name: "feActivity",
  initialState,
  reducers: {
    fetchFeActivityListRequest: (feActivity, action) => {
      feActivity.feActivityList.loading = true;
      feActivity.feActivityList.error = null;
    },
    fetchFeActivityListSuccess: (feActivity, action) => {
      feActivity.feActivityList.data = action.payload;
      feActivity.feActivityList.loading = false;
      feActivity.feActivityList.error = null;
    },
    fetchFeActivityListFailure: (feActivity, action) => {
      feActivity.feActivityList.data = null;
      feActivity.feActivityList.loading = false;
      feActivity.feActivityList.error = action.payload;
    },

    //create FeActivity
    createFeActivityRequest: (feActivity, action) => {
      feActivity.createFeActivity.loading = true;
      feActivity.createFeActivity.error = null;
    },
    createFeActivitySuccess: (feActivity, action) => {
      feActivity.createFeActivity.data = action.payload;
      feActivity.createFeActivity.loading = false;
      feActivity.createFeActivity.error = null;
      feActivity.feActivityList = addNewFeActivitySuccess(action.payload, {
        ...feActivity.feActivityList,
      });
    },
    createFeActivityFailure: (feActivity, action) => {
      feActivity.createFeActivity.data = null;
      feActivity.createFeActivity.loading = false;
      feActivity.createFeActivity.error = action.payload;
    },

    // update FeActivity details
    updateFeActivityRequest: (feActivity, action) => {
      feActivity.updateFeActivity.loading = true;
      feActivity.updateFeActivity.error = null;
    },
    updateFeActivitySuccess: (feActivity, action) => {
      feActivity.updateFeActivity.data = action.payload;
      feActivity.updateFeActivity.loading = false;
      feActivity.updateFeActivity.error = null;
      feActivity.feActivityList = updateFeActivitySuccessFun(
        action.payload,
        feActivity.feActivityList
      );
    },
    updateFeActivityFailure: (feActivity, action) => {
      feActivity.updateFeActivity.data = null;
      feActivity.updateFeActivity.loading = false;
      feActivity.updateFeActivity.error = action.payload;
    },

    // delete FeActivity
    deleteFeActivityRequest: (feActivity, action) => {
      feActivity.deleteFeActivity.loading = true;
      feActivity.deleteFeActivity.error = null;
    },
    deleteFeActivitySuccess: (feActivity, action) => {
      feActivity.deleteFeActivity.data = action.payload;
      feActivity.deleteFeActivity.loading = false;
      feActivity.deleteFeActivity.error = null;
      feActivity.feActivityList = deleteNewFeActivitySuccess(action.payload, {
        ...feActivity.feActivityList,
      });
    },
    deleteFeActivityFailure: (feActivity, action) => {
      feActivity.deleteFeActivity.data = null;
      feActivity.deleteFeActivity.loading = false;
      feActivity.deleteFeActivity.error = action.payload;
    },

    // getById feActivity details
    getFeActivityByIdRequest: (feActivity, action) => {
      feActivity.getFeActivityById.loading = true;
      feActivity.getFeActivityById.error = null;
    },
    getFeActivityByIdSuccess: (feActivity, action) => {
      feActivity.getFeActivityById.data = action.payload;
      feActivity.getFeActivityById.loading = false;
      feActivity.getFeActivityById.error = null;
    },
    getFeActivityByIdFailure: (feActivity, action) => {
      feActivity.getFeActivityById.data = null;
      feActivity.getFeActivityById.loading = false;
      feActivity.getFeActivityById.error = action.payload;
    },

    // reset feActivity details
    resetFeActivity: (feActivity, action) => {
      feActivity.createFeActivity.data = null;
      feActivity.createFeActivity.loading = false;
      feActivity.createFeActivity.error = null;

      feActivity.deleteFeActivity.data = null;
      feActivity.deleteFeActivity.loading = false;
      feActivity.deleteFeActivity.error = null;
    },
  },
});

function addNewFeActivitySuccess(dataToAdd, state) {
  state.data.data = [...state.data.data, dataToAdd];
  state.data.total++;
  return state;
}

// update FeActivity function
function updateFeActivitySuccessFun(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

function deleteNewFeActivitySuccess(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
  return state;
}

export const {
  fetchFeActivityListRequest,
  fetchFeActivityListSuccess,
  fetchFeActivityListFailure,

  createFeActivityRequest,
  createFeActivitySuccess,
  createFeActivityFailure,

  updateFeActivityRequest,
  updateFeActivitySuccess,
  updateFeActivityFailure,

  deleteFeActivityRequest,
  deleteFeActivitySuccess,
  deleteFeActivityFailure,

  getFeActivityByIdRequest,
  getFeActivityByIdSuccess,
  getFeActivityByIdFailure,

  resetFeActivity,
} = feActivitySlice.actions;

export const getFeActivityListState = (store) =>
  store?.feActivity?.feActivityList;
export const getFeActivityCreateState = (store) =>
  store?.feActivity?.createFeActivity;
export const getUpdateFeActivityState = (store) =>
  store?.feActivity?.updateFeActivity;
export const getFeActivityDeleteState = (store) =>
  store?.feActivity?.deleteFeActivity;
export const getFeActivityGetByIdState = (store) =>
  store?.feActivity?.getFeActivityById;
export default feActivitySlice.reducer;
