import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    operativeTicketList: { loading: false, data: null, error: null },
    createOperativeTicket: { loading: false, data: null, error: null },
    updateOperativeTicket: { loading: false, data: null, error: null },
    deleteOperativeTicket: { loading: false, data: null, error: null },
    getOperativeTicketById: { loading: false, data: null, error: null },
};

export const operativeTicketSlice = createSlice({
    name: "operativeTicket",
    initialState,
    reducers: {
        fetchOperativeTicketListRequest: (operativeTicket, action) => {
            operativeTicket.operativeTicketList.loading = true;
            operativeTicket.operativeTicketList.error = null;
        },
        fetchOperativeTicketListSuccess: (operativeTicket, action) => {
            operativeTicket.operativeTicketList.data = action.payload;
            operativeTicket.operativeTicketList.loading = false;
            operativeTicket.operativeTicketList.error = null;
        },
        fetchOperativeTicketListFailure: (operativeTicket, action) => {
            operativeTicket.operativeTicketList.data = null;
            operativeTicket.operativeTicketList.loading = false;
            operativeTicket.operativeTicketList.error = action.payload;
        },

        //create OperativeTicket
        createOperativeTicketRequest: (operativeTicket, action) => {
            operativeTicket.createOperativeTicket.loading = true;
            operativeTicket.createOperativeTicket.error = null;
        },
        createOperativeTicketSuccess: (operativeTicket, action) => {
            operativeTicket.createOperativeTicket.data = action.payload;
            operativeTicket.createOperativeTicket.loading = false;
            operativeTicket.createOperativeTicket.error = null;
            operativeTicket.operativeTicketList =
                addNewOperativeTicketSuccess(action.payload, {
                    ...operativeTicket.operativeTicketList,
                });
        },
        createOperativeTicketFailure: (operativeTicket, action) => {
            operativeTicket.createOperativeTicket.data = null;
            operativeTicket.createOperativeTicket.loading = false;
            operativeTicket.createOperativeTicket.error = action.payload;
        },

        //update operativeTicket details
        updateOperativeTicketRequest: (operativeTicket, action) => {
            operativeTicket.updateOperativeTicket.loading = true;
            operativeTicket.updateOperativeTicket.error = null;
        },
        updateOperativeTicketSuccess: (operativeTicket, action) => {
            operativeTicket.updateOperativeTicket.data = action.payload;
            operativeTicket.updateOperativeTicket.loading = false;
            operativeTicket.updateOperativeTicket.error = null;
            operativeTicket.operativeTicketList =
                updateNewOperativeTicketSuccess(
                    action.payload,
                    operativeTicket.operativeTicketList
                );
        },
        updateOperativeTicketFailure: (operativeTicket, action) => {
            operativeTicket.updateOperativeTicket.data = null;
            operativeTicket.updateOperativeTicket.loading = false;
            operativeTicket.updateOperativeTicket.error = action.payload;
        },

        // delete operativeTicket
        deleteOperativeTicketRequest: (operativeTicket, action) => {
            operativeTicket.deleteOperativeTicket.loading = true;
            operativeTicket.deleteOperativeTicket.error = null;
        },
        deleteOperativeTicketSuccess: (operativeTicket, action) => {
            operativeTicket.deleteOperativeTicket.data = action.payload;
            operativeTicket.deleteOperativeTicket.loading = false;
            operativeTicket.deleteOperativeTicket.error = null;
            operativeTicket.operativeTicketList =
                deleteNewOperativeTicketSuccess(action.payload, {
                    ...operativeTicket.operativeTicketList,
                });
        },
        deleteOperativeTicketFailure: (operativeTicket, action) => {
            operativeTicket.deleteOperativeTicket.data = null;
            operativeTicket.deleteOperativeTicket.loading = false;
            operativeTicket.deleteOperativeTicket.error = action.payload;
        },

        // getById operativeTicket details
        getOperativeTicketByIdRequest: (operativeTicket, action) => {
            operativeTicket.getOperativeTicketById.loading = true;
            operativeTicket.getOperativeTicketById.error = null;
        },
        getOperativeTicketByIdSuccess: (operativeTicket, action) => {
            operativeTicket.getOperativeTicketById.data = action.payload;
            operativeTicket.getOperativeTicketById.loading = false;
            operativeTicket.getOperativeTicketById.error = null;
        },
        getOperativeTicketByIdFailure: (operativeTicket, action) => {
            operativeTicket.getOperativeTicketById.data = null;
            operativeTicket.getOperativeTicketById.loading = false;
            operativeTicket.getOperativeTicketById.error = action.payload;
        },

        // reset operativeTicket details
        resetOperativeTicket: (operativeTicket, action) => {
            operativeTicket.createOperativeTicket.data = null;
            operativeTicket.createOperativeTicket.loading = false;
            operativeTicket.createOperativeTicket.error = null;

            operativeTicket.updateOperativeTicket.data = null;
            operativeTicket.updateOperativeTicket.loading = false;
            operativeTicket.updateOperativeTicket.error = null;

            operativeTicket.deleteOperativeTicket.data = null;
            operativeTicket.deleteOperativeTicket.loading = false;
            operativeTicket.deleteOperativeTicket.error = null;

            operativeTicket.getOperativeTicketById.data = null;
            operativeTicket.getOperativeTicketById.loading = false;
            operativeTicket.getOperativeTicketById.error = null;
        },

        resetOperativeTicketList: (operativeTicket, action) => {
            operativeTicket.operativeTicketList.data = null;
            operativeTicket.operativeTicketList.loading = false;
            operativeTicket.operativeTicketList.error = null;
        },
    },
});

function addNewOperativeTicketSuccess(dataToAdd, state) {
    if (state.data?.data.length) {
        state.data.data = [dataToAdd, ...state.data.data];
        state.data.total++;
        return { ...state, data: { ...state.data } };
    } else {
        return state;
    }
}

function updateNewOperativeTicketSuccess(dataToUpdate, state) {
    if (state.data?.data.length) {
        const updatedData = state.data.data.map((value) => {
            if (value._id !== dataToUpdate._id) return value;
            return { ...value, ...dataToUpdate };
        });
        return { ...state, data: { ...state.data, data: updatedData } };
    } else {
        return state;
    }
}

function deleteNewOperativeTicketSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchOperativeTicketListRequest,
    fetchOperativeTicketListSuccess,
    fetchOperativeTicketListFailure,

    createOperativeTicketRequest,
    createOperativeTicketSuccess,
    createOperativeTicketFailure,

    updateOperativeTicketRequest,
    updateOperativeTicketSuccess,
    updateOperativeTicketFailure,

    deleteOperativeTicketRequest,
    deleteOperativeTicketSuccess,
    deleteOperativeTicketFailure,

    getOperativeTicketByIdRequest,
    getOperativeTicketByIdSuccess,
    getOperativeTicketByIdFailure,

    resetOperativeTicket,
    resetOperativeTicketList,
} = operativeTicketSlice.actions;

export const getOperativeTicketListState = (store) =>
    store?.operativeTicket?.operativeTicketList;
export const getOperativeTicketCreateState = (store) =>
    store?.operativeTicket?.createOperativeTicket;
export const getOperativeTicketUpdateState = (store) =>
    store?.operativeTicket?.updateOperativeTicket;
export const getOperativeTicketDeleteState = (store) =>
    store?.operativeTicket?.deleteOperativeTicket;
export const getOperativeTicketGetByIdState = (store) =>
    store?.operativeTicket?.getOperativeTicketById;
export default operativeTicketSlice.reducer;
