import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

export function requestClientMasterList(params) {
    if (params === undefined) {
        return fetch.get(`${API_BASE_URL}/api/app/client-master`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${API_BASE_URL}/api/app/client-master?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    }
}

export function requestCreateClientMaster(data) {
    return fetch.post(`${API_BASE_URL}/api/app/client-master`, data);
}

export function requestUpdateClientMaster(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${API_BASE_URL}/api/app/client-master/${data._id}`, body);
}

export function requestDeleteClientMaster(data) {
    return fetch.delete(`${API_BASE_URL}/api/app/client-master/${data}`);
}

export function requestClientMasterDataById(data) {
    return fetch.get(`${API_BASE_URL}/api/app/client-master/getById/${data.id}`);
}

