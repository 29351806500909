import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leadList: { loading: false, data: null, error: null },
  createLead: { loading: false, data: null, error: null },
  updateLead: { loading: false, data: null, error: null },
  deleteLead: { loading: false, data: null, error: null },
  getLeadById: { loading: false, data: null, error: null },
  addLeadNote: { loading: false, data: null, error: null },
  deleteLeadNote: { loading: false, data: null, error: null },
  updateLeadStage: { loading: false, data: null, error: null },
  leadKpi: { loading: false, data: null, error: null },
};

export const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    fetchLeadListRequest: (lead, action) => {
      lead.leadList.loading = true;
      lead.leadList.error = null;
    },
    fetchLeadListSuccess: (lead, action) => {
      lead.leadList.data = action.payload;
      lead.leadList.loading = false;
      lead.leadList.error = null;
    },
    fetchLeadListFailure: (lead, action) => {
      lead.leadList.data = null;
      lead.leadList.loading = false;
      lead.leadList.error = action.payload;
    },

    // create lead
    createLeadRequest: (lead, action) => {
      lead.createLead.loading = true;
      lead.createLead.error = null;
    },
    createLeadSuccess: (lead, action) => {
      lead.createLead.data = action.payload;
      lead.createLead.loading = false;
      lead.createLead.error = null;
      lead.leadList = addNewLeadSuccess(action.payload, {
        ...lead.leadList,
      });
    },
    createLeadFailure: (lead, action) => {
      lead.createLead.data = null;
      lead.createLead.loading = false;
      lead.createLead.error = action.payload;
    },

    // update lead details
    updateLeadRequest: (lead, action) => {
      lead.updateLead.loading = true;
      lead.updateLead.error = null;
    },
    updateLeadSuccess: (lead, action) => {
      lead.updateLead.data = action.payload;
      lead.updateLead.loading = false;
      lead.updateLead.error = null;
      lead.leadList = updateLead(action.payload, lead.leadList);
    },
    updateLeadFailure: (lead, action) => {
      lead.updateLead.data = null;
      lead.updateLead.loading = false;
      lead.updateLead.error = action.payload;
    },

    // delete lead
    deleteLeadRequest: (lead, action) => {
      lead.deleteLead.loading = true;
      lead.deleteLead.error = null;
    },
    deleteLeadSuccess: (lead, action) => {
      lead.deleteLead.loading = false;
      lead.deleteLead.error = null;
      lead.deleteLead.data = action.payload;
      lead.leadList = deleteLeadFunction(action.payload, {
        ...lead.leadList,
      });
    },
    deleteLeadFailure: (lead, action) => {
      lead.deleteLead.data = null;
      lead.deleteLead.loading = false;
      lead.deleteLead.error = action.payload;
    },

    // get by id lead details
    getLeadDetailsRequest: (lead, action) => {
      lead.getLeadById.loading = true;
      lead.getLeadById.error = null;
    },
    getLeadDetailsSuccess: (lead, action) => {
      lead.getLeadById.loading = false;
      lead.getLeadById.error = null;
      lead.getLeadById.data = action.payload;
    },
    getLeadDetailsFailure: (lead, action) => {
      lead.getLeadById.data = null;
      lead.getLeadById.loading = false;
      lead.getLeadById.error = action.payload;
    },

    addLeadNoteRequest: (state, action) => {
      state.addLeadNote.loading = true;
      state.addLeadNote.error = null;
    },
    addLeadNoteSuccess: (state, action) => {
      state.addLeadNote.data = action.payload;
      state.addLeadNote.loading = false;
      state.addLeadNote.error = null;
      state.leadList = updateLead(action.payload, { ...state.leadList });
    },
    addLeadNoteFailure: (state, action) => {
      state.addLeadNote.data = null;
      state.addLeadNote.loading = false;
      state.addLeadNote.error = action.payload;
    },

    deleteLeadNoteRequest: (state, action) => {
      state.deleteLeadNote.loading = true;
      state.deleteLeadNote.error = null;
    },
    deleteLeadNoteSuccess: (state, action) => {
      state.deleteLeadNote.data = action.payload;
      state.deleteLeadNote.loading = false;
      state.deleteLeadNote.error = null;
      state.leadList = removeLeadNote(action.payload, state.leadList);
    },
    deleteLeadNoteFailure: (state, action) => {
      state.deleteLeadNote.data = null;
      state.deleteLeadNote.loading = false;
      state.deleteLeadNote.error = action.payload;
    },

    updateLeadStageRequest: (state, action) => {
      state.updateLeadStage.loading = true;
      state.updateLeadStage.error = null;
    },
    updateLeadStageSuccess: (state, action) => {
      state.updateLeadStage.data = action.payload;
      state.updateLeadStage.loading = false;
      state.updateLeadStage.error = null;
      state.leadList = updateLeadStage(action.payload, { ...state.leadList });
    },
    updateLeadStageFailure: (state, action) => {
      state.updateLeadStage.data = null;
      state.updateLeadStage.loading = false;
      state.updateLeadStage.error = action.payload;
    },

    getLeadKPIRequest: (state, action) => {
      state.leadKpi.loading = true;
      state.leadKpi.error = null;
    },
    getLeadKPISuccess: (state, action) => {
      state.leadKpi.data = action.payload;
      state.leadKpi.loading = false;
      state.leadKpi.error = null;
    },
    getLeadKPIFailure: (state, action) => {
      state.leadKpi.data = null;
      state.leadKpi.loading = false;
      state.leadKpi.error = action.payload;
    },

    //Reset lead state
    resetLead: (lead, action) => {
      lead.createLead.data = null;
      lead.createLead.loading = false;
      lead.createLead.error = null;

      lead.updateLead.data = null;
      lead.updateLead.loading = false;
      lead.updateLead.error = null;

      lead.deleteLead.data = null;
      lead.deleteLead.loading = false;
      lead.deleteLead.error = null;

      lead.addLeadNote.data = null;
      lead.addLeadNote.loading = false;
      lead.addLeadNote.error = null;

      lead.deleteLeadNote.data = null;
      lead.deleteLeadNote.loading = false;
      lead.deleteLeadNote.error = null;

      lead.updateLeadStage.data = null;
      lead.updateLeadStage.loading = false;
      lead.updateLeadStage.error = null;
    },
  },
});

// addNew lead function
function addNewLeadSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

// update lead function
function updateLead(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// delete lead function
function deleteLeadFunction(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

function removeLeadNote(dataToRemove, state) {
  state.data.data = state.data.data.map((lead) => {
    if (lead._id !== dataToRemove.leadId) return lead;
    if (!lead.notes?.length) return lead;

    return {
      ...lead,
      notes: lead.notes.filter((note) => note._id !== dataToRemove.noteId),
    };
  });
  return state;
}

function updateLeadStage(stageData, state) {
  state.data.data = state.data.data.map((lead) => {
    if (lead._id !== stageData._id) return lead;
    return {
      ...lead,
      leadStage: stageData.leadStage,
      leadStatusReason: stageData.leadStatusReason,
    };
  });
  return state;
}

export const {
  fetchLeadListRequest,
  fetchLeadListSuccess,
  fetchLeadListFailure,

  createLeadRequest,
  createLeadSuccess,
  createLeadFailure,

  updateLeadRequest,
  updateLeadSuccess,
  updateLeadFailure,

  deleteLeadRequest,
  deleteLeadSuccess,
  deleteLeadFailure,

  getLeadDetailsRequest,
  getLeadDetailsSuccess,
  getLeadDetailsFailure,

  addLeadNoteFailure,
  addLeadNoteSuccess,
  addLeadNoteRequest,

  deleteLeadNoteFailure,
  deleteLeadNoteRequest,
  deleteLeadNoteSuccess,

  updateLeadStageFailure,
  updateLeadStageRequest,
  updateLeadStageSuccess,

  getLeadKPIRequest,
  getLeadKPISuccess,
  getLeadKPIFailure,

  resetLead,
} = leadSlice.actions;

export const getLeadListState = (store) => store?.lead?.leadList;
export const getLeadCreateState = (store) => store?.lead?.createLead;
export const getLeadUpdateState = (store) => store?.lead?.updateLead;
export const getLeadDeleteState = (store) => store?.lead?.deleteLead;
export const getLeadGetByIdState = (store) => store?.lead?.getLeadById;
export const getLeadAddNoteState = (store) => store?.lead?.addLeadNote;
export const getLeadDeleteNoteState = (store) => store?.lead?.deleteLeadNote;
export const getLeadStageUpdateState = (store) => store?.lead?.updateLeadStage;
export const getLeadKPIState = (state) => state.lead?.leadKpi;
export default leadSlice.reducer;
